<!--

      This component shows a recipe ingredient amount in the format 1/4
      instead of 0.25. Furthermore, it contains a rounding logic so that only
      the following outcomes are allowed: 1/4, 1/3, 1/2, 2/3, 3/4, 1

 -->

<template>
  <span class="the-amount">
    <span v-if="a[0]">
      {{a[0]}}
    </span>
    <span v-if="a[1] || a[2]">
      <sup>{{a[1]}}</sup>
      <span class="fraction">&frasl;</span>
      <sub>{{a[2]}}</sub>
    </span>
  </span>
</template>

<script>
export default {
  name: 'amount',
  props: ['amount'],
  computed: {
    a () {
      if (typeof this.amount == 'number') {
        return this.separateNumber(this.amount)
      }
      return [null, null]
    }
  },
  methods: {
    // Separates the number into three parts:
    // 1. The full number
    // 2. The upper number of the fraction of the rest
    // 3. The lower number of the fraction of the rest
    separateNumber(a) {
      let [before, after] = this.specialRound(a).toFixed(2).split('.',2)
      before = parseInt(before)
      after = parseInt(after)
      const fract = this.roundFraction(parseFloat("0."+ after))
      if (after > 0 && fract[0] > 0 && fract[0]/fract[1] < 1) {
        return [before, parseInt(fract[0]), parseInt(fract[1])]
      } else if (fract[0]/fract[1] === 1) {
        return [before + 1, 0, 0]
      } else {
        return [before, 0, 0]
      }
    },

    // This function rounds a number only if the rounding procedure changes
    // the numbers value by less than x percent (e.g. 5%).
    // We don't want 0.5 tablespoons to be rounded to 1 tablespoon, but we want
    // 170.11 g rice to be rounded to 170g rice.
    specialRound(a) {
      const x = 0.05
      const rounded = Math.round(a)
      const diff = Math.abs(rounded - a)
      const diffPercent = diff/a
      if (diffPercent < x) {
        return rounded
      } else {
        return Math.round(a * 100)/100
      }
    },

    // Since users don't want complicated fractions (e.g. 12/25), but rather
    // simple ones (1/2) this function rounds them to ensure the denominator
    // is less than a certain value (e.g. 4).
    roundFraction(frac) {
      const greatestAllowedDenominator = 4
      let i
      let diffs = []
      for (i = greatestAllowedDenominator; i > 0; i--) {
        diffs.unshift(Math.abs(frac - Math.round(frac * i)/i))
      }
      const minimum = Math.min(...diffs)
      const minDenominator = diffs.indexOf(minimum) + 1
      const numerator = Math.round(frac * minDenominator)

      console.assert(Math.abs(frac-(numerator/minDenominator)) === minimum )

      return [numerator, minDenominator]
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.the-amount {
  display: inline;
}
.fraction {
  margin: 0;
}
</style>
