import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import constants from './constants'
import { IonicVue } from '@ionic/vue'
import Icon from './components/Icon.vue'
import './registerServiceWorker'

import '@ionic/vue/css/core.css' // this is new
import '@ionic/core/css/ionic.bundle.css' // this is old - do we need this?
import './global.scss'

// Import plugins
import '@ionic-native/insomnia/ngx'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

// Disable the Android Ripple Effect
// Disable the feature that the Android hardware back button always reloads the
// browser - instead we use a different approach (to be found in App.vue).
const app = createApp(App)
  .use(IonicVue, {
    mode: 'ios',
    rippleEffect: false,
    hardwareBackButton: true
  })
  .use(constants)
  .use(router)
  .use(store)
store.$app = app

app.component('Icon', Icon)
app.config.productionTip = false

// Setup Sentry for Error Reporting
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://f3a9c2c05f95480eb9be12dbe58a174d@sentry.io/2429940',
    release: '1.6.0',
    integrations: [
      new Integrations.CaptureConsole({levels: ['error']}),
      new Integrations.Vue({ Vue: app })
    ],
  })
  // Sentry.configureScope(scope => {
  //    scope.setExtra(store.state)
  // })
}

router.isReady().then(() => {
  app.mount('#app');
});
