// Actions
const actions = {

  // // Check which store branches are available at a given postal code
  // async checkStoreBranchAvailability ({rootState, dispatch}, code) {
  //   if (!code) {
  //     await dispatch('save', { key: 'reweavailable', value: null })
  //   } else if (code) {
  //     const details = {
  //       url: rootState.api.shop.storebranches,
  //       id: code
  //     }
  //     await dispatch('apiSend', details).then( async storeBranches => {
  //       // Check if REWE is mentioned in any of the returned store branches
  //       let reweIsAvailable = false
  //       let myStoreBranch = {}
  //       if (storeBranches) {
  //         storeBranches.forEach( storeBranch => {
  //           if (storeBranch.store_name && storeBranch.store_name === 'Rewe') {
  //             reweIsAvailable = true
  //             myStoreBranch = storeBranch
  //           }
  //         })
  //       }
  //       if (reweIsAvailable) {
  //         await dispatch('save', { key: 'reweavailable', value: true })
  //         // Patch the profile to set the store branch for the user
  //         await dispatch('applySettings', { key: 'preferred_store_branch', value: myStoreBranch.id })
  //       } else {
  //         await dispatch('save', { key: 'reweavailable', value: false })
  //         await dispatch('setBerlinsStorebranch')
  //       }
  //     })
  //   }
  // },

  // Check which store branches are available at a given postal code
  async checkStoreBranchAvailability ({rootState, dispatch}, code) {
    if (!code) {
      await dispatch('applySettings', { key: 'preferred_store_branch', value: null })
    } else if (code) {
      const details = {
        url: rootState.api.shop.storebranches,
        id: code
      }
      await dispatch('apiSend', details).then(async storeBranches => {
        if (storeBranches) {
          // Here we have to save the list of storebranches, because
          // we need to allow the user to select one of them.
          await dispatch('save', { key: 'storeBranches', value: storeBranches })

          // Furthermore, we need to automatically select a supermarket
          // unless the user already has a valid supermarket set.
          // We make a preliminary supermarket selection here, because these
          // three cases could happen:
          // 1. Possibly, the user did not enter a postal code, and thus
          //    the app automatically runs this function with a postal code
          //    from Berlin Alexanderplatz.
          // 2. Or the user entered a postal code, but did not select a 
          //    supermarket.
          // 3. Or the user entere a postal code for which there is no
          //    supermarket available.
          // However, since it is not supported to use our app without a 
          // supermarket, we need to automatically select one:
          if (storeBranches.length > 0) {
            let aValidStoreBranchWasAlreadySelected = false
            if (rootState.profile && rootState.profile.preferred_store_branch) {
              storeBranches.forEach(branch => {
                if (branch.id === rootState.profile.preferred_store_branch.id) {
                  aValidStoreBranchWasAlreadySelected = true
                }
              })
            }
            if (!aValidStoreBranchWasAlreadySelected) {
              if (storeBranches && storeBranches.length) {
                await dispatch('applySettings', { key: 'preferred_store_branch', value: storeBranches[0].id })
                dispatch('refreshOrderAfterSupermarketSwitch')
              } else {
                dispatch('setBerlinsStoreBranch')
              }
            }
          } else {
            dispatch('setBerlinsStoreBranch')
          }
        }
      })
    }
  },


  async refreshOrderAfterSupermarketSwitch ({ dispatch }) {
    dispatch('getOpenOrdersAndPlans').then(() => {
      dispatch('removeNewOrderLocally')
    })
  },


  async setBerlinsStoreBranch ({ dispatch }) {
    // Set Berlins storebranch as a default alternative
    await dispatch('applySettings', { key: 'preferred_store_branch', value: 3 }) // This is the storebranch of Berlin Alexanderplatz
    dispatch('refreshOrderAfterSupermarketSwitch')
    await dispatch('showNotification', { message: "Es tut uns sehr Leid, wir können dich momentan leider nicht beliefern! Damit du die App trotzdem testen kannst haben wir für dich den Berliner REWE Lieferservice ausgewählt."})
  },


  async ensureStorebranchIsSet ({ rootState, dispatch }) {
    // Several users have a postal code, but no storebrach. This means there must be some kind of way to
    // hack the app by entering a postal code and preventing the app from retrieving and setting a fitting
    // store branch. So far, I was unable to find the reason that this is still possible. We already fixed
    // two bugs that made this possible, but there still seem to be more options, which is why we have
    // this function.
    // In addition, since we are now updating the postal code to storebranch relationship in the backend,
    // if REWE changed their delivery locations, it can happen that people get an empty storebranch, i.e.
    // that their storebranch is being removed, because REWE no longer delivers in their location.
    // Hence, when a storebranch is null, we want to set Berlin's storebranch.
    if (rootState.profile && rootState.profile.postal_code && !rootState.profile.preferred_store_branch) {
      console.error("This user did not have a storebranch but a postal code")
      await dispatch('checkStoreBranchAvailability', rootState.profile.postal_code)
    }
  },


  async validatePostalCodeAndStorebranch ({ rootState, dispatch }) {
    if (rootState.profile && !rootState.profile.postal_code) {
      await dispatch('showNotification', { message: "Bitte gib deine Postleitzahl ein, um die App nutzen zu können.", type: 'Error'})
      dispatch('goTo', 'supermarket')
    }
  },


  // Get the REWE trolley url for a given order from our Backend
  // Download the suggested durable goods belonging to this order
  async getREWEcheckoutURL ({ rootState, dispatch }, order_id) {
    const details = {
      url: rootState.api.shop.order + order_id + '/checkout-rewe/'
    }
    return dispatch('apiSend', details).then(url => {
      if (url && url.checkout_url) {
        return url.checkout_url
      } else {
        // Try again ...
        return dispatch('apiSend', details).then(url => {
          if (url && url.checkout_url) {
            return url.checkout_url
          } else {
            // If it fails again ...
            dispatch('showNotification', { message: "Hups. Bei der Übertragung des Warenkorbs an REWE ist etwas schiefgegangen. Bitte gehe einen Schritt zurück und klicke erneut auf 'Weiter'.", type: "Error"})
          }
        })
      }
    })
  },


}

export default {
    actions
}
