<!--

      This component shows a price. The number before the comma is big, the
      number behind the comma is smaller and higher up.

 -->

<template>
  <div class="all">
    <div class="price main-text">
      <span v-if="estimated">ca.</span>
      {{ p[0] }},
    </div>
    <div class="up">{{ p[1] }}</div>
    <div class="eur main-text">€</div>
  </div>
</template>

<script>
export default {
  name: 'price',
  props: ['price', 'estimated'],
  computed: {
    p () {
      if (typeof this.price == 'number') {
        return Math.abs(this.price).toFixed(2).split('.',2)
      }
      return ["0", "00"]
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.all {
  font-size: 1.34rem;
  display: inline-block;
  white-space: nowrap;
  color: inherit;
}

.price {
  display: inline-block;
  color: inherit;
  font-size: 1.34rem;
}

.up {
  display: inline-block !important;
  margin-left: -2px;
  font-size: 1rem;
  transform: translateY(-.27rem);
  color: inherit;
}

.eur {
  margin: 0 2px 0 2px;
  display: inline-block;
  color: inherit;
  font-size: 1.34rem;
}
</style>
