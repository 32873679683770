let constants = {
  install (Vue, options) {
    Vue.constants = {
      activeTests: ['NewCustomerJourney']
    }
  }

}

export default constants
