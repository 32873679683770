<template>
  <div>
    <Icon name="foodableRateUs" class="icon-modal" />
    <div class="text-section">
      <div class="headline">Gefällt dir foodable?</div>
      <div class="main-text">
        Hilf uns mit deiner Bewertung foodable für dich weiterzuentwickeln. 
      </div>
    </div>
    <div @click="$store.dispatch('applySettings', { key: 'show_rate_us_popup', value: false });$store.dispatch('goToAppStore')" class="btn-primary">Jetzt bewerten</div>
    <div>
      <div @click="$store.dispatch('closeModal')" class="btn-tertiary">Nein</div>
    </div>
  </div>
</template>
