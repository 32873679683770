<!--

      This component displays a popup above all of the other content.

 -->

<template>
  <Modal :noPadding="true">
    <div class="shopping-list">
      <div class="headline">Merkliste</div>

      <div class="the-list">
        <div class="list-entry list-entry-border" v-for="(item, index) in list" :key="item" @click="checkItem(index)">
          <Icon name="ellipseOutline" class="icon-checkbox icon-25 icon-gray" v-show="!item.checked" />
          <Icon name="checkmarkCircleOutline" class="icon-checkbox icon-25 icon-gray" v-show="item.checked" />
          <div class="main-text">{{ item.text }}</div>
        </div>
      </div>

      <div class="btn-add-list-entry" @click="startAddingMode()">
        <div class="btn-number">
          <Icon name="addOutline" class="btn-number-icon" />
        </div>
        <div class="secondary-text-gray">Produkt hinzufügen</div>
      </div>

      <div class="list-entry" v-show="addingMode">
        <Icon name="ellipseOutline" class="icon-checkbox icon-25 icon-gray" />
        <div class="input-field" :key="updateInput">
          <ion-input v-on:keyup.enter="addListItem($event)" ref="add" placeholder="Produkt" type="text"></ion-input>
        </div>
      </div>

      <div class="main-text" v-if="recommendations && recommendations.length">
        <br><br>Das könntest du auch noch brauchen:<br>
        <div v-for="rec in recommendations" :key="rec" class="btn-secondary recommendation" @click="addListItem(rec)">{{ rec }}</div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal.vue'
import { IonInput } from '@ionic/vue'

export default {
  name: 'modalshoppinglist',
  components: {
    Modal,
    IonInput
  },
  data () {
    return {
      addingMode: false,
      updateInput: 0,
      list: [],
      recommendations: [],
      timer: null
    }
  },
  methods: {
    startAddingMode () {
      this.addingMode = !this.addingMode
      if (this.addingMode) {
        this.$refs.add.$el.setFocus()
      }
    },
    addListItem (item) {
      let newValue
      if (item.target) {
        newValue = item.target.value
      } else {
        newValue = item
      }
      this.list.push({ text: newValue })
      this.$refs.add.$el.value = ''
      
      // Add it to Backend
      const details = {
        url: this.$store.state.api.shop.list,
        method: 'POST',
        data: {
          text: newValue
        }
      }
      this.$store.dispatch('apiSend', details).then(() => {
        this.getShoppingList()
      })

      this.refreshRecommendations()
    },
    checkItem (index) {
      const theItem = this.list[index]
      if (theItem.hasOwnProperty('checked') && theItem.checked) {
        theItem.checked = false
        clearTimeout(this.timer)
      } else {
        theItem['checked'] = true
        this.timer = setTimeout((data) => this.removeItem(data), 1000, { index: index, item: theItem })
      }
    },
    removeItem(data) {
      this.list.splice(data.index, 1)
      
      // Remove it from Backend
      const details = {
        url: this.$store.state.api.shop.list,
        id: data.item.id,
        method: 'DELETE'
      }
      this.$store.dispatch('apiSend', details).then(() => {
        this.getShoppingList()
      })
    },
    getShoppingList () {
      this.$store.dispatch('apiSend', { url: this.$store.state.api.shop.list }).then(results => {
        if (results && results.length) {
          this.list = results
        }
      })
    },
    getRecommendations() {
      this.$store.dispatch('apiSend', { url: this.$store.state.api.shop.productRecommendations }).then(results => {
        if (results && results.hasOwnProperty('results')) {
          results.results.forEach(result => {
            if (result.shopping_ingredient && result.shopping_ingredient.length < 20) {
              this.recommendations.push(result.shopping_ingredient)
            }
          })
        }
        this.refreshRecommendations()
      })
    },
    refreshRecommendations () {
      const freshRecommendations = []
      this.recommendations.forEach(rec => {
        if (!this.list.some(item => item.text === rec)) {
          freshRecommendations.push(rec)
        }
      })
      this.recommendations = freshRecommendations
    }
  },
  mounted () {
    this.getShoppingList()
    this.getRecommendations()
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/main.scss';

.shopping-list {
  @extend .text-section;
  padding-bottom: 150px;
  min-height: 100%;
  text-align: left;
}

.btn-add-list-entry {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0;
  .btn-number {
    margin-right: 10px;
  }
}

.the-list {
  margin: 20px 0;
}

.list-entry {
  display: grid;
  grid-template-columns: 35px 1fr;
  align-items: center;
  margin: 10px 4px;
}

.list-entry-border {
  padding-bottom: 8px;
  border-bottom: solid 1px $col-lighter-gray-font;
}

.recommendation {
  margin-left: -2px;
  margin-right: 10px;
  margin-bottom: -4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 47%;
}
</style>
