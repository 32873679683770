<!--

      This component displays a popup above all of the other content.

 -->

<template>
  <Modal :noPadding="true">
    <img class="main-image" :src="imageUrl" />

    <div class="text-section" style="position: relative;">
      <div class="like-button-position">
        <div class="round-button">
          <LikeButton :recipe="$store.state.modalrecipe" class="centered" />
        </div>
      </div>
      <div class="share-button-position">
        <div class="round-button" @click="shareRecipe()">
          <Icon name="shareOutline" class="round-button-icon-secondary centered" />
        </div>
      </div>
      <div class="instagram-share-button-position" v-if="this.instagramAvailable">
        <div class="round-button" @click="shareRecipeToInstagram()">
          <Icon name="logoInstagram" class="round-button-icon-secondary logo-instagram centered" />
        </div>
      </div>

      <div class="recipe-features">
        <div class="recipe-feature" v-if="$store.state.modalrecipe.ready_in">
          <Icon name="timeOutline" class="icon-feature"/>
          <span>{{ $store.state.modalrecipe.ready_in }} min insgesamt</span>
        </div>
        <div class="recipe-feature" v-if="$store.state.modalrecipe.ready_in != $store.state.modalrecipe.cooktime">
          <Icon name="timeOutline" class="icon-feature" />
          <span v-if="$store.state.modalrecipe.ready_in != $store.state.modalrecipe.cooktime"> {{ $store.state.modalrecipe.cooktime }} min Zubereitung</span>
        </div>
      </div>
      <div class="main-text text-left">{{ $store.state.modalrecipe.name }}</div>
      <div 
        class="recipe-author" 
        v-if="fullRecipe && fullRecipe.source && fullRecipe.source_visible"
        @click="$store.dispatch('openUrl', fullRecipe.source_link )"
      >
        <div class="recipe-author-icon">
          <img :src="authorImageUrl" alt="">
        </div>
        <div class="secondary-text-gray">Autor: {{ fullRecipe.source }}</div>
      </div>
    </div>
    
    <div class="recipe-section-title-area">
      <div class="section-title">Zutaten</div>
      <div>
        <div class="secondary-text-gray text-center">Portionen</div>
        <AmountSelector
          :amount="servingSize"
          :update="s => servingSize = s"
          :increment="0.5"
          :key="servingSize"
          :minimum="0.5"
        />
      </div>
    </div>
    <div v-if="fullRecipe">
      <div class="unavailability-legend" v-if="someIngredientsAreMissing && $store.state.modalShowAvailability" @click="toggleLegend()">
        Rote Zutaten sind aktuell nicht lieferbar.
      </div>
      <div class="availability-legend" v-if="!someIngredientsAreMissing && $store.state.modalShowAvailability" @click="toggleLegend()">
        Alles ist lieferbar.
      </div>
      <div v-for="ingredient in recipeIngredients" v-bind:key="ingredient.id" :class="['ingredient-margin', {'an-ingredient-availability': $store.state.modalShowAvailability, 'an-ingredient': !$store.state.modalShowAvailability}]">
        <div v-if="isMissing(ingredient.shopping_ingredient_id) && $store.state.modalShowAvailability" class="missing-red"></div>
        <div v-if="!isMissing(ingredient.shopping_ingredient_id) && $store.state.modalShowAvailability" class="missing-green"></div>
        <div class="ingredient-left">
          <div><Amount :amount="ingredient.amount * servingSize" /> {{ ingredient.unit }}</div>
        </div>
        <div>
          <div :class="['an-ingredient', {'clickable-ingredient': ingredient.expiry_info || $store.state.modalOrder }, {'missing-ingredient-name':isMissing(ingredient.shopping_ingredient_id)}]" @click="openIngredientDetails(ingredient)">{{ ingredient.name }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-for="i in 8" v-bind:key="i" :class="['ingredient-margin', {'an-ingredient-availability': $store.state.modalShowAvailability, 'an-ingredient': !$store.state.modalShowAvailability}]">
        <div class="ingredient-left">
          <div>
            <ion-skeleton-text animated :style="`width: ${(30 + Math.random () * Math.floor(90))}px;float:right;height: 15px;`">
            </ion-skeleton-text>
          </div>
        </div>
        <div>
          <div>
            <ion-skeleton-text animated :style="`width: ${(40 + Math.random () * Math.floor(120))}px;height: 15px;`">
            </ion-skeleton-text>
          </div>
        </div>
      </div>
    </div>

    <div class="recipe-section-title-area">
      <div class="section-title" v-if="this.fullRecipe">Zubereitung</div>
    </div>
    <div v-if="this.fullRecipe" class="text-section" style="margin-bottom: 40px;">
      <Step 
        v-for="(step, index) in this.fullRecipe.steps" 
        v-bind:key="step.description"
        :number="index + 1"
      >
        <div class="instructions">
          <span class="instruction" v-for="i in step.instructions" :key="i.name" @click="openIngredientDetailsFromPrepSteps(i)">
            <Amount :amount="i.presentation_amount * servingSize" v-if="i.presentation_amount" />
            <span v-if="i.presentation_unit">&nbsp;{{ i.presentation_unit }}</span>
            <span>&nbsp;{{ i.name }}</span>
          </span>
        </div>
        {{ step.description }}
        <Stopwatch />
      </Step>
    </div>

    <InfoBox :icon="'foodableInstagram'" @click="shareRecipeToInstagram()" v-if="this.instagramAvailable" class="insta-ad">
      <div class="headline">Sieht's lecker aus?</div>
      <div class="main-text">Teile deine Kreation auf Instagram</div>
      <div class="btn-tertiary-small" style="padding-left: 0;">Jetzt fotografieren</div>
    </InfoBox>

    <div class="text-section text-center">
      <div class="headline text-section">Guten Appetit !</div>
      <img src="../../assets/img/served-food.svg" alt="">
    </div>

    <!-- <div class="text-section" v-if="this.fullRecipe && this.fullRecipe.source_link && this.fullRecipe.source_img">
      <div class="secondary-text text-section">Dieses Rezept ist von</div>
      <a :href="this.fullRecipe.source_link" target="_blank" class="recipe-source-img-area">
        <img class="recipe-source-img" :src="this.sourceImage" />
      </a>
    </div> -->

    <div class="report-mistakes">
      <div class="secondary-text-gray" @click="$store.dispatch('reportRecipeMistake', this.fullRecipe)">
        Fehler im Rezept gefunden?
        <div class="btn-tertiary-small">Jetzt melden</div>
      </div>
    </div>

  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal.vue'
import LikeButton from '@/components/buttons/LikeButton.vue'
import InfoBox from '@/components/info/InfoBox.vue'
import Step from '@/components/Step.vue'
import Amount from '@/components/Amount.vue'
import Stopwatch from '@/components/Stopwatch.vue'
import AmountSelector from '@/components/AmountSelector.vue'
import { IonSkeletonText } from '@ionic/vue'
import * as Sentry from '@sentry/browser'
import { Plugins } from '@capacitor/core'
import { Insomnia } from '@ionic-native/insomnia/ngx'
import { Instagram } from '@ionic-native/instagram/ngx'
import { Camera, CameraOptions } from '@ionic-native/camera/ngx'
const { Share } = Plugins
const inso = new Insomnia()
const insta = new Instagram()
const camera = new Camera()

export default {
  name: 'modalrecipedetails',
  components: {
    Modal,
    LikeButton,
    InfoBox,
    Step,
    Amount,
    Stopwatch,
    AmountSelector,
    IonSkeletonText
  },
  data() {
    return {
      fullRecipe: null,
      servingSize: null,
      impressionTracker: null,
      ingredientAvailability: [],
      instagramAvailable: null
    }
  },
  computed: {
    imageUrl () {
      return process.env.VUE_APP_IMAGE_CDN + process.env.VUE_APP_IMAGE_FILTER + this.$store.state.modalrecipe.main_img
    },
    authorImageUrl () {
      if (this.fullRecipe.source_img) {
        return process.env.VUE_APP_IMAGE_CDN + process.env.VUE_APP_IMAGE_FILTER3 + this.fullRecipe.source_img
      }
    },
    recipeIngredients () {
      if (this.fullRecipe && this.fullRecipe.ingredients) {
        return this.fullRecipe.ingredients.sort(this.sortByGramm)
      }
    },
    someIngredientsAreMissing () {
      return this.missingIngredients && this.missingIngredients.length > 0
    },
    missingIngredients () {
      if (this.ingredientAvailability && this.ingredientAvailability.length > 0) {
        return this.ingredientAvailability.filter(i => !i.available)
      }
      return []
    },
    sourceImage () {
      if (this.fullRecipe.source_img) {
        const imageName = this.fullRecipe.source_img.split('/').reverse()[0].replace('.png','')
        return process.env.VUE_APP_IMAGE_CDN + process.env.VUE_APP_IMAGE_FILTER4 + imageName
      }
    }
  },
  methods: {
    isMissing (ingredientId) {
      return this.missingIngredients.find(m => m.shopping_ingredient_id === ingredientId)
    },
    sortByGramm (a, b) {
      return b.gram_amount - a.gram_amount
    },
    closeModal () {
      this.$store.dispatch('closeModal')
    },
    getServingSize () {
      if (this.$store.state.modalrplan && this.$store.state.modalrplan.serving_size) {
        this.servingSize = this.$store.state.modalrplan.serving_size
      } else {
        this.servingSize = this.$store.state.profile.adults + 0.5 * this.$store.state.profile.children
      }
    },
    async getRecipeDetails () {
      if (this.$store.state.recipes) {
        this.fullRecipe = this.$store.state.recipes.find(element => element.id === this.$store.state.modalrecipe.id)
      }
      if (!this.fullRecipe) {
        // Download the recipe and save it in the state and local storage
        const details = {
          url: this.$store.state.api.recipes,
          id: this.$store.state.modalrecipe.id
        }
        await this.$store.dispatch('apiSend', details).then(result => {
          if (result) {
            this.fullRecipe = result
            this.$store.dispatch('addToOfflineRecipeCollection', result)
          }
        })
      }
    },
    async checkIngredientAvailability () {
      if (this.$store.state.modalShowAvailability) {
        await this.$store.dispatch('ensureStorebranchIsSet')
        const details = {
          url: this.$store.state.api.recipeAvailability + '?recipe=' + this.$store.state.modalrecipe.id
        }
        this.$store.dispatch('apiSend', details).then(result => {
          if (result && result.length > 0) {
            this.ingredientAvailability = result[0].shopping_ingredient_availability
          }
        })
      }
    },
    async shareRecipe () {
      await Share.share({
        title: this.$store.state.modalrecipe.name,
        text: 'Schau dir mal dieses leckere foodable Rezept an!',
        url: 'https://foodable.de/app/recipe/' + this.$store.state.modalrecipe.id,
        dialogTitle: 'Rezept mit Freunden teilen'
      })
    },
    async shareRecipeToInstagram () {
      const options = {
        quality: 100,
        destinationType: camera.DestinationType.DATA_URL,
        encodingType: camera.EncodingType.JPEG,
        mediaType: camera.MediaType.PICTURE
      }
      camera.getPicture(options).then((imageData) => {
        let base64Image = 'data:image/jpeg;base64,' + imageData
        insta.share(base64Image, 'Dieses Rezept habe ich heute mit @foodable_de gekocht!\n-\n-\n-\n-\n#photooftheday #lifestyle #yummy #kochenmitliebe #kochenmachtglücklich #gesundessen #schnellesessen #schnellerezepte #schnelleküche #schnellundeinfach #schnellselbstgemacht #frischgekocht #frischeküche #frischkochen #gesundkochen #selbstgekocht')
      })
    },
    openIngredientDetails (item) {
      if (item && item.expiry_info || item && this.$store.state.modalOrder) {
        Sentry.configureScope(function (scope) {
          scope.setExtra('ingredientdetails', JSON.stringify(item))
        })
        this.$store.dispatch('save', { key: 'modalIngredientDetails', value: item}).then(() => {
          this.$store.dispatch('openModal', { name: 'ingredientdetails' })
        })
      }
    },
    openIngredientDetailsFromPrepSteps (step) {
      if (step && step.hasOwnProperty('name') && this.fullRecipe) {
        const ingredient = this.fullRecipe.ingredients.find(i => i.name === step.name)
        if (ingredient) {
          this.openIngredientDetails(ingredient)
        }
      }
    },
    recordImpression () {
      // Record recipe impression
      this.$store.dispatch('recordRecipeImpression', [
        { recipe: this.$store.state.modalrecipe.id, flag: this.$store.state.recipeImpressionFlags.COOKED }
      ])
    }
  },
  mounted () {
    // Update instagram availability
    insta.isInstalled().then(result => {
      this.instagramAvailable = result
    })

    this.getRecipeDetails().then(() => {
      inso.keepAwake()
      this.$store.dispatch('trackCookbutlerImpression', this.fullRecipe)
      this.impressionTracker = setTimeout(this.recordImpression, 1000 * 60 * 10) // ten minutes
    })
    this.getServingSize()

    // Check the availability of each individual shopping ingredient
    this.checkIngredientAvailability()

    // Log tracking event, which mentions the origin from which the recipe modal was opened.
    this.$store.dispatch('logAnalyticsEvent', { name: 'opened_recipe' })

    // Record recipe impression
    this.$store.dispatch('recordRecipeImpression', [
      { recipe: this.$store.state.modalrecipe.id, flag: this.$store.state.recipeImpressionFlags.OPENED }
    ])
  },
  beforeUnmount () {
    clearTimeout(this.impressionTracker)
    inso.allowSleepAgain()
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/main.scss';

.main-image {
  height: 100vw;
  width: 100vw;
  background-color: $col-gray-font;
  background-size: cover;
  background-position: center center;
  border-radius: $border-radius;
}

.an-ingredient {
  @extend .main-text;
  display: grid;
  grid-template-columns: 5fr 8fr;
  align-items: center;
  padding-left: 5px;
  text-align: left;
}

.an-ingredient-availability {
  display: grid;
  grid-template-columns: 10px 5fr 8fr;
  align-items: start;
  padding-left: 25px;
}

.ingredient-margin {
  margin-bottom: 6px;
}

.ingredient-left {
  @extend .main-text;
  align-self: start;
  text-align: right;
  padding-right: 20px;
}

.recipe-section-title-area {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px;
  .section-title {
    margin: 0;
  }
}

.like-button-position {
  position: absolute;
  right: 0;
  top: -45px;
}

.share-button-position {
  position: absolute;
  right: 45px;
  top: -45px;
  border-top-right-radius: -20px;
}

.instagram-share-button-position {
  position: absolute;
  right: 90px;
  top: -45px;
}

.unavailable-ingredient {
  --stroke: #{$col-red};
  fill: #{$col-red};
}

.unavailability-legend {
  @extend .tertiary-text;
  padding: 0 0 10px 20px;
  border-radius: $border-radius;
  color: $col-red;
  text-align: left;
  .unavailable-ingredient {
    margin: 2px 4px 0 0;
    float: left;
  }
}

.availability-legend {
  @extend .tertiary-text;
  padding: 0 0 10px 20px;
  border-radius: $border-radius;
  text-align: left;
  .unavailable-ingredient {
    margin: 2px 4px 0 0;
    float: left;
  }
}

.missing-ingredient-name {
  color: $col-red !important;
}

.report-mistakes {
  margin-top: 40px;
  background: $col-pastell-green;
  padding: 20px;
  padding-bottom: calc(constant(safe-area-inset-top) + 80px) !important;
  padding-bottom: calc(env(safe-area-inset-top) + 80px) !important;
  text-align: center;
}

.instruction {
  @extend .tertiary-text;
  color: white;
  background: $col-gray-font;
  margin: 2px 3px 3px 0;
  border-radius: $border-radius;
  padding: 3px 6px;
  display: inline-block;
}

.clickable-ingredient {
  color: $col-font-green;
}

.logo-instagram {
  fill: #{$col-gray-font};
  padding: 2px;
}

.recipe-author {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.recipe-author-icon {
  border-radius: 100%;
  border: solid 1px $col-lighter-gray-font;
  width: 24px;
  height: 24px;
  margin-right: 5px;
  padding: 2px;
}

.insta-ad {
  margin-bottom: 40px;
}

.insta-ad .info-icon {
  height: 40px !important;
}
</style>
