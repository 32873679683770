<template>
  <ion-app>
    <ion-router-outlet v-show="!this.startingUp" />
    <ion-content v-show="this.startingUp">
      <div class="centered">
        <ion-spinner name="dots"></ion-spinner>
      </div>
    </ion-content>
    <Alert v-show="!this.startingUp" v-if="this.$store.state.notification" :key="this.$store.state.notification" />
  </ion-app>
</template>

<script>
import { 
  IonApp, 
  IonRouterOutlet, 
  IonContent,
  IonSpinner 
} from '@ionic/vue'
import { Plugins, StatusBarStyle } from '@capacitor/core'
const { SplashScreen, App, StatusBar } = Plugins

StatusBar.setStyle({ style: StatusBarStyle.Light })
StatusBar.setBackgroundColor({ color: '#F4F9F1' })

import Alert from '@/components/info/Alert.vue'

let googleMetaTag = document.createElement('meta')
googleMetaTag.name = "google-signin-client_id"
googleMetaTag.content = process.env.VUE_APP_GOOGLE_CLIENT_ID
document.getElementsByTagName('head')[0].appendChild(googleMetaTag)

let viewportMetaTag = document.createElement('meta')
viewportMetaTag.name = "viewport"
viewportMetaTag.content = "initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, width=device-width, viewport-fit=cover, user-scalable=no"
document.getElementsByTagName('head')[0].appendChild(viewportMetaTag)

export default {
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonContent,
    IonSpinner,
    Alert,
  },
  data () {
    return {
      startingUp: true
    }
  },
  created () {
    this.$store.dispatch('getDeviceInfo')

    let navigationDestination = null
    this.$store.state.navigationDestination = null

    // App.addListener('backButton', () => {
    //   console.log('Clicked back')
    // })

    // Listen for ios universal links and android app links
    App.addListener('appUrlOpen', (data) => {
      const slug = data.url.replace('app//','app/').split(".de/app/").pop()
      this.$store.state.slug = slug

      if (slug.includes('activate')) {
        const details = {
          url: slug
        }
        this.$store.dispatch('apiSend', details).then(response => {
          if (response.status === 'success') {
            this.$store.dispatch('showNotification', { message: 'Danke. Deine E-Mail wurde erfolgreich verifiziert!', type: 'Success'})
          } else {
            this.$store.dispatch('showNotification', { message: 'Fehler! Die E-Mail-Verifikation ist fehlgeschlagen.', type: 'Error'})
          }
        })
      } else if (slug.includes('reset') && slug.includes('password')) {
        navigationDestination = 'newpassword'
      } else {
        navigationDestination = slug
      }

      // If we don't do the following check, the navigation happens before app
      // startup, which leads to an empty cooking tab page, when navigating
      // there. Thus the app startup shall happen before navigation.
      // In case the app does start up, the navigation is triggered in the
      // function 'enterTheApp' and thus doesn't have to be triggered here.
      if (navigationDestination && !this.startingUp) {
        this.$store.dispatch('goTo', navigationDestination)
      } else if (navigationDestination && this.startingUp) {
        this.$store.state.navigationDestination = navigationDestination
      }
    })

    // Check and log if the app goes in background
    App.addListener('appStateChange', (data) => {
      if (data.isActive) {
        this.$store.dispatch('logFoodableAnalyticsEvent', { name: 'app_in_foreground' })
      } else {
        this.$store.dispatch('logFoodableAnalyticsEvent', { name: 'app_in_background' })
      }
    })

    // Start the app
    this.$store.dispatch('startup').then(() => {
      this.startingUp = false
      SplashScreen.hide()
    })
  }
}
</script>

<style src='@ionic/core/css/core.css'></style>
<style src='@ionic/core/css/ionic.bundle.css'></style>
