exports.codes = {
  'app_startup': {
    'ios': 'taknpw',
    'android': 'mai1t5'
  },
  'opened_recipe': {
    'ios': 'ndgdig',
    'android': 'jk3ajt'
  },
  'searched_for_product': {
    'ios': 'sws03y',
    'android': 'ej4hsa'
  },
  'marked_meal_as_eaten': {
    'ios': 'x7rsxb',
    'android': 'etpmjn'
  },
  'replaced_a_recipe': {
    'ios': '7du684',
    'android': 'hfujmc'
  },
  'added_a_recipe': {
    'ios': 'tgltx3',
    'android': '482qgb'
  },
  'added_durable_good': {
    'ios': 'lop71r',
    'android': 't6nl44'
  },
  'removed_durable_good': {
    'ios': 'uteil4',
    'android': 'ff1uve'
  },
  'valueslides_weiter_1': {
    'ios': 'ojm55a',
    'android': 'plfzpf'
  },
  'valueslides_weiter_2': {
    'ios': 'szvq77',
    'android': 'q7de9y'
  },
  'valueslides_weiter_3': {
    'ios': 'rc58cc',
    'android': 'umxndh'
  },
  'valueslides_weiter_4': {
    'ios': 'cxkmd1',
    'android': 'kljifa'
  },
  'valueslides_weiter_5': {
    'ios': 'nkdaan',
    'android': 'hb83d9'
  },
  'valueslides_skipped_at_slide_1': {
    'ios': 'ktlty2',
    'android': 'dq65ng'
  },
  'valueslides_skipped_at_slide_2': {
    'ios': 'kdht6o',
    'android': '4sy2o3'
  },
  'valueslides_skipped_at_slide_3': {
    'ios': 'vthocn',
    'android': '88z2dz'
  },
  'valueslides_skipped_at_slide_4': {
    'ios': '19n8fs',
    'android': '37nyxj'
  },
  'valueslides_skipped_at_slide_5': {
    'ios': 'jfg7y3',
    'android': 'ezb4q7'
  },
  'deleted_old_menu': {
    'ios': 'jxa0wu',
    'android': 'cmoe0x'
  },
  'add_recipe_like': {
    'ios': 'z53vm2',
    'android': 'ulr83h'
  },
  'remove_recipe_like': {
    'ios': '1gxcg5',
    'android': '8eunls'
  },
  'opened_currentplan': {
    'ios': '8p8qdk',
    'android': '1qa5p0'
  },
  'user_logged_in': {
    'ios': '7romtr',
    'android': 'gqeih5'
  },
  'created_new_menu': {
    'ios': 'vv09yq',
    'android': 'lo3ubh'
  },
  'opened_unordered_menu': {
    'ios': 'jtrsqp',
    'android': 'c0gcgm'
  },
  'opened_old_menu': {
    'ios': '3hj7u9',
    'android': 'xssv4l'
  },
  'opened_onboarding': {
    'ios': '7d3i84',
    'android': 'ktc0hm'
  },
  'selected_product_alternative': {
    'ios': '8sdvth',
    'android': 'e9933d'
  },
  'opened_product_alternative_page': {
    'ios': '5kbqlk',
    'android': '6la79c'
  },
  'opened_profile': {
    'ios': '7l54n5',
    'android': 'rnuc0v'
  },
  'user_registered': {
    'ios': 'afsqmi',
    'android': '7zsdcs'
  },
  'opened_add_recipe_page': {
    'ios': '5az7dj',
    'android': 'ryuzvn'
  },
  'opened_replace_recipe_page': {
    'ios': '1okzxb',
    'android': 'xz4ud1'
  },
  'did_not_order': {
    'ios': 'xk9szh',
    'android': 'xkpw0i'
  },
  'completed_order': {
    'ios': 'nevcyj',
    'android': '1axcy6'
  },
  'menu_wizard_weiter_1': {
    'ios': 'cpdaif',
    'android': 'h7tb9d'
  },
  'menu_wizard_weiter_1_unique': {
    'ios': '994f8t',
    'android': '4qvd0j'
  },
  'menu_wizard_weiter_2': {
    'ios': 'qhwo1y',
    'android': 'ra4vkh'
  },
  'menu_wizard_weiter_2_unique': {
    'ios': 'ud00g3',
    'android': 'dt07pz'
  },
  'menu_wizard_weiter_3': {
    'ios': 'bqwrn9',
    'android': 'mmusv9'
  },
  'menu_wizard_weiter_3_unique': {
    'ios': 'tibu4y',
    'android': '3717mv'
  },
  'menu_wizard_weiter_4': {
    'ios': 'q1s4o0',
    'android': 'zafr4j'
  },
  'menu_wizard_weiter_4_unique': {
    'ios': 'txb73v',
    'android': 'v2pce6'
  },
  'menu_wizard_weiter_5': {
    'ios': 'psb3jr',
    'android': '202j4c'
  },
  'menu_wizard_weiter_5_unique': {
    'ios': 'kkrnz9',
    'android': 'p8ic5v'
  },
  'menu_wizard_weiter_6': {
    'ios': 'k0hzrm',
    'android': '56mc2j'
  },
  'menu_wizard_weiter_6_unique': {
    'ios': 'dxtx6x',
    'android': 'aw6o1e'
  },
  'onboarding_weiter_1': {
    'ios': 'bje9rv',
    'android': 'zbsd2e'
  },
  'onboarding_weiter_2': {
    'ios': 'lxhemj',
    'android': 'hxmjh2'
  },
  'onboarding_weiter_3': {
    'ios': '1x0dw7',
    'android': 'egird3'
  },
  'onboarding_weiter_4': {
    'ios': 'm2kjsh',
    'android': '24v298'
  },
  'onboarding_weiter_5': {
    'ios': '8gfmws',
    'android': 'skjtv4'
  },
  'onboarding_weiter_6': {
    'ios': '74sobv',
    'android': 'kaejry'
  },
  'onboarding_weiter_7': {
    'ios': 'ov8zg8',
    'android': 'mgatxm'
  },
  'awin_transaction': {
    'ios': '4x0npi',
    'android': 'sxl0do'
  },
  'awin_transaction_unique': {
    'ios': 'lsxgez',
    'android': 'sk8yj4'
  },
  'transferred_to_partner': {
    'ios': 'jydspy',
    'android': '8n7m4d'
  }
}