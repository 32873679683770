<template>
  <div>
    <Icon name="foodableLightBulb" class="icon-modal" />
    <div class="text-section">
      <div class="headline">Was bedeutet das?</div>
      <div class="main-text">
        Indem du auf + oder - drückst kannst du einstellen, wie viele Rezepte
        deine Kochbox bzw. dein Menü enthalten soll.
        <br><br>
        Wenn du einmal am Tag warm isst, und für 7 Tage planen möchtest, wähle hier 
        eine 7 aus. Wenn du aber zweimal am Tag warm isst, und 7 Tage planen möchtest,
        wähle eine 14 aus.
        <br><br>
        Jede Kochbox, die wir dir erstellen, wird individuell für dich
        zusammengestellt.
        <br><br>
      </div>
    </div>
    <div @click="$store.dispatch('closeModal')" class="btn-primary">Okay, verstanden</div>
  </div>
</template>

<script scoped>
export default {
  name: 'menuplanningdayshelp'
}
</script>
