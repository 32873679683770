import { createRouter, createWebHistory } from '@ionic/vue-router'
import Tabs from '@/components/TabBar'

import store from './store/'

const routes = [
  {
    path: '/',
    redirect: '/valueslides'
  },
  {
    path: '/tabs/profile/orders',
    name: 'orders',
    component: () => import('@/views/profile/Orders')
  },
  {
    path: '/tabs/profile/orders/order',
    name: 'order',
    component: () => import('@/views/profile/Order')
  },
  {
    path: '/discover',
    name: 'discover',
    props: true,
    component: () => import('@/views/shopping/Discover')
  },
  {
    path: '/tabs/shopping/menus',
    name: 'menus',
    component: () => import('@/views/shopping/Menus')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/shopping/Search')
  },
  {
    path: '/productsearch',
    name: 'productsearch',
    component: () => import('@/views/shopping/ProductSearchPage')
  },
  {
    path: '/tabs/',
    component: Tabs,
    children: [
      {
        path: '',
        redirect: '/valueslides'
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/tabs/ProfileTab'),
      },
      {
        path: 'recipes',
        name: 'recipes',
        component: () => import('@/views/tabs/RecipesTab'),
      },
      {
        path: 'products',
        name: 'products',
        component: () => import('@/views/tabs/ProductsTab'),
      },
      {
        path: 'cart',
        name: 'cart',
        component: () => import('@/views/tabs/CartTab'),
        props: true
      },
      {
        path: 'shopping',
        name: 'shopping',
        component: () => import('@/views/tabs/ShoppingTab'),
      },
      {
        path: 'cooking',
        name: 'cooking',
        component: () => import('@/views/tabs/CookingTab')
      }
    ]
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/views/shopping/Checkout'),
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/shopping/Product')
  },
  {
    path: '/valueslides',
    name: 'valueslides',
    component: () => import('@/views/ValueSlides')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login')
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('@/views/auth/SignIn')
  },
  {
    path: '/newpassword',
    name: 'newpassword',
    component: () => import('@/views/auth/NewPassword')
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('@/views/auth/ResetPassword')
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () => import('@/views/Onboarding')
  },
  {
    path: '/addproductcategory',
    name: 'addproductcategory',
    props: true,
    component: () => import('@/views/shopping/AddProductCategory')
  }
]

// Add pages for each profile setting:
store.state.settingsPages.forEach(page => {
  routes.push({
    path: '/tabs/profile/' + page.component.toLowerCase(),
    name: page.component.toLowerCase(),
    component: () => import('@/views/profile/Settings'),
    props: { component: page.component, title: page.title }
  })
})

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

router.beforeEach((to, from, next) => {
  let routerHistory = []
  if (store.state.routerHistory && store.state.routerHistory.length) {
    routerHistory = store.state.routerHistory
  }
  routerHistory.push(from)
  store.state.routerHistory = routerHistory
  next()
})

export default router
