<template>
  <Modal>
    <div class="flex-area">
      <div class="content-area">
        <PriceExplanation v-if="name === 'pricehelp'" class="popup-content" />
        <PriceExplanation v-if="name === 'pricehelpmenu'"
          :days="this.$store.getters.days" 
          :thePrice="this.$store.state.neworder.price" 
          :theAvgPrice="this.$store.getters.theAvgPrice" 
          :theRestPrice="this.$store.getters.theRestPrice"
        />
        <LeftoversExplanation v-if="name === 'leftovers'" :data="data" />
        <ExpiryInformation v-if="name === 'ingredientdetails'" />
        <VerifyEmail v-if="name === 'verifyemail'" />
        <MenuPlanningDaysHelp v-if="name === 'menuplanningdayshelp'" />
        <UpdateNow v-if="name === 'updatenow'" />
        <DeleteAccount v-if="name === 'deleteaccount'" />
        <AddRecipe v-if="name === 'addrecipe'" />
        <PlanningIsGood v-if="name === 'planningisgood'" />
        <RateUs v-if="name === 'rating'" />
        <DidYouOrder v-if="name === 'didyouorder'" />
        <MealPlannerExplanation v-if="name === 'mealplannerexplanation'" />
        <OtherAnimalProduce v-if="name === 'OtherAnimalProduce'" />
        <OtherDairyProducts v-if="name === 'OtherDairyProducts'" />
        <RecipeReloadExplanation v-if="name === 'recipereloadexplanation'" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/modals/Modal.vue'
import PriceExplanation from '@/components/modals/content/PriceExplanation.vue'
import LeftoversExplanation from '@/components/modals/content/LeftoversExplanation.vue'
import ExpiryInformation from '@/components/modals/content/ExpiryInformation.vue'
import VerifyEmail from '@/components/modals/content/VerifyEmail.vue'
import MenuPlanningDaysHelp from '@/components/modals/content/MenuPlanningDaysHelp.vue'
import UpdateNow from '@/components/modals/content/UpdateNow.vue'
import DeleteAccount from '@/components/modals/content/DeleteAccount.vue'
import AddRecipe from '@/components/modals/content/AddRecipe.vue'
import PlanningIsGood from '@/components/modals/content/PlanningIsGood.vue'
import RateUs from '@/components/modals/content/RateUs.vue'
import DidYouOrder from '@/components/modals/content/DidYouOrder.vue'
import MealPlannerExplanation from '@/components/modals/content/MealPlannerExplanation.vue'
import RecipeReloadExplanation from '@/components/modals/content/RecipeReloadExplanation.vue'
import OtherAnimalProduce from '@/components/modals/content/OtherAnimalProduce.vue'
import OtherDairyProducts from '@/components/modals/content/OtherDairyProducts.vue'

export default {
  name: 'modal',
  props: ['name', 'data'],
  components: {
    Modal,
    PriceExplanation,
    LeftoversExplanation,
    ExpiryInformation,
    VerifyEmail,
    MenuPlanningDaysHelp,
    UpdateNow,
    DeleteAccount,
    AddRecipe,
    PlanningIsGood,
    RateUs,
    DidYouOrder,
    MealPlannerExplanation,
    RecipeReloadExplanation,
    OtherAnimalProduce,
    OtherDairyProducts
  },
  methods: {
    closeModal () {
      this.$store.dispatch('closeModal').then(() => {
        if (this.name === 'verifyemail') {
          this.$store.dispatch('logout')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../theme/main.scss';

.flex-area {
  display: table;
  height: 100%;
}

.content-area {
  padding-top: constant(safe-area-inset-top);//for iOS 11.2
  padding-top: env(safe-area-inset-top);//for iOS 11.1
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
  iframe {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
