<template>
  <div>
    <Icon name="foodableBell" class="icon-modal" />
    <div class="text-section headline">Konto vollständig löschen</div>
    <div class="text-section main-text">
      Bist du ganz sicher, dass du dein foodable Konto unwiderruflich löschen möchtest?
    </div>
    <div class="text-section secondary-text-gray">
      Wenn du das tust, werden alle deine bisherigen Einstellungen und Bestellung unwiderruflich 
      gelöscht und deine E-Mail Adresse verschwindet aus unserem Verteiler.
    </div>
    <div>
      <div @click="$store.dispatch('closeModal')" class="btn-tertiary" fill="clear">Nein</div>
    </div>
    <div @click="$store.dispatch('deleteAccount')" class="btn-primary">Ja, alles löschen!</div>
  </div>
</template>
