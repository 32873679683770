// Notifications
// Generally, there are three types of notifications:
//
//   1. In-App-Notifications
//      These notifications are display inside the app at the top of the screen.
//      They can either be success (green), warning (yellow) or error (red)
//      messages.
//
//  2. Local Notifications
//     Similarly to push notifications, local notifications are displayed on
//     the phone while the app is not open. Local notifications are triggered
//     by a time interval or a specific condiction inside of the app.
//     Docs: https://capacitorjs.com/docs/apis/local-notifications
//
//  3. Push Notifications
//     These notifications originate from a server. When a new notification is
//     created on the server, it is sent and displayed on all phones of all
//     users who have the foodable app installed on their phone.

import { Plugins } from '@capacitor/core'
const { LocalNotifications } = Plugins
import * as Sentry from '@sentry/browser'

import { OneSignal } from '@ionic-native/onesignal/ngx'

// Actions
const actions = {

  // ####################### In-App-Notifications #######################

  // Displays a notification where the Alert component is used
  async showNotification({ rootState }, details ) {
    rootState.notification = details
  },

  // When the user clicks a notification, this function runs
  async deleteNotification({ rootState }) {
    rootState.notification = null
  },

  // ####################### Push Notifications #######################

  async initOneSignal ({ rootState, dispatch }) {
    if (!rootState.deviceInfo) {
      await dispatch('getDeviceInfo')
    }
    const os = rootState.deviceInfo.platform
    if (os !== 'web') {
      const oneSignal = new OneSignal()
      oneSignal.setLogLevel({logLevel: 6, visualLevel: 1})

      let notificationOpenedCallback = result => {
        Sentry.configureScope(function (scope) {
          scope.setExtra('result', JSON.stringify(result))
        })
        if (result && result.notification && result.notification.payload) {
          dispatch('logFoodableAnalyticsEvent', { name: 'opened_onesignal_notification', payload: result.notification.payload })
        }
        if (result && result.notification && result.notification.payload && result.notification.payload.launchURL) {
          const url = result.notification.payload.launchURL
          const slug = url.replace('app//', 'app/').split('.de/app/').pop()
          rootState.slug = slug
          dispatch('goTo', slug)
        }
      }
  
      var iosSettings = {}
      // Auto prompt user for notification permissions.
      iosSettings["kOSSettingsKeyAutoPrompt"] = false
      // Used with the Launch URL you set on push notifications.
      // true will open the Launch URL in an in-app browser.
      // false will open the Launch URL in the device's default browser.
      iosSettings["kOSSettingsKeyInAppLaunchURL"] = false
  
      oneSignal.startInit('a03e00ae-8951-4041-a3f4-f2b1b0f32100')
      oneSignal.inFocusDisplaying(oneSignal.OSInFocusDisplayOption.Notification)
      oneSignal.handleNotificationOpened().subscribe(notificationOpenedCallback)
      oneSignal.handleNotificationReceived().subscribe(notificationOpenedCallback)
      oneSignal.iOSSettings(iosSettings)
      if (rootState.profile && rootState.profile.user) {
        oneSignal.setExternalUserId(rootState.profile.user.toString(), (results) => {
          // The results will contain push and email success statuses
          console.log('Results of setting external user id')
          console.log(results)
          
          // Push can be expected in almost every situation with a success status, but
          // as a pre-caution its good to verify it exists
          if (results.push && results.push.success) {
            console.log('Results of setting external user id push status:')
            console.log(results.push.success)
          }
          
          // Verify the email is set or check that the results have an email success status
          if (results.email && results.email.success) {
            console.log('Results of setting external user id email status:')
            console.log(results.email.success)
          }
        })
      }
      oneSignal.endInit()
    }
  },

  // ####################### Local Notifications #######################

  // Before we can send local or push notifications, we must request the
  // permission to do so.
  async askForNotificationPermission({ dispatch }) {
    await LocalNotifications.requestPermission()
    dispatch('checkIfNotificationsEnabled')
    dispatch('save', { key: 'askedOnceForPermission', value: 'true' })
  },

  async checkIfNotificationsEnabled({ dispatch }) {
    const enabled = await LocalNotifications.areEnabled()
    dispatch('save', { key : 'notificationsEnabled', value: enabled.value })
    console.log('Device notifications are ' + (enabled.value ? 'enabled' : 'disabled'))
  },

  async showLocalNotification({ rootState }, details) {
    // Some learnings:
    // - Android expects the id to be an integer.

    // Only activate notifications if they were enabled:
    if (rootState.notificationsEnabled) {
      const localNotifications = await LocalNotifications.schedule({
        notifications: [
          {
            title: details.title,
            body: details.message,
            id: details.id,
            schedule: details.schedule,
            sound: null,
            attachments: null,
            actionTypeId: null,
            extra: null
          }
        ]
      })  
    }
  },

  async getPendingNotifications({}) {
    const pending = await LocalNotifications.getPending()
    return pending
  },

  async cancelNotificationsWithId({}, id) {
    const pending = await LocalNotifications.getPending()
    if (pending && pending.notifications && pending.notifications.length > 0) {
      const toBeCancelled = pending.notifications.filter(n => parseInt(n.id) === parseInt(id))
      if (toBeCancelled.length > 0) {
        await LocalNotifications.cancel({ notifications: toBeCancelled })
      }
    }
  },


  // For each local notification, we create an action here. This allows us to change 
  // notifications in one place instead of changing them in multiple places throughout
  // the codebase. Please stick to this rule.

  async activateMenuReminderNotification ({ dispatch }) {
    dispatch('showLocalNotification', {
      id: 1,
      title: 'Denk an deinen Wocheneinkauf',
      message: 'Du hast ein Menü mit leckeren Rezepten 🍛🍲. Bestell sie jetzt bei REWE 🛒 - und schon sehr bald gibts gutes Essen! 😋🍝',
      schedule: { at: new Date(Date.now() + 1000 * 60 * 60 * 24 * 3) } // => 3 Days after the menu was created
    })
  },

  async activateRegistrationReminderNotification ({ dispatch }) {
    dispatch('showLocalNotification', {
      id: 2,
      message: 'Erstelle dir jetzt einen foodable-Account und bestellt dir leckere Menüs! 🤤 👉',
      schedule: { at: new Date(Date.now() + 1000 * 60 * 15) }
      // => 15 mins after the user opens the valueslides
      //    delete the notification if the user registers during the 15 mins
    })
  },

  async activateOrderReminderDripNotifications ({ dispatch }) {
    // 30 Minutes after the signup
    dispatch('showLocalNotification', {
      id: 3,
      title: 'Was darf es sein?',
      message: 'Du hast keine Rezepte für diese Woche ausgewählt!? 😳 Das kannst du hier ändern! 👉',
      schedule: { at: new Date(Date.now() + 1000 * 60 * 30) } 
      // => 30 minutes after the signup happened
      //    This notification will be deleted if the user creates a menu
    })

    // 2 days after the order
    dispatch('showLocalNotification', {
      id: 4,
      title: 'Freundliche Erinnerung',
      message: 'Möchtest du jetzt leckere Rezepte finden und dein Wochenmenü bestellen? Hier entlang! 👉',
      schedule: { at: new Date(Date.now() + 1000 * 60 * 60 * 24 * 2) }
      // => 2 days after the menu was created
      //    This notification will be deleted if the user creates a menu
    })

    // 4 days after the order
    dispatch('showLocalNotification', {
      id: 5,
      title: 'Hast du jetzt kurz 5min?',
      message: 'In nur wenigen Minuten ist dein Wocheneinkauf erledigt! Jetzt Menü erstellen, Rezepte finden und bestellen! 😉 👉',
      schedule: { at: new Date(Date.now() + 1000 * 60 * 60 * 24 * 4) }
      // => 4 days after the menu was created
      //    This notification will be deleted if the user creates a menu
    })
  },

  async deleteOrderReminderDripNotifications ({ dispatch }) {
    dispatch('cancelNotificationsWithId', 3)
    dispatch('cancelNotificationsWithId', 4)
    dispatch('cancelNotificationsWithId', 5)
  },

  // ####################### User Settings #######################

  async toggleOrderDayNotification({rootState, getters, dispatch}) {
    if (getters.orderDayNotificationOn) {
      await dispatch('applySettings', { key: 'order_notification_enabled', value: false })
    } else {
      await dispatch('applySettings', { key: 'order_notification_enabled', value: true })
      // If day or time are not set, select Sunday at 11.
      if (!getters.orderDayShort || !rootState.profile.order_time) {
        await dispatch('applySettings', { key: 'order_time', value: '11:00' })
        await dispatch('applySettings', { key: 'order_day', value: 6 })
      }
    }
  },

  async toggleFoodwasteNotification({getters, dispatch}) {
    if (getters.foodwasteNotificationOn) {
      await dispatch('applySettings', { key: 'foodwaste_notification_enabled', value: false })
    } else {
      await dispatch('applySettings', { key: 'foodwaste_notification_enabled', value: true })
      // If the day is not set, select Saturday (at 11). 11 is the default time in our BE.
      if (!getters.foodwasteDayShort) {
        await dispatch('applySettings', { key: 'foodwaste_notification_day', value: 5 })
      }
    }
  }

}

export default {
  actions
}
