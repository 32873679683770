/*


      This module manages navigation and a complex
      app startup procedure. Overall we have two
      startup routines that have to happen:
        - startup routine 1 happens after the app starts
        - startup routine 2 happens after the user is let into the app

      1. Scenario: The app starts:
        - 'App.vue' runs
        - 'startup' action runs
          - check if user is logged in
            - Yes: enterTheApp runs
            - Yes: 'startupRoutineAfterEnteringApp' action runs
            - No: user is routed to valueslides
        - 'startupRoutineAfterStartingApp' action runs

      2. Scenario: The user logs in via social login OR email:
         Hint: The app has already started; scenario 1 has happened
        - Authentication.store.js runs OR 'Login.vue' runs
          - 'runThisUponSuccessfulLogin' action runs
            - enterTheApp runs
            -'startupRoutineAfterEnteringApp' action runs

      3. Scenario: The user registers via social login OR email:
         Hint: The app has already started; scenario 1 has happened
        - Authentication.store.js runs OR 'Register.vue' runs
          - 'runThisUponSuccessfulRegistration' action runs
            - 'startupRoutineAfterEnteringApp' action runs


*/

import { modalController } from '@ionic/vue'
import ModalInfo from '@/components/modals/ModalInfo.vue'
import ModalRecipeDetails from '@/components/modals/ModalRecipeDetails.vue'
import ModalShoppingList from '@/components/modals/ModalShoppingList.vue'
import router from '../../router'
import { Plugins } from '@capacitor/core'
const { Browser, Share } = Plugins

// Actions
const actions = {

  // This function runs every time the app is opened.
  // Some times a user might be logged in, sometimes not.
  // Both cases are handled here.
  async startup({ rootState, dispatch }) {
    await dispatch('populateState')
    // Reset the api calls counter
    await dispatch('save', { key: 'callsInProgress', value: 0 })
    await dispatch('save', { key: 'modal', value: null })

    // Check if the vuex state contains an old access token
    // If yes, this tells us that the user had been registered before
    // If not, the user needs to register now
    if (rootState.accesstoken) {
      // Check if the API build version changed. If so, reload the app.
      await dispatch('checkBuildVersions').then(async () => {
        await dispatch('enterTheApp', rootState.navigationDestination)
        dispatch('startupRoutineAfterEnteringApp')
      })
    } else {
      // Wipe the whole app and begin at the valueslides
      await dispatch('deleteAllData')
      if (rootState.navigationDestination === 'newpassword') {
        await dispatch('goTo', '/newpassword')
      } else {
        await dispatch('goTo', '/valueslides')
      }
    }

    dispatch('startupRoutineAfterStartingApp')
  },


  async enterTheApp ({ rootState, dispatch }, navigationDestination) {
    // Ensure that one api call is first and the others happen afterwards.
    // Otherwise, multiple api calls happen simultaneosly and they all fail if
    // the access token is invalid. This way, we only try once and then get the
    // new token before we do the other calls (which happen in enterTheApp).
    rootState.enteringTheApp = true
    await dispatch('getProfileFromAPI').then(async () => {

      let loadedUserData = false

      if (rootState.profile && !rootState.profile.postal_code) {
        // This tells us that the user was not onboarded before.
        await dispatch('goTo', 'onboarding')
      } else {
        // For some users the storebranches were null? Ensure this does not happen!
        dispatch('ensureStorebranchIsSet')

        if (navigationDestination) {
          await dispatch('goTo', navigationDestination)
        } else {
          // If the user ordered before, go to the cooking tab. 
          // Otherwise open the shopping page.
          if (rootState.deliveredOrders && rootState.deliveredOrders.length || rootState.notYetDeliveredOrders && rootState.notYetDeliveredOrders.length) {
            await dispatch('goTo', 'cooking')
          } else {
            if (rootState.profile.old_customer_journey) {
              await dispatch('goTo', 'shopping')
            } else {
              // Since we need this data to present the recipes page, it must be loaded earlier than usual
              loadedUserData = true
              await dispatch('downloadUserRelatedData')
              await dispatch('goTo', 'recipes')
            }
            
          }
        }
      }

      if (rootState.profile && !rootState.profile.email_confirmed) {
        await dispatch('openModal', { name: 'verifyemail' })
      }

      await dispatch('downloadAppRelatedData')
      if (!loadedUserData) await dispatch('downloadUserRelatedData')

      rootState.enteringTheApp = false
    })
  },


  async downloadAppRelatedData ({ dispatch }) {
    await dispatch('getFavorites')
    await dispatch('getIngredientCategories')
    await dispatch('getRecipeCategories')
    await dispatch('getBioCategories')
  }, 


  async downloadUserRelatedData ({ dispatch }) {
    await dispatch('getOpenOrdersAndPlans')
    await dispatch('checkIfNeworderMightHaveBeenOrdered')
    await dispatch('autoSetNewplanAndNeworder')
    await dispatch('getOrderedOrders')
    await dispatch('getMyLastOrders')
  },


  async startupRoutineAfterStartingApp ({ dispatch }) {
    // Run things in here that shall happen when the
    // app starts up.
    // Only run things in here, which DO NOT rely on the
    // user's profile. At this point the user might not
    // yet be registered or logged in!
    dispatch('checkInternetConnection')
    dispatch('checkIfNotificationsEnabled')

    dispatch('getDeviceInfo').then(async () => {
      await dispatch('connectToAdjust')
      dispatch('logAnalyticsEvent', { name: 'app_startup' })
    })
    console.log("Startup routine 1/2 was triggered!")
  },


  async startupRoutineAfterEnteringApp ({ rootState, dispatch }) {
    // Run things in here, which are supposed to happen
    // after the app started AND the app already has
    // access to the profile, i.e. the user is logged in.
    if (!rootState.notificationsEnabled && !rootState.askedOnceForPermission) {
      dispatch('askForNotificationPermission')
    }
    dispatch('initOneSignal')
    dispatch('relayAwinTransactions')

    console.log("Startup routine 2/2 was triggered!")
  },


  async runThisUponSuccessfulLogin ({ dispatch }) {
    dispatch('logAnalyticsEvent', { name: 'user_logged_in' })
    await dispatch('enterTheApp')
    dispatch('cancelNotificationsWithId', 2)

    dispatch('startupRoutineAfterEnteringApp')
  },


  async runThisUponSuccessfulRegistration ({ dispatch }) {
    await dispatch('getProfileFromAPI').then(async () => {
      dispatch('logAnalyticsEvent', { name: 'user_registered' })
      await dispatch('goTo', 'onboarding')

      // Update local notifications
      dispatch('cancelNotificationsWithId', 2)
      dispatch('activateOrderReminderDripNotifications')

      dispatch('startupRoutineAfterEnteringApp')
    })
  },


  // Use this function to navigate to a different router location.
  async goTo ({ rootState, dispatch }, link ) {
    // Treat with some special cases:
    // 1. User can go into the recipe modal directly via a url like this:
    //    https://foodable.de/app/recipe/926
    if (link.includes('recipe/')) {
      const recipeId = link.match(/\d/g).join('')
      const details = {
        url: rootState.api.recipes,
        id: recipeId
      }
      dispatch('apiSend', details).then(recipe => {
        if (recipe && recipe.id) {
          dispatch('save', { key: 'modalrecipe', value: recipe })
          dispatch('openModal', { name: 'modalrecipedetails' })
        }
      })
    } else if (link.includes('plan/?uuid')) {
      // 2. User can copy an existing meal plan and open the copy by clicking
      //    on a shared meal plan link like this one:
      //    https://www.foodable.de/app/plan/?uuid=QB8gQoQztLdZzjE2Pwgx4T
      const planUuid = link.match(/uuid=.*/g).join('').replace('uuid=','').replace('/','')
      if (planUuid) {
        dispatch('copyAndOpenPlan', planUuid)
      }
    } else if (link.includes('popup/')) {
      const modalName = link.replace('popup','').replaceAll('/','').replace('?', '')
      try {
        dispatch('openModal', { name: modalName })
      } catch (e) {
        console.error('Tried to open modal via a deep link but the modal does not exist: ', modalName)
      }
      // Determine what will happen behind the modal:
      if (rootState.deliveredOrders && rootState.deliveredOrders.length || rootState.notYetDeliveredOrders && rootState.notYetDeliveredOrders.length) {
        await dispatch('goTo', 'cooking')
      } else {
        await dispatch('goTo', 'shopping')
      }
    } else {
      // 3. Deal with all other cases:
      const l = link.replace('/','').replace('?', '')
      const publicRoutes = ['login', 'register', 'valueslides', 'newpassword', 'resetpassword', 'signin']
      // Check if the user is logged in:
      if (publicRoutes.indexOf(l) > -1 || rootState.accesstoken) {
        dispatch('goToIfNotAlreadyRoutedTo', l)
      } else {
        // Since the user is not logged in, go to the login page:
        router.push({ path: '/login', replace: true })
      }
    }
  },


  async goToIfNotAlreadyRoutedTo ({}, link ) {
    // Go to the link but avoid duplicate navigation. Hence first check
    // if the current route is already the same route.
    if (router.currentRoute._value.name != link) {
      router.push({ name: link })
    }
  },


  async deleteAccount ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.profile,
      method: 'DELETE'
    }
    dispatch('apiSend', details).then(() => {
      dispatch('closeModal')
      dispatch('logout')
      dispatch('showNotification', { message: 'Wir haben dein Konto in der App gelöscht und deine E-Mail aus unserem Verteiler entfernt. Schade, dass du gehst! Gib uns gerne Feedback an hello@foodable.de.', type: 'Success'})
    })
  },

  async logout ({ dispatch }) {
    dispatch('deleteAllData')
    router.push({ path: '/signin', replace: true })
  },


  async copyAndOpenPlan({ rootState, dispatch }, someId) {
    // someId can either be the id or the uuid. Both are supported!

    // 1. First check if the user already has this plan.
    const foundPlan = rootState.openPlans && rootState.openPlans.find(p => p.id === someId || p.uuid === someId)
    if (foundPlan) {
      // 2. Save the order and the plan as the newplan and neworder
      //    and thereby ensure all other stuff is loaded as well
      //    (neworderdurables, product recommendations, ...)
      const order = rootState.openOrders && rootState.openOrders.find(o => o.plan_id === foundPlan.id)
      await dispatch('setNewOrderLocally', {
        plan: foundPlan,
        order: order
      }).then(async () => {
        await dispatch('goTo', 'checkout')
      })
    } else {
      // 2. Create an own copy of the featured plan for the user
      //    and get this newly copied plan
      const details = {
        url: rootState.api.plan.weekly + someId + '/copy/',
        method: 'POST'
      }
      dispatch('apiSend', details).then(plan => {
        if (plan && plan.order) {
          // 3. Download the corresponding order that was automatically
          //    created when the plan was copied
          const details2 = {
            url: rootState.api.shop.order,
            id: plan.order
          }
          dispatch('apiSend', details2).then(order => {
            if (order && order.id) {
              // 4. Save the order and the plan as the newplan and neworder
              //    and thereby ensure all other stuff is loaded as well
              //    (neworderdurables, product recommendations, ...)
              dispatch('setNewOrderLocally', {
                plan: plan,
                order: order
              }).then(() => {
                dispatch('goTo', 'checkout')
              })
            }
          })
        }
      })
    }
  },


  async openLegalDocument ({}, name) {
    const useInAppBrowser = process.env.VUE_APP_USE_IN_APP_BROWSER === 'true'
    const docs = {
      imprint: "https://foodable.de/impressum/",
      privacy: "https://foodable.de/datenschutz/",
      agb: "https://foodable.de/agb/"
    }
    const openThis = docs[name]
    if (openThis) {
      if (useInAppBrowser) {
        await Browser.open({ url: openThis })
      } else {
        window.open(openThis, '_system')
      }
    }
  },


  async openUrl ({}, url) {
    const useInAppBrowser = process.env.VUE_APP_USE_IN_APP_BROWSER === 'true'
    if (useInAppBrowser) {
      await Browser.open({ url: url })
    } else {
      window.open(url, '_system')
    }
  },


  async openSupermarket ({}, name) {
    const useInAppBrowser = process.env.VUE_APP_USE_IN_APP_BROWSER === 'true'
    const supermarkets = {
      getnow: process.env.VUE_APP_WHISK_GETNOW_BERLIN_TROLLEY_URL_LOGOUT,
      getnowRegister: 'https://getnow.com/konto-eroeffnen/'
    }
    const openThis = supermarkets[name]
    if (openThis) {
      if (useInAppBrowser) {
        await Browser.open({ url: openThis })
      } else {
        window.open(openThis, '_system')
      }
    }
  },

  async writeHelpMail ({}) {
    const subject = '?subject=Support-Anfrage / Feedback'
    const body = '&body=Liebes foodable Team,%0D%0A%0D%0AIch habe folgendes Problem / Feedback bezüglich eurer App:%0D%0A'
    window.location.href = 'mailto:hello@foodable.de' + subject + body
  },

  async reportRecipeMistake ({}, recipe) {
    const subject = '?subject=Fehler im Rezept gefunden'
    const body = '&body=Liebes foodable Team,%0D%0A%0D%0AIch habe im Rezept "' + recipe.name + '" folgenden Fehler entdeckt / bzw. habe folgendes Feedback zu dem Rezept:%0D%0A'
    window.location.href = 'mailto:hello@foodable.de' + subject + body
  },

  async addRecipeViaMail ({}) {
    const subject = '?subject=Neues Rezept hinzufügen'
    const body = '&body=Liebes foodable Team,%0D%0A%0D%0AIch möchte bitte folgendes Rezept hinzufügen. Die Zutaten, die Personenzahl, die Zubereitungsschritte und ein Bild habe ich angehängt:%0D%0A'
    window.location.href = 'mailto:hello@foodable.de' + subject + body
  },

  async sharePlan ({}, plan) {
    if (plan.name) {
      await Share.share({
        title: plan.name,
        text: 'Schau dir mal mein leckeres foodable Menü "' + plan.name + '" an!',
        url: 'https://foodable.de/app/plan/?uuid=' + plan.uuid,
        dialogTitle: 'Menü mit Freunden teilen'
      })
    } else {
      await Share.share({
        title: 'Mein foodable Menü',
        text: 'Schau dir mal mein leckeres Menü aus der foodable App an!',
        url: 'https://foodable.de/app/plan/?uuid=' + plan.uuid,
        dialogTitle: 'Menü mit Freunden teilen'
      })
    }
  },

  async openModal ({ rootState, dispatch }, data) {
    let modalComponent = ModalInfo
    if (data.name === 'modalrecipedetails') {
      modalComponent = ModalRecipeDetails
    } else if (data.name === 'modalshoppinglist') {
      modalComponent = ModalShoppingList
    }
    // Create and present the new modal
    const modal = await modalController
      .create({
        component: modalComponent,
        cssClass: 'modal',
        componentProps: {
          name: data.name,
          data: data.data
        },
      })

    modal.backdropDismiss = true // MUST be true for Android Hardware Back Button to work!
    modal.swipeToClose = false // iOS only. Should be false, because it incurs scrolling issues in iOS.

    modal.present()
    // Add the modal to a stack and save the stack in state
    // We need to save modals in a stack here, in order to be
    // able to close them later.
    let modalStack = []
    if (rootState.modal) {
      modalStack = rootState.modal
    }
    modalStack.push(modal)
    dispatch('save', { key: 'modal', value: modalStack })
  },

  async closeModal ({ rootState, dispatch }) {
    // Remove the modal from the stack and dismiss it.
    // This removes the modal from the view.
    // Then save the updated stack in the state.
    const modalStack = rootState.modal
    if (modalStack) {
      const frontmostModal = modalStack.pop()
      if (frontmostModal) {

        // Logout if the user does not verify their email and closes the verification model:
        if (frontmostModal.componentProps && frontmostModal.componentProps.name === 'verifyemail' && !rootState.profile.email_confirmed) {
          dispatch('logout')
        }
        const isMealplannerExplanationModal = (
          frontmostModal.componentProps &&
          frontmostModal.componentProps.name === 'mealplannerexplanation'
          && rootState.profile.show_meal_planner_explanation_popup
        )
        const isRecipeReloadExplanationModal = (
          frontmostModal.componentProps &&
          frontmostModal.componentProps.name === 'recipereloadexplanation'
          && rootState.profile.show_recipe_reload_explanation_popup
        )

        if (isMealplannerExplanationModal) {
          rootState.showConfettiAfterMealPlanGeneration = false
          dispatch('applySettings', { key: 'show_meal_planner_explanation_popup', value: false })
        }

        if (isRecipeReloadExplanationModal) {
          dispatch('applySettings', { key: 'show_recipe_reload_explanation_popup', value: false })
        }

        // Remove the current model to get it closed
        frontmostModal.dismiss()
      }
      dispatch('save', { key: 'modal', value: modalStack }) 
    }
  }
}

export default {
  actions
}
