<!--

      This component shows notifications at the top of the screen.
      Notifications typically time out after 15s and can be closed by clicking.

 -->

<template>
  <transition appear name="alert" mode="out-in">
    <div v-if="this.showAlert" :class="theClasses" @click="theLink()">
      <div class="alert-label main-text" v-if="this.$store.state.notification" :key="this.showAlert">
        <strong v-if="this.$store.state.notification && this.$store.state.notification.title">
          {{ $store.state.notification.title }}
        </strong>
        <br v-if="this.$store.state.notification && this.$store.state.notification.title">
        {{ $store.state.notification.message }}
      </div>
      <div class="close-alert">
        Okay ({{ countdownTime }})
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'alert',
  data () {
    return {
      remainingDisplayTime: null,
      showAlert: true,
    }
  },
  computed: {
    theClasses () {
      if (this.$store.state.notification) {
        let classes = 'alert fixed-top'
        const type = this.$store.state.notification.type
        if (type === 'Error') {
          classes += ' a-red'
        } else if (type === 'Warning') {
          classes += ' a-yellow'
        } else {
          classes += ' a-green'
        }
        return classes
      }
    },
    countdownTime() {
      if (this.remainingDisplayTime > 0) {
        setTimeout(() => {
            this.remainingDisplayTime -= 1
        }, 1000)
        return this.remainingDisplayTime
      } else {
        this.showAlert = false
        setTimeout(() => {this.$store.dispatch('deleteNotification')}, 300)
      }
    }
  },
  methods: {
    // Defines the behaviour in case the message is clicked
    theLink () {
      if (this.$store.state.notification && this.$store.state.notification.link) {
        this.$store.dispatch('goTo', this.$store.state.notification.link)
      } else {
        this.showAlert = false
      }
    },
  },
  created () {
    this.remainingDisplayTime = process.env.VUE_APP_NOTIFICATION_DURATION_MS/1000
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.alert {
  color: white;
  visibility: visible;
  font-size: 1.3rem;
  text-align: center;
  padding-top: constant(safe-area-inset-top);//for iOS 11.2
  padding-top: env(safe-area-inset-top);//for iOS 11.1
  border-bottom-left-radius: #{$border-radius};
  border-bottom-right-radius: #{$border-radius};
  width: 100vw;
  z-index: 30000;
}
.alert-label {
  display: block;
  padding: 20px;
  color: white;
}
.a-red {
  background: #{$col-red};
}
.a-yellow {
  background: #{$col-orange};
}
.a-green {
  background: #{$col-main-green};
}
.close-alert {
  display: inline-block;
  border-radius: $border-radius;
  font-size: .8em;
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 4px 16px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(0,0,0,.3);
}
</style>
