<!--

      This component shows notifications at the top of the screen.
      Notifications typically time out after 15s and can be closed by clicking.

 -->

<template>
  <div class="stopwatch-component" @click="start()">
    <div v-if="collapsed" class="btn-secondary-small">Timer</div>
    <div v-if="!collapsed" class="btn-secondary-small">
      <span :key="time">{{ minutes }}:{{ seconds }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stopwatch',
  data () {
    return {
      now: null,
      startdate: null,
      collapsed: true
    }
  },
  computed: {
    time () {
      const passedSeconds = (this.now - this.startdate)/1000
      return Math.round(passedSeconds)
    },
    minutes () {
      const mins = Math.floor(this.time/60)
      if (mins < 0) {
        return 0
      } else if ( mins > 60) {
        const hours = Math.floor(mins/60)
        let restMins = mins - (hours * 60)
        if (restMins < 10) {
          restMins = '0' + restMins
        }
        return hours + ':' + restMins
      }
      return mins
    },
    seconds () {
      const secs = this.time % 60
      if (secs < 0) {
        return '00'
      } else if (secs < 10) {
        return '0' + secs
      } else {
        return secs
      }
    }
  },
  methods: {
    start () {
      this.collapsed = !this.collapsed
      this.now = Date.now()
      this.startdate = Date.now()
      this.letTheClockTick()
    },
    letTheClockTick () {
      setInterval(() => {
        this.now = Date.now()
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.stopwatch-component {
  display: flex;
  margin-top: 5px;
  flex-direction: row-reverse;
}
</style>
