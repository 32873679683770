<template>
  <div class="menu-card" v-if="!skeletonMode">
    <div v-if="this.openingMenu === openPlan.id" class="centered menu-opening">
      <ion-spinner name="crescent" class="centered menu-opening-spinner"></ion-spinner>
    </div>
    <div class="menu-card-title">
      <div @click="openTheOpenPlan(openPlan)" class="menu-card-title-flex">
        <div class="main-text">{{ name(openPlan) }}</div>
        <Icon v-if="openPlan.saved" :key="openPlan.saved" name="bookmark" class="icon-menu-card saved" />
      </div>
      <div class="menu-btns">
        <Icon name="shareOutline" class="icon-menu-card" @click="this.$store.dispatch('sharePlan', openPlan)" />
        <Icon name="trashOutline" class="icon-menu-card" @click="removePlanById(openPlan)" />
      </div>
    </div>
    <div class="menu-card-images" @click="openTheOpenPlan(openPlan)">
      <div v-for="recipe in recipes(openPlan)" :key="recipe.id" class="menu-card-img">
        <img :src="image(recipe)" />
      </div>
      <div v-if="recipes(openPlan).length === 0" class="empty-menu-text">Dieses Menü enthält keine Rezepte</div>
    </div>
  </div>
  <ion-skeleton-text class="skeleton-menu-card" animated v-if="skeletonMode"></ion-skeleton-text>
</template>

<script>
import {
  IonSpinner,
  IonSkeletonText
} from '@ionic/vue'
import * as Sentry from '@sentry/browser'

export default {
  name: 'menucard',
  props: ['openPlan', 'skeletonMode'],
  components: {
    IonSpinner,
    IonSkeletonText
  },
  data () {
    return {
      openingMenu: null
    }
  },
  methods: {
    openTheOpenPlan (plan) {
      this.openingMenu = plan.id
      if (this.$store.getters.oldCustomerJourney) {
        this.$store.dispatch('copyAndOpenPlan', plan.id).then(() => {
          setTimeout(() => {this.openingMenu = null}, 1000)
        })
      } else {
        this.$store.dispatch('goTo', 'cart')
      }
    },
    recipes (openPlan) {
      if (openPlan && openPlan.single_days) {
        let recipes = []
        const days = openPlan.single_days

        Sentry.configureScope(function (scope) {
          scope.setExtra('days', JSON.stringify(days))
        })

        days.forEach(d => {
          if (d.planrecipes.length) {
            recipes.push(d.planrecipes[0].recipe)
          }
        })
        return recipes.sort((a,b) => a.position - b.position)
      }
    },
    removePlanById (plan) {
      // Update the savedPlans array
      if (plan.saved) {
        this.$store.state.savedPlans = this.$store.state.savedPlans.filter(p => p.id !== plan.id)
      }
      this.$store.dispatch('save', { key: 'savedPlans', value: this.$store.state.savedPlans })
      
      // Remove the plan and its order completely
      this.$store.dispatch('removeOpenOrderAndPlan', plan.id)
    },
    image (recipe) {
      return process.env.VUE_APP_IMAGE_CDN + process.env.VUE_APP_IMAGE_FILTER3 + recipe.main_img
    },
    name (plan) {
      if (plan.name) {
        return plan.name
      } else {
        const created = new Date(plan.created_at)
        let item = 'Warenkorb'
        if (this.$store.getters.oldCustomerJourney) {
          item = 'Box'
        }
        return item + ' vom ' + created.getDate() + '.' + (created.getMonth() + 1) + '.' + created.getFullYear()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.menu-card {
  @extend .white-box;
  position: relative;
  transform: rotate(0);
  transition: ease-in-out .5s;
}

.menu-card-title {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 2px;
}

.menu-card-title-flex {
  display: flex;
}

.saved {
  fill: $col-light-gray-font;
}

.menu-card-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 20px;
  height: 70px;
  overflow: hidden;
  margin-left: -5px;
}
.menu-card-img {
  margin-left: -20px;
  img {
    border-radius: 70px;
    width: 70px;
    height: 70px;
    object-fit: contain;
    border: solid 1px rgba(white, .5);
  }
}

.menu-opening {
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  background: white;
  opacity: .6;
}

.menu-opening-spinner {
  width: 50px;
  height: 50px;
}

.empty-menu-text {
  @extend .secondary-text-gray;
  margin: auto;
  padding-right: 20px;
}

.menu-btns {
  display: flex;
  margin-left: 8px;
}

.icon-menu-card {
  width: 22px;
  height: 22px;
  --stroke: #{$col-gray-font};
  --stroke-width: 1.6rem;
  margin-right: -4px;
  margin-left: 8px;
}
</style>
