<!--

      This component defines the tabs at the bottom of the app.

 -->

<template>
  <ion-page>
    <ion-content scrollY="false">
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <div class="tab-bar-container">
          <ion-tab-bar>
            <ion-tab-button tab='profile' href='/tabs/profile'>
              <Icon name="personOutline" class="tab-icon" />
              <div class="tab-name">Profil</div>
            </ion-tab-button>

            <ion-tab-button tab='shopping' href='/tabs/shopping' v-if="$store.getters.oldCustomerJourney">
              <Icon name="albumsOutline" class="tab-icon" />
              <div class="tab-name">Kochboxen</div>
            </ion-tab-button>

            <ion-tab-button tab='recipes' href='/tabs/recipes' v-if="!$store.getters.oldCustomerJourney">
              <Icon name="receiptOutline" class="tab-icon" />
              <div class="tab-name">Rezepte</div>
            </ion-tab-button>

            <ion-tab-button tab='products' href='/tabs/products' v-if="!$store.getters.oldCustomerJourney">
              <svg class="tab-icon foodable-tab-icon" width="100%" height="100%" viewBox="0 0 1000 1000" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;"><g><g id="Layer-1" serif:id="Layer 1"><path d="M411.998,383.7c0,0 -80.156,-142.014 -242.207,-92.499c-36.011,6.752 -94.663,26.668 -89.85,-19.605c6.263,-11.49 28.057,-34.239 62.201,-42.12c32.613,-7.53 77.779,-0.454 77.779,-0.454c0,0 -157.741,-73.917 -67.362,-130.692c90.379,-56.775 113.852,166.894 110.014,88.075c-2.574,-52.834 -3.028,-103.991 12.057,-128.096c7.419,-11.857 43.062,-12.303 48.961,7.071c5.25,17.256 -1.172,86.921 8.251,138.794c18.236,100.369 81.763,150.033 95.313,175.28" style="fill:none"></path><path d="M920.338,950c0,0 -647.304,-443.332 -505.409,-560.22c31.745,-26.15 62.687,-49.075 109.255,-17.642c161.589,109.066 386.725,514.53 396.154,577.862Z" style="fill:none;"></path></g></g></svg>
              <div class="tab-name">Produkte</div>
            </ion-tab-button>

            <ion-tab-button tab='shopping' href='/tabs/cart' v-if="!$store.getters.oldCustomerJourney">
              <Icon name="cartOutline" class="tab-icon" />
              <div class="tab-name">Warenkorb</div>
            </ion-tab-button>

            <ion-tab-button tab='cooking' href='/tabs/cooking'>
              <Icon name="restaurantOutline" class="tab-icon" />
              <div class="tab-name">Kochen</div>
            </ion-tab-button>
          </ion-tab-bar>

          <div class="top-bar">
            <Transition mode="out-in" name="bounce">
              <div class="price-badge" v-if="$store.state.neworder && $store.state.neworder.price && !$store.getters.oldCustomerJourney" :key="$store.state.neworder.price">
                <Price :price="$store.state.neworder.price" />
              </div>
            </Transition>
          </div>
        </div>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script>
import Price from '@/components/Price.vue'

import {
  IonPage,
  IonContent,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonRouterOutlet
} from '@ionic/vue'

export default {
  name: 'tabbar',
  components: {
    IonPage,
    IonContent,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonRouterOutlet,
    Price
  }
}
</script>

<style lang="scss" scoped>
@import "../theme/main.scss";

.tab-bar-container {
  margin-top: -14px;
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
}

.top-bar {
  background: white;
  height: 25px;
  margin-bottom: -10px;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0,0,0,.2);
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  pointer-events: none;
}

ion-tab-bar {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  --border: none;
  height: 55px;
  margin-top: -10px;
}

ion-tab-button {
  visibility: visible !important;
  color: $col-gray-font;
  --stroke: #{$col-gray-font};
  stroke: #{$col-gray-font};
}

.tab-selected {
  color: $col-font-green;
  --stroke: #{$col-font-green};
  stroke: #{$col-font-green};
  --stroke-width: 42px;
}

.tab-icon {
  height: 30px;
  width: 30px;
  --stroke-width: 30px !important;
}

.foodable-tab-icon {
  stroke-width: 50px;
}

.tab-name {
  font-size: .9rem;
}

.price-badge {
  background: $col-font-green;
  color: white;
  position: absolute;
  bottom: 35px;
  right: calc(22% - 20px); // This is a linear function that was built with trial and error considering various screen sizes.
  padding: 1px 6px;
  border-radius: $border-radius;
  transform: scale(.7);
  z-index: 10;
  margin-bottom: calc(constant(safe-area-inset-top) * 0.8);//for iOS 11.2
  margin-bottom: calc(env(safe-area-inset-top) * 0.8);//for iOS 11.1
}

.bounce-enter-active {
  transition: all .2s cubic-bezier(1,3.59,.68,-0.22);
}

.bounce-leave-active {
  transition: all .2s ease-in-out;
}

.bounce-enter-from,
.bounce-leave-to {
  transform: translateY(-4px) scale(.7);
}
</style>
