// Documentation for the plugin we use here:
// https://github.com/capacitor-community/uxcam

import "@capacitor-community/uxcam"
import { Plugins } from "@capacitor/core"
// import { LogEventProperty, UserProperty } from "@capacitor-community/uxcam"

const { UXCamPlugin } = Plugins

// Actions
const actions = {

  async startUXCam ({ rootState, dispatch }) {
    if (!rootState.deviceInfo) {
      await dispatch('getDeviceInfo')
    }
    const os = rootState.deviceInfo.platform
    if (os === 'android') {
      UXCamPlugin.startWithKey({ UXCamKey: 'ystq7ztuq4iyqh7' })
    }
  }

}

export default {
  actions
}
