import router from '../../router'

// Actions
const actions = {

  async getNewAccessToken ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.auth.login,
      id: '',
      data: {
        grant_type: "refresh_token",
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        refresh_token: rootState.refreshtoken
      },
      method: 'POST',
      lastchance: true,
      noauthheader: true
    }
    return await dispatch('apiSend', details )
  },


  async getProfileFromAPI ({ rootState, dispatch }) {
    // Instead of just requesting profile this was updated to update active user tests
    // The response is the same user profile
    const details = {
      url: rootState.api.tests,
      method: 'PATCH',
      data: {
        tests: this.$app.constants.activeTests
      }
    }
    await dispatch('apiSend', details).then(profile => {
      if (profile) {
        dispatch('save', { key: 'profile', value: profile })
      }
    })
  },

  async getSelectedRecipeCategories ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.selectedRecipeCategories
    }
    await dispatch('apiSend', details).then(async selection => {
      if (selection && selection.length) {
        await dispatch('save', { key: 'selectedRecipeCategories', value: selection })
      }
    })
  },


  // Use this function to save profile settings by sending an id and value.
  // This separate function is necessary, because it informs the API and only
  // saves a change locally if the API saved it successfully.
  async applySettings ({ rootState, dispatch }, data) {
    if (rootState.profile) {
      const details = {
        url: rootState.api.profile,
        method: 'PATCH',
        id: '',
        data: {
          id: rootState.profile.id,
        }
      }

      // Support for data objects {} and arrays []:
      if (data.hasOwnProperty('length')) {
        data.forEach(d => {
          details.data[d.key] = d.value
        })
      } else {
        details.data[data.key] = data.value
      }

      await dispatch('apiSend', details).then(async profile => {
        if (profile && profile.id) {
          await dispatch('save', { key: 'profile', value: profile })
          if (data.key === 'postal_code') {
            dispatch('checkStoreBranchAvailability', rootState.profile.postal_code)
          }
        }
      })
    }
  },


  // Set the profile to contain 1 adult if 0 people were set
  async checkHouseholdSize ({ rootState, dispatch }) {
    if (rootState.profile && rootState.profile.adults + rootState.profile.children === 0) {
      dispatch('applySettings', { key: 'adults', value: 1 })
      dispatch('showNotification', { message: "Du hattest 0 Personen im Haushalt angegeben. Das ist zu wenig. Wir haben daher 1 Erwachsenen für dich ausgewählt. Die Personenzahl kannst du im Profil jederzeit ändern."})
    }
  },


  // Exclusion categories

  async getExclusionIdsFromProfile ({ rootState }) {
    let exclusionIds = []
    if (rootState.profile) {
      rootState.profile.ingredient_category_exclusions.forEach(item => {
        exclusionIds.push(item.id)
      })
    }
    return exclusionIds
  },


  async toggleExcludeItem ({ rootState, dispatch }, item) {
    const excluded = await dispatch('getExclusionIdsFromProfile')
    // Show disclaimer when the first category is being added
    if (excluded.length === 0) {
      dispatch('showNotification', { message: 'Wir garantieren und haften nicht dafür, dass ausgeschlossene Zutaten wirklich nicht in den Supermarkt-Produkten enthalten sind. Kontrolliere dies bitte selbst!', type: 'Warning'})
    }

    let finalExclusionIds = excluded
    if (excluded.includes(item.id)) {
      finalExclusionIds = finalExclusionIds.filter(id => id !== item.id)
    } else {
      finalExclusionIds.push(item.id)
    }
    dispatch('applySettings', { key: 'ingredient_category_exclusions', value: finalExclusionIds})
  },


  async toggleExcludeGroup ({ rootState, dispatch }, group) {
    const excluded = await dispatch('getExclusionIdsFromProfile')
    const theItems = rootState.ingredientcategories.filter(i => i.exclusion_group === group)
    let theItemsIds = []
    let finalExclusionIds = []

    // Collect the ids of all ingredient category exclusions in that group.
    theItems.forEach(item => {
      theItemsIds.push(item.id)
    })

    // Check if the whole exclusion group is already excluded.
    // In this case we want to activate them all instead of excluding them.
    const wholeGroupIsExcluded = theItemsIds.every(i => excluded.includes(i))

    if (wholeGroupIsExcluded) {
      // Activate the whole group by removing their ids from the final array
      finalExclusionIds = excluded.filter(ex => !theItemsIds.includes(ex))
    } else {
      // Concatenate the old exclusions from the profile with the new
      // exclusions selected in the interface. Then de-duplicate the new list.
      const combined = excluded.concat(theItemsIds)
      finalExclusionIds = combined.filter((i, p) => combined.indexOf(i) === p)
    }

    dispatch('applySettings', { key: 'ingredient_category_exclusions', value: finalExclusionIds})
  },


  async getIngredientCategories ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.ingredientcategories
    }
    dispatch('apiSend', details).then(cats => {
      dispatch('save', { key: 'ingredientcategories', value: cats })
    })
  },


  async getRecipeCategories ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.recipeCategories
    }
    dispatch('apiSend', details).then(cats => {
      dispatch('save', { key: 'recipeCategories', value: cats })
    })
  },


  // Bio Categories

  async getBioIdsFromProfile ({ rootState }) {
    const bioFromProfile = rootState.profile.ingredient_categories_bio
    let bioIds = []
    bioFromProfile.forEach(item => {
      bioIds.push(item)
    })
    return bioIds
  },



  async toggleBioItem ({ rootState, dispatch }, item) {
    const selected = await dispatch('getBioIdsFromProfile')
    let finalBioIds = selected
    if (selected.includes(item.id)) {
      finalBioIds = finalBioIds.filter(id => id !== item.id)
    } else {
      finalBioIds.push(item.id)
    }
    dispatch('applySettings', { key: 'ingredient_categories_bio', value: finalBioIds})
  },



  async getBioCategories ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.biocategories
    }
    dispatch('apiSend', details).then(cats => {
      dispatch('save', { key: 'biocategories', value: cats })
    })
  },



  async generateMealPlan ({ rootState, dispatch }) {
    await dispatch('ensureStorebranchIsSet')
    const details = {
      url: rootState.api.plan.generate,
      method: 'POST',
      data: {
          planned_days: rootState.planneddays
      }
    }
    return dispatch('apiSend', details).then(newplan => {
      // Actively forget the durable goods from the last menu
      // This has to go here (and not in the ordering function) since the
      // user might also remove a menu and create a new one.
      // dispatch('save', { key: 'neworderdurables', value: null })
      dispatch('save', { key: 'newordershoppingingredients', value: null })
      dispatch('save', { key: 'neworderPriceConsumed', value: null })
      dispatch('save', { key: 'neworderLeftovers', value: null })
      dispatch('save', { key: 'neworderUnavailableProducts', value: null })

      if (newplan && newplan.id) {
        dispatch('save', { key: 'newplan', value: newplan })
        dispatch('activateMenuReminderNotification')
        return true
      } else if (newplan && newplan.error) {
        dispatch('showNotification', { message: 'Leider haben wir aktuell nicht genügend Rezepte, die deinen ausgeschlossenen Zutaten entsprechen. Wir kreieren aber regelmäßig neue Rezepte!', type: 'Error' })
        return false
      } else {
        dispatch('showNotification', { message: 'Hups. Wir konnten leider kein Menü erstellen. Bitte versuch es nochmal.', type: 'Error' })
        return false
      }
    })
  },


  // This function only exists for debugging purposes. It should not be used
  // in production.
  async reloadApp () {
    window.location.reload()
  },


  // This function re-downloads the order, which belongs to the most recently
  // generated menu (newplan). It is only needed to download the order again after it
  // was edited by the user. In this case there is no need to download the
  // menu together with the order, which is why we don't use getLatestOrder in those
  // cases.
  async refreshExistingOrder ({ rootState, dispatch }, noOrderDetails) {
    const details = {
      url: rootState.api.shop.order,
      id: rootState.newplan.order
    }
    await dispatch('apiSend', details).then(async order => {
      if (order && order.id) {
        await dispatch('save', { key: 'neworder', value: order })
        await dispatch('updateOrderInOpenOrders', order)

        dispatch('save', { key: 'newordershoppingingredients', value: null })

        // The neworderPriceConsumed is probably outdated now.
        await dispatch('save', { key: 'neworderPriceConsumed', value: null })
        await dispatch('getNewOrderPriceConsumed', order).then(() => {
            const recipeIds = rootState.newplan.single_days.map(day => day.planrecipes).map(planRecipe => planRecipe[0].recipe.id)
            const impressions = recipeIds.map(recipeId => (
              { recipe: recipeId, flag: rootState.recipeImpressionFlags.SEEN }
            ))
            dispatch('recordRecipeImpression', impressions)
            return dispatch('getRecipesAvailability', recipeIds)
          }).then(() => dispatch('getNewOrderLeftovers', order)).then(() => {
            dispatch('getNewOrderUnavailableProducts', order)
            // if (!noOrderDetails) {
            //   dispatch('getSmallAmountProducts', order.id)
            // }
          })
      }
    })
  },


  // This function fetches the order again, which was saved as neworder,
  // together with its plan.
  async getLatestOrder ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.shop.order,
      id: rootState.newplan.order
    }
    await dispatch('apiSend', details).then(async order => {
      if (order && order.id) {
        // 1. Save the order
        await dispatch('save', { key: 'neworder', value: order })
        await dispatch('updateOrderInOpenOrders', order)
        // 2. Download the plan
        await dispatch('getPlanById', order.plan_id).then(async newplan => {
          if (newplan && newplan.id) {
            await dispatch('save', { key: 'newplan', value: newplan })
            await dispatch('updatePlanInOpenPlans', newplan )
          }
        })
        // 3. Refresh the neworderPriceConsumed
        dispatch('save', { key: 'neworderPriceConsumed', value: null })
        dispatch('getNewOrderPriceConsumed', order)
        // 4. Get the leftover statistics and unavailable products
        dispatch('getNewOrderLeftovers', order)
        dispatch('getNewOrderUnavailableProducts', order)
        // // 5. Get the small amount products
        // dispatch('getSmallAmountProducts', order.id)
        // 7. Remember when we downloaded the order. This is necessary since
        //    some users leave the app open in the background of their phone.
        //    When the user then opens the app and restores the last menu,
        //    it might already be outdated.
        dispatch('save', { key: 'latestOrderDownloadedAt', value: Date.now() })
      } else {
        dispatch('removeNewOrderLocally')
      }
    })
  },


  async updatePlanInOpenPlans({ rootState, dispatch }, plan) {
    if (rootState.openPlans) {
      const foundPlanIndex = rootState.openPlans.findIndex(p => p.id === plan.id)
      if (typeof foundPlanIndex === 'number') {
        let newOpenPlansArray = rootState.openPlans
        newOpenPlansArray[foundPlanIndex] = plan
        dispatch('save', { key: 'openPlans', value: newOpenPlansArray})
      }
    }
  },



  async updateOrderInOpenOrders({ rootState, dispatch }, order) {
    if (rootState.openOrders && order && order.id) {
      const foundOrderIndex = rootState.openOrders.findIndex(o => o && o.id && o.id === order.id)
      if (foundOrderIndex) {
        let newOpenOrdersArray = rootState.openOrders
        newOpenOrdersArray[foundOrderIndex] = order
        dispatch('save', { key: 'openOrders', value: newOpenOrdersArray})
      }
    }
  },


  // The neworder and the newplan are the most recently opened menu
  // Whenever a user has a menu, which was not ordered, these should not be null!
  // Using the following two functions, you can set or remove the neworder and
  // newplan.
  async removeNewOrderLocally({ rootState, dispatch }) {
    rootState.removingNeworderAndNewplan = true
    await dispatch('save', { key: 'newplan', value: null })
    await dispatch('save', { key: 'neworder', value: null })
    // await dispatch('save', { key: 'neworderdurables', value: null })
    await dispatch('save', { key: 'newordershoppingingredients', value: null })
    await dispatch('save', { key: 'neworderPriceConsumed', value: null })
    await dispatch('save', { key: 'neworderLeftovers', value: null })
    await dispatch('save', { key: 'neworderUnavailableProducts', value: null })
    await dispatch('save', { key: 'neworderPaymentIntent', value: null })
    await dispatch('save', { key: 'neworderDeliverySlot', value: null })
    // Ensure that there is always a newplan, if possible. This is needed in order
    // to make it possible to add a recipe from the discover page or from the old orders.
    await setTimeout(() => dispatch('autoSetNewplanAndNeworder'), 500)
    rootState.removingNeworderAndNewplan = false
  },


  async setNewOrderLocally({ dispatch }, data) {
    await dispatch('save', { key: 'newplan', value: data.plan })
    await dispatch('save', { key: 'neworder', value: data.order }).then(async () => {
      await dispatch('refreshExistingOrder')
    })
    dispatch('logAnalyticsEvent', { name: 'opened_unordered_menu' })
  },


  async removeOpenOrderAndPlan({ rootState, dispatch }, planId) {
    if (rootState.newplan.id === planId) {
      rootState.removingNeworderAndNewplan = true
    }
    // Find the order belonging to this plan.
    // If there is none, this function might have been executed before.
    const order = rootState.openOrders.find(o => o.plan_id === planId)
    if (order) {
      const orderId = order.id

      // 1. Remove this plan and its order locally from openPlans and openOrders
      const openPlansWithoutDeletedPlan = rootState.openPlans.filter(p => p.id !== planId)
      const openOrdersWithoutDeletedOrder = rootState.openOrders.filter(o => o.plan_id !== planId)

      await dispatch('save', { key: 'openPlans', value: openPlansWithoutDeletedPlan })
      await dispatch('save', { key: 'openOrders', value: openOrdersWithoutDeletedOrder })

      // 2. Remove order and plan remotely. However, first, we need this security check:
      //    We check if the order was purchased by the same user using another
      //    device. Without this check we might delete an order that was already
      //    purchased while the currently used device still has it set as an
      //    open order and plan.
      const safeToDelete = await dispatch('orderWasNotPurchasedMeanwhile', orderId)
      if (safeToDelete) {
        // Remove the plan. The BE automatically removes the corresponding order.
        const details1 = {
          url: rootState.api.plan.weekly,
          id: planId,
          method: 'DELETE'
        }
        dispatch('apiSend', details1).then(() => {
          dispatch('cancelNotificationsWithId', 1)
        })
      }
      dispatch('logAnalyticsEvent', { name: 'deleted_old_menu' })
    }

    // If the newplan was deleted, we need to ensure that another plan is now the newplan
    // This is required in order to ensure that it will be possible to add a recipe
    // to your currently open plan, from the discover page and the old orders page.
    if ((rootState.newplan && rootState.newplan.id === planId) || !rootState.newplan) {
      dispatch('removeNewOrderLocally')
    }
  },


  async orderWasNotPurchasedMeanwhile ({ rootState, dispatch }, orderId) {
    const details = {
      url: rootState.api.shop.order,
      id: orderId
    }
    return await dispatch('apiSend', details).then( order => {
      if (order) {
        return order.ordered_at === null
      }
    })
  },


  async getPlanById ({ rootState, dispatch }, plan_id) {
    const details = {
      url: rootState.api.plan.weekly,
      id: plan_id
    }
    return await dispatch('apiSend', details).then(plan => {
      return plan
    })
  },


  // Download the suggested durable goods belonging to this order
  // async getSmallAmountProducts ({ rootState, dispatch }, order_id) {
  //   const details = {
  //     url: rootState.api.shop.order + order_id + '/get-suggested-durables/'
  //   }
  //   await dispatch('apiSend', details).then(async durables => {
  //     if (durables) {
  //       await dispatch('save', { key: 'neworderdurables', value: durables })
  //     }
  //   })
  // },


  // Dowload the price of the consumed products in recipes
  // And save it in the neworder.
  // ATTENTION! Only use this function for neworder!
  async getNewOrderPriceConsumed ({ rootState, dispatch }, neworder) {
    const details = {
      url: rootState.api.shop.order + neworder.id + '/price-consumed/'
    }
    let recipes = rootState.recipes
    dispatch('save', { key: 'loadingNeworderPriceConsumed', value: true })
    await dispatch('apiSend', details).then(result => {
      if (result && result.hasOwnProperty('order_price_consumed')) {
        dispatch('save', { key: 'neworderPriceConsumed', value: result.order_price_consumed }).then(() => {
          dispatch('save', { key: 'loadingNeworderPriceConsumed', value: null })
        })
      }
    })
  },


  // Download requested recipes availability
  async getRecipesAvailability ({ rootState, dispatch }, recipeIds) {
    await dispatch('ensureStorebranchIsSet')
    if (!rootState.recipeAvailability) {
      rootState.recipeAvailability = []
    }
    let url = rootState.api.recipeAvailability + '?'
    const cachedRecipeIds = rootState.recipeAvailability.filter(
      each => Date.now() - each.date < 1000 * 60 * 60 // skip an hour old cached values
    ).map(recipe => recipe.recipe)
    recipeIds = recipeIds.filter(id => cachedRecipeIds.indexOf(id) === -1) // exclude cached recipes

    if (recipeIds.length === 0) {
      return
    }

    // fetch new data
    recipeIds.forEach(id => { url += `recipe=${id}&` })
    const details = { url: url }
    await dispatch('apiSend', details).then(async result => {
      if (result) {
        result.forEach(availability => {
          const ingredients = availability.shopping_ingredient_availability
          const data = {
            'recipe': availability.recipe_id,
            'missingCount': ingredients.filter(i => !i.available).length,
            'totalCount': ingredients.length,
            'date': Date.now()
          }
          const index = rootState.recipeAvailability.findIndex(d => d.recipe === data.recipe)
          if (index === -1) {
            rootState.recipeAvailability.push(data)
          } else {
            rootState.recipeAvailability[index] = data
          }
        })
      }
    })
  },


  async getNewOrderLeftovers ({ rootState, dispatch }, neworder) {
    const details = {
      url: rootState.api.shop.order + neworder.id + '/leftovers/'
    }
    await dispatch('apiSend', details).then(async result => {
      if (result) {
        await dispatch('save', { key: 'neworderLeftovers', value: result })
      }
    })
  },


  async getNewOrderUnavailableProducts ({ rootState, dispatch }, order) {
    const details = {
      url: rootState.api.shop.order + order.id + '/products/?unavailable=true'
    }
    await dispatch('apiSend', details).then(async result => {
      if (result) {
        await dispatch('save', { key: 'neworderUnavailableProducts', value: result })
      }
    })
  },


  // Don't confuse this with a recipe like
  // A product like is used to ensure that people get the same product
  // variant in the next menu if they once use it as a replacement.
  async createProductLike ({ rootState, dispatch }, id) {
    const details = {
      url: rootState.api.shop.productLike,
      method: 'POST',
      data: {
        product_id: id
      }
    }
    await dispatch('apiSend', details )
  },


  // Since we want to avoid downloading the same recipes over and over
  // again, this function saves recipes locally on the smartphone once they
  // were requested from BE. (Whenever a recipe is to be displayed, the app
  // first checks if it already exists.)
  async addToOfflineRecipeCollection({ rootState, dispatch }, recipe) {
    let recipes = []
    if (rootState.recipes) {
      recipes = rootState.recipes
    }
    if ( !recipes.some(r => r.id === recipe.id) ) {
      // The recipe is not yet there, hence add it
      recipes.push(recipe)
    } else {
      // The recipe is already there, hence replace it
      recipes = rootState.recipes.filter(r => r.id != recipe.id)
      recipes.push(recipe)
    }
    dispatch('save', { key: 'recipes', value: recipes })
  },


  // Check API and APP build versions and compare to previous value.
  // If the API build version was increased, wipe and reload the app.
  // This will force the user to sign in again. Therefore, send the user
  // to the login page.
  // The App version is always hardcoded in the state.
  // This has to be increased during each deploy. Then it has to be
  // increased in the backend django environment variables.
  // The user will then get an alert forcing to update.
  async checkBuildVersions ({ rootState, dispatch }) {
    await dispatch('save', { key: 'appversion', value: process.env.VUE_APP_VERSION })
    try {
      const res = await fetch(
        process.env.VUE_APP_API + rootState.api.version,
        {
          method: 'GET',
          headers: {
            "Authorization": "Bearer " + rootState.accesstoken
          }
        })
      const result = await res.json()
      const oldApiBuildVersion = rootState.apiversion
      if (result && result.hasOwnProperty('backend_build_version')) {
        dispatch('save', { key: 'apiversion' , value: result.backend_build_version })
        dispatch('save', { key: 'expectedAppVersion', value: result.app_build_version })
        if (oldApiBuildVersion && oldApiBuildVersion != result.backend_build_version) {
          dispatch('reloadApp')
        }
        if (rootState.appversion !== result.app_build_version) {
          dispatch('openModal', { name: 'updatenow' })
        }
      }
    } catch(e) {
      console.error('Could not retrieve build versions...')
    }
  },


  // Like or dislike a recipe online
  async ilike ({ rootState, dispatch }, recipe) {
    // Part 1:
    // Only toggle the liked state in the local recipe collection.
    // Hereby, we assume that the Api call will not fail afterwards.
    let recipeClone = Object.assign({}, recipe)
    if (recipeClone.is_liked) {
      recipeClone.is_liked = null
    } else {
      recipeClone.is_liked = true
    }
    await dispatch('addToOfflineRecipeCollection', recipeClone)

    // Part 2:
    // Contact the Api in order to toggle the liked status of the recipes.
    // After the call is successful, the recipe is overwritten in the local
    // recipe collection. This ensures that mistakes from above are also
    // overwritten.
    if (recipe.is_liked == null) {
      dispatch('logAnalyticsEvent', { name: 'add_recipe_like' })
      // Record recipe impression
      dispatch('recordRecipeImpression', [
        { recipe: recipe.id, flag: rootState.recipeImpressionFlags.LIKED }
      ])
      // Then add the like online
      const details = {
        url: rootState.api.like.create,
        method: 'POST',
        data: {
          recipe_id: recipe.id
        }
      }
      return await dispatch('apiSend', details ).then( async result => {
        if (result) {
          // Since we change the favorites, we have to inform the function, which
          // is executed once the replace page tabs are switched so that it can
          // re-download the favorites.
          dispatch('save', { key: 'favoriteschanged', value: true })
          return await dispatch('reloadRecipeAfterLiking', recipe)
        }
      })
    } else {
      // Remove a like
      if (recipe.is_liked === true) {
        dispatch('showNotification', { message: 'Du warst zu schnell. Bitte versuche es gleich noch einmal.', type: 'Error' })
      } else {
        dispatch('logAnalyticsEvent', { name: 'remove_recipe_like' })
        const details = {
          url: rootState.api.like.delete,
          id: recipe.is_liked,
          method: 'DELETE'
        }
        return await dispatch('apiSend', details ).then( async result => {
          // Since we change the favorites, we have to inform the function, which
          // is executed once the replace page tabs are switched so that it can
          // re-download the favorites.
          dispatch('save', { key: 'favoriteschanged', value: true })
          return await dispatch('reloadRecipeAfterLiking', recipe)
        })
      }
    }
  },


  // Download the recipe again from the Backend. This is necessary in order to
  // ensure that the liking has actually happened and to display the result.
  async reloadRecipeAfterLiking ({ rootState, dispatch }, recipe) {
    // Download the recipe from the API again
    const details = {
      url: rootState.api.recipes,
      id: recipe.id,
    }
    return dispatch('apiSend', details ).then( resultRecipe => {
      if (resultRecipe) {
        dispatch('addToOfflineRecipeCollection', resultRecipe)
        return resultRecipe
      }
      return null
    })
  },


  async getFavorites ({ rootState, dispatch }) {
    // Only download favorites if the user has a storebranch set. Otherwise,
    // this will lead to backend errors.
    if (rootState.profile && rootState.profile.preferred_store_branch) {
      // 1. Download all favorites from the Api. Take care of pagination.
      const apiFavorites = []
      async function downloadFavorites(url) {
        const data = await dispatch('apiSend', { url: url })
        if (data && data.hasOwnProperty('results')) {
          data.results.forEach(favorite => {
            apiFavorites.push(favorite)
          })
          if (data.next) {
            await downloadFavorites(data.next)
          }
        }
      }
      await downloadFavorites(rootState.api.favorites)

      // 2. Ensure that all apiFavs are fully downloaded locally
      //    and that their likes are correctly set.
      apiFavorites.forEach(fav => {
        let favExistsLocally
        if (rootState.recipes && rootState.recipes.length > 0) {
          favExistsLocally = rootState.recipes.find(r => r.id === fav.id)
        }
        if (!favExistsLocally) {
          // Since apiFavs only contains a brief version of the recipe without
          // ingredients and prep steps, we must fully download the recipes
          // and add it to the offline recipe collection.
          dispatch('reloadRecipeAfterLiking', fav)
        } else {
          // Ensure that the like is correctly set
          const likeIsCorrect = fav.is_liked === favExistsLocally.is_liked
          if (!likeIsCorrect) {
            dispatch('reloadRecipeAfterLiking', fav)
          }
        }
      })

      // 3. Ensure to remove all mistaken local likes by comparing local favs
      //    with api favs.
      let localFavs
      if (rootState.recipes && rootState.recipes.length > 0) {
        localFavs = rootState.recipes.filter(r => r.is_liked != null)
      }
      if (localFavs && localFavs.length > 0) {
        localFavs.forEach(lfav => {
          const lfavExistsInApiFavs = apiFavorites.find(r => r.id === lfav.id)
          if (!lfavExistsInApiFavs) {
            // Remove the wrong local like by re-downloading the recipe and saving it
            // offline.
            dispatch('reloadRecipeAfterLiking', lfav)
          }
        })
      }
    }
  },



  // Download the corresponding plans for recent unordered orders.
  async getOpenOrdersAndPlans ({ rootState, dispatch }, url) {
    let openOrdersLastPage
    if (!url) {
      const storeName = rootState.profile.preferred_store_branch.store_name
      openOrdersLastPage = await dispatch('apiSend', { url: rootState.api.shop.order + '?ordered_at__isnull=true&store_name=' + storeName })
    } else {
      openOrdersLastPage = await dispatch('apiSend', { url: url })
    }

    if (openOrdersLastPage && openOrdersLastPage.hasOwnProperty('results')) {

      let openOrders
      if (rootState.openOrders && url) {
        openOrders = rootState.openOrders.concat(openOrdersLastPage.results)
      } else {
        openOrders = openOrdersLastPage.results
      }

      let newOpenPlans = []
      let newSavedPlans = []
      await Promise.all(openOrders.map(async o => {
        await dispatch('getPlanById', o.plan_id).then(plan => {
          if (plan.hasOwnProperty('single_days')) {
            if (o.saved) {
              plan['saved'] = true
              newSavedPlans.push(plan)
            } else {
              plan['saved'] = false
            }
            newOpenPlans.push(plan)
          }
        })
      }))

      await dispatch('save', { key: 'openPlans', value: newOpenPlans })
      await dispatch('save', { key: 'openOrders', value: openOrders })
      await dispatch('save', { key: 'openOrdersLastPage', value: openOrdersLastPage })
      await dispatch('save', { key: 'savedPlans', value: newSavedPlans })
    }
  },


  // Download past orders
  // This function is used for the data in the cooking tab
  async getOrderedOrders ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.shop.orderedOrdersOverview
    }
    await dispatch('apiSend', details).then(async orderedOrders => {
      if (orderedOrders && orderedOrders.length > 0) {
        await dispatch('save', { key: 'orders', value: orderedOrders })
      
        const deliveredOrders = orderedOrders.filter(o => o.delivered)
        if (deliveredOrders.length > 0) {
          const sortedDeliveredOrders = deliveredOrders.sort((a, b) => {
            return new Date(a.ordered_at) - new Date(b.ordered_at)
          })
          const deliveredOrdersWithPlans = await dispatch('addPlansToOrders', sortedDeliveredOrders)
          await dispatch('save', { key: 'deliveredOrders', value: deliveredOrdersWithPlans })
        }
  
        const notDeliveredOrders = orderedOrders.filter(o => !o.delivered && o.has_recipes)
        if (notDeliveredOrders.length > 0) {
          const sortedNotDeliveredOrders = notDeliveredOrders.sort((a, b) => {
            return new Date(a.ordered_at) - new Date(b.ordered_at)
          })
          await dispatch('save', { key: 'notDeliveredOrders', value: sortedNotDeliveredOrders })
        }
      }
    })
  },

  // This function is used for the data in the profile's last orders
  // Here we use a different endpoint than above, which provivdes a
  // different level of information detail and pagination.
  async getMyLastOrders ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.shop.order
    }
    await dispatch('apiSend', details).then(async orders => {
      if (orders && orders.hasOwnProperty('results')) {
        await dispatch('save', { key: 'orders', value: orders })
      }
    })
  },


  // Download the corresponding plans for a given set of orders.
  // A plan is then being saved inside the respective order.
  async addPlansToOrders ({ dispatch }, orders) {
    const ordersWithPlans = []
    await Promise.all(orders.map(async o => {
      if (o.eaten) {
        ordersWithPlans.push(o)
      } else {
        await dispatch('getPlanById', o.plan_id).then(plan => {
          if (plan.hasOwnProperty('single_days')) {
            o['plan'] = plan
            ordersWithPlans.push(o)
          }
        })
      }
    }))
    return ordersWithPlans
  },


  async autoSetNewplanAndNeworder ({ rootState, getters, dispatch }) {
    // Hint: This function runs after removing an order locally (e.g. after buying it)
    //       or after the app was started.
    //
    // This is happening:
    // 1. Check which customer journey the user uses.
    //    In the newer customer journey, we want to
    //    create a new empty order instead.
    //    In the cookbox customer journey, we want to
    //    automatically choose an old order.
    if (!getters.oldCustomerJourney && !rootState.neworder) {
      // Create a new empty plan and download the order that was created with the plan.
      const details = {
        url: rootState.api.plan.weeklyCreate,
        method: 'POST'
      }
      dispatch('apiSend', details).then(async plan => {
        const details = {
          url: rootState.api.shop.order,
          id: plan.order
        }
        await dispatch('apiSend', details).then(async order => {
          if (order.hasOwnProperty('id')) {
            await dispatch('save', { key: 'newplan', value: plan })
            await dispatch('save', { key: 'neworder', value: order }).then(async () => {
              await dispatch('refreshExistingOrder')
            })
          }
        })
      })

    } else {
      // Select an existing order, if possible. No need to create a new order, 
      // since the create-mealplan endpoint will automatically create a new order
      // for the newly created mealplan.
      if (rootState.openPlans && rootState.openPlans.length > 0) {
        const openPlansSorted = rootState.openPlans.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at)
        })
        const correspondingOrder = rootState.openOrders.find(o => o.plan_id === openPlansSorted[0].id)
        if (correspondingOrder) {
          await dispatch('setNewOrderLocally', {
            plan: openPlansSorted[0],
            order: correspondingOrder
          })
        } else {
          console.error('Function "autoSetNewplanAndNeworder" could not find corresponding order to plan with id ' + openPlansSorted[0].id)
        }
      }
    }
  },


  async checkIfNeworderMightHaveBeenOrdered ({ rootState, dispatch }) {
    if (rootState.neworder && rootState.neworder.id === rootState.neworderWithLastStepRewe && rootState.profile && !rootState.profile.preferred_store_branch.use_internal_payment) {
      dispatch('openModal', { name: 'didyouorder' })
    }
  },


  async getFeaturedPlans ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.plan.featured
    }
    await dispatch('apiSend', details).then(async featuredPlans => {
      await dispatch('save', { key: 'featuredPlans', value: featuredPlans })
    })
  },

  
  async reportCheckoutToPartner ({ rootState, dispatch }, order_id) {
    const details = {
      url: rootState.api.shop.order + order_id + '/webhook/',
      method: 'POST'
    }
    dispatch('apiSend', details).then(res => console.log(res))
  },

  async foodableOrdering ({ rootState, dispatch }, goToCooking) {
    if (rootState.neworder && rootState.neworder.id) {
      // Log the analytics event that the user completed the order.
      dispatch('logAnalyticsEvent', { name: 'completed_order' })

      // Forget that an order was left unordered (was probably this order)
      dispatch('save', { key: 'neworderWithLastStepRewe', value: null })

      // Set the ordered_at date
      const now = new Date()
      const isoDate = now.toISOString()

      // Set the ordered_at date of the order
      const orderId = rootState.neworder.id
      const details = {
        url: rootState.api.shop.order,
        id: orderId,
        method: 'PATCH',
        data: { ordered_at: isoDate }
      }
      await dispatch('apiSend', details ).then(async result => {
        if (result && result.id) {
          // Tell our supermarket partner that the payment happened
          if (rootState.profile && rootState.profile.preferred_store_branch.use_internal_payment) {
            dispatch('reportCheckoutToPartner', orderId)
          }

          // Finalize the purchase by removing the newplan and the neworder
          // and by updating the list of recent orders
          await dispatch('removeNewOrderLocally')
          dispatch('getOrderedOrders')
          dispatch('getMyLastOrders')

          // Navigate the user to "Kochen & Essen"
          if (goToCooking) dispatch('goTo', 'cooking')
          // Since the user bought the menu, there is no longer a reason to show
          // a purchase reminder notification about it.
          dispatch('cancelNotificationsWithId', 1)
        }
      })
    }
  },

  async getUserProductCategories ({ rootState, dispatch }) {
    const details = {
      url: rootState.api.userproductcategories,
      method: 'GET'
    }
    await dispatch('apiSend', details).then(async userProductCategories => {
      await dispatch('save', { key: 'userProductCategories', value: userProductCategories })
    })
  },

  async createUserProductCategory ({ rootState, dispatch }, userProductCategory) {
    const details = {
      url: rootState.api.userproductcategories,
      method: 'POST',
      data: userProductCategory
    }
    let response = await dispatch('apiSend', details)
    return response.data
  },

  async updateUserProductCategory ({ rootState, dispatch }, userProductCategory) {
    // 1. Refresh the user product categories.
    await dispatch('getUserProductCategories')

    // 2. Ensure we found categories. Otherwise, this function shouldn't have run in the first place.
    console.assert(rootState.userProductCategories && rootState.userProductCategories.length)

    // 3. Send the updated category to the backend
    const details = {
      url: rootState.api.userproductcategories,
      id: userProductCategory.id,
      method: 'PATCH',
      data: userProductCategory
    }
    dispatch('apiSend', details).then(response => {
      // 4. Update the category locally based on BE's response
      let foundCategory = rootState.userProductCategories.find(e => e.id === userProductCategory.id)
      foundCategory = response.data
    })
  },

  async getUserProductCategoryProducts({ rootState, dispatch },  productQueryParams) {
    const details = {
      url: rootState.api.userproductcategories + productQueryParams.userProductCategoryId + '/products/',
      querparams: `?page=${productQueryParams.page}`,
      method: 'GET',
    }
    return await dispatch('apiSend', details)
  },

  async addProductToUserProductCategory( { rootState, dispatch },  addData) {
    const details = {
      url: rootState.api.userproductcategories + addData.user_product_category_id + '/products/',
      method: 'POST',
      data: addData
    }
    return await dispatch('apiSend', details)
  },

  async deleteUserProductCategoryProduct( { rootState, dispatch },  userProductCategoryProductId) {
    const details = {
      url: rootState.api.userproductcategoryproduct,
      id: userProductCategoryProductId,
      method: 'DELETE',
    }
    dispatch('apiSend', details)
  },

  async deleteUserProductCategory( { rootState, dispatch }, categoryId) {
    const details = {
      url: rootState.api.userproductcategories,
      id: categoryId,
      method: 'DELETE',
    }
    dispatch('apiSend', details)
  }
}

export default {
    actions
}
