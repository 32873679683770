<template>
  <div>
    <Icon name="foodableLightBulb" class="icon-modal" />
    <div class="text-section">
      <div class="headline">Sonstige Tierprodukte</div><br>
      <div class="main-text">
        Die Kategorie "Sonstige Tierprodukte" beinhaltet all diejenigen Tierprodukte, die
        nicht in den anderen Kategorien abgedeckt sind (z.B. Fleischbrühe). Wenn du also 
        gänzlich auf Tierprodukte verzichten möchtest, weil du beispielsweise vegan oder
        vegetarisch isst, solltest du auch diese Kategorie ausschließen.
        <br><br>
        Bitte kontrolliere zur Sicherheit trotzdem selbst ob die von dir gewählten Produkte
        auch wirklich frei von ausgeschlossenen Zutaten sind.
      </div>
    </div>
  </div>
</template>
