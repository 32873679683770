// Documentation for the plugin we use here:
// https://github.com/capacitor-community/firebase-analytics

import { Adjust, AdjustConfig, AdjustEvent, AdjustEnvironment } from '@ionic-native/adjust'
import adjustEventCodes from '@/adjustEventCodes'

import router from '../../router'

import { Plugins } from '@capacitor/core'

const { Device } = Plugins

// Actions
const actions = {

  async connectToAdjust ({ rootState, dispatch }) {
    if (!rootState.deviceInfo) {
      await dispatch('getDeviceInfo')
    }
    const os = rootState.deviceInfo.platform
    let adjustConfig
    let env
    if (process.env.NODE_ENV !== 'development') {
      env = AdjustEnvironment.Production
    } else {
      env = AdjustEnvironment.Sandbox
    }
    if (os === 'android') {
      // adjustConfig = new AdjustConfig('f32xa4q9x7gg', env) - this is for ios
      adjustConfig = new AdjustConfig('2k0krwgzlyww', env)
      console.log('Connecting to adjust...')
      const connectionResult = Adjust.create(adjustConfig)
      console.log(connectionResult)
      return connectionResult
    }
  },

  // Check which store branches are available at a given postal code
  async logAnalyticsEvent ({ dispatch }, data) {    
    // Log event to our foodable tracking endpoint
    dispatch('logFoodableAnalyticsEvent', data)
    // Log event to adjust for marketing attribution
    dispatch('logAdjustEvent', data)
  },

  async getDeviceInfo ({ dispatch }) {
    const deviceInfo = await Device.getInfo()
    await dispatch('save', { key: 'deviceInfo', value: deviceInfo })
  },

  // Foodable Analytics Event Tracking
  async logFoodableAnalyticsEvent ({ rootState, dispatch }, data) {
    if (process.env.NODE_ENV !== 'development') {
      // Add an empty payload, if there is none
      if (!data.hasOwnProperty('payload')) data.payload = {}

      // Add some general information to the payload and stringify the whole payload
      data.payload.order = rootState.neworder ? rootState.neworder.id : 'None'
      data.payload.plan = rootState.newplan ? rootState.newplan.id : 'None'
      data.payload.route = router.currentRoute._value.name
      data.payload = JSON.stringify(data.payload)

      const details = {
        url: rootState.api.foodableAnalytics,
        method: 'POST',
        data: {
          event: data.name,
          payload: data.payload
        }
      }
      dispatch('apiSend', details)

    }
  },


  async logAdjustEvent ({ rootState, dispatch }, data) {
    if (process.env.NODE_ENV !== 'development') {
      // Log event to adjust
      if (!rootState.deviceInfo) {
        await dispatch('getDeviceInfo')
      }
      const os = rootState.deviceInfo.platform
      if (adjustEventCodes && adjustEventCodes.codes && adjustEventCodes.codes[data.name]) {
        // Disable adjust tracking on ios
        // if (os === 'ios' || os === 'android') {
        if (os === 'android' || os === 'web') {
          const foundAdjustEvent = adjustEventCodes.codes[data.name][os]
          let adjustEvent = new AdjustEvent(foundAdjustEvent)
          if (data.revenue) {
            adjustEvent.setRevenue(data.revenue, "EUR")
          }
          const trackingResult = Adjust.trackEvent(adjustEvent)
          console.log(trackingResult)
          return trackingResult
        }
      } else {
        console.error('Did not find adjust event for ', data.name)
      }
    }
  },

  async goToAppStore ({ rootState, dispatch }) {
    dispatch('getDeviceInfo').then(async () => {
      if (rootState.deviceInfo && rootState.deviceInfo.operatingSystem && rootState.deviceInfo.operatingSystem === 'ios') {
        // Open iOS App Store
        dispatch('openUrl', 'https://apps.apple.com/de/app/id1489156001')
      } else {
        // Open Google Play Store
        dispatch('openUrl', 'https://play.google.com/store/apps/details?id=com.foodable.app')
      }
    })
  },

  async showRateUsPopup ({ rootState, dispatch }) {
    if (rootState.profile.show_rate_us_popup) {
      dispatch('openModal', { name: 'rating' })
    }
  },

  // Relay awin_transaction event to adjust
  async relayAwinTransactions ({ rootState, dispatch }) {
    dispatch('apiSend', { url: rootState.api.awinTransactions }).then(transactions => {
      if (transactions && transactions.length > 0) {
        // popup should be shown after first awin transaction
        dispatch('showRateUsPopup')

        transactions.forEach(transaction => {
          if (transaction.event === 'awin_transaction' && !transaction.relayed_to_adjust) {
            const dataObject = { name: 'awin_transaction' }
            if (transaction.payload && typeof transaction.payload === 'string') {
              const payload = JSON.parse(transaction.payload.replaceAll("'", '"'))
              dataObject['revenue'] = payload.commission
            }
            dispatch('logAdjustEvent', dataObject).then(adjustTrackingResult => {
              // Patch the awin transaction endpoint to inform it that the relay happened
              console.log('Sent awin transaction to adjust. This is the result:')
              console.log(adjustTrackingResult)
              const details = {
                url: rootState.api.tracking,
                id: transaction.id,
                method: 'PATCH',
                data: {
                  'relayed_to_adjust': true
                }
              }
              dispatch('apiSend', details)
            })
            // Send another unique event
            dataObject.name = 'awin_transaction_unique'
            dispatch('logAdjustEvent', dataObject)
            dispatch('logFoodableAnalyticsEvent', { name: dataObject.name })
          }
        })
      }
    })
  },

  // Create a tracking event for each impression of a cookbutler recipe image
  trackCookbutlerImpression ({ rootState, dispatch }, recipe) {
    if (recipe && recipe.source && recipe.source === 'Cookbutler') {
      const details = {
        url: rootState.api.tracking + 'create/',
        method: 'POST',
        data: {
          event: "cookbutler_impression",
          payload: recipe.id
        }
      }
      dispatch('apiSend', details)
    }
  },

  // Record recipe impression
  async recordRecipeImpression ({ rootState, dispatch }, impressions) {
    const details = {
      url: rootState.api.recipeImpressions,
      method: 'POST',
      data: impressions
    }
    dispatch('apiSend', details)
  }
}

export default {
  actions
}
