<template>
  <div>
    <Icon name="foodableLightBulb" class="icon-modal" />
    <div class="text-section">
      <div class="headline">Whoa! It's Magic!</div>
      <div class="white-space"></div>
      <div class="headline">Wir haben die Kategorien Guter Verbrauch und Empfohlen neu für dich zusammengestellt.</div>
      <div class="white-space"></div>
      <div class="headline">Diese Kategorien werden immer an deinen aktuellen Warenkob angepasst.</div>
      <div class="text-section text-left" v-for="value in values" :key="value.title">
        <div class="headline">{{ value.title }}</div>
        <div class="secondary-text">{{ value.subtitle }}</div>
        <div class="tertiary-text-gray">{{ value.text }}</div>
      </div>
    </div>
  </div>
  <div @click="$store.dispatch('closeModal')" class="btn-primary">Schließen</div>
</template>

<script>
export default {
  name: 'recipereloadexplanation',
  data () {
    return {
      values: [
        {
          title: 'Guter Verbrauch',
          subtitle: 'Mit Rezepten, deren Zutaten du möglichst vollständig verbrauchst.',
          text: 'Wir passen deine Rezepte in dieser Kategorie immer auf deinen aktuellen Warenkorb und deine Haushaltsgröße an. Damit helfen wir dir, weniger Lebensmittelabfäle zu produzieren!'
        },
        {
          title: 'Empfohlen',
          subtitle: 'Guter Verbrauch, Preis, Saisonalität und Geschmack.',
          text: 'In dieser Kategorie findest du Rezepte, die bezüglich Verbrauch und Preis zu deinem aktuellen Warenkorb passen. Und gleichzeitig deinem Geschmack und der Saison entsprechen!'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.white-space {
  height: 10px;
}

</style>