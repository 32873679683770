<!--

      This modal shows information about the leftovers for the
      newplan (currently active menu that was not yet ordered).
      It displays detailed statistics about how much of each
      ingredient is consumed or not.

 -->

<template>
    <div class="circle-chart">
        <svg :class="['round', { 'green': value > 60 }, { 'red': value < 20 }, { 'yellow': value >= 20 && value <= 60 }]" ref="round" :viewbox="'0 0 ' + size + ' ' + size" :width="size" :height="size">
            <circle class="bg-circle" :cx="size/2" :cy="size/2" :r="radius" :style="strokeWidth" />
            <circle :cx="size/2" :cy="size/2" :r="radius" :style="strokeWidth" />
        </svg>
        <div class="text centered" :style="fontSize">{{ displayValue }}%</div>
    </div>
</template>

<script>
export default {
  name: 'circlechart',
  props: ['size', 'value'],
  data () {
    return {
      radius: 0.3 * this.size,
      displayValue: 0
    }
  },
  computed: {
    fontSize () {
      return 'font-size: ' + this.size * 0.8 / 100 + 'rem;'
    },
    strokeWidth () {
      return 'stroke-width: ' + this.size / 20 + ';'
    }
  },
  methods: {
    increaseDisplayValue () {
      this.displayValue += 1
      if (this.displayValue < this.value) {
        setTimeout(() => { this.increaseDisplayValue() }, 600/(this.value-1))
      }
    }
  },
  mounted () {
    setTimeout(() => {
      const roundCircum = 2 * this.radius * Math.PI
      const roundDraw = this.value * roundCircum / 100
      this.$refs.round.style = 'stroke-dasharray: ' + roundDraw  + ' 999 !important;'
    }, 40)


    this.increaseDisplayValue()
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.circle-chart {
  position: relative;
}

.round {
  transform: rotate(-90deg);
  transition: all 1s ease;
  fill: none;
  stroke-linecap: round;
  stroke-dasharray: 0 999;
  position: relative;
}

.green {
  stroke: $col-font-green;
}

.red {
  stroke: $col-red;
}

.yellow {
  stroke: $col-orange;
}

.bg-circle {
  fill: none;
  stroke: $col-button-green;
  stroke-linecap: round;
  stroke-dasharray: 999 999;
  position: absolute;
  top: 0;
  left: 0;
}

.text {
  padding-bottom: 2px;
}
</style>