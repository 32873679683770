<!--

      This component allows to select an integer amount by clicking + or -
      Furthermore, between those two signs, it displays the integer.

      This component must either receive a function via the property "update"
      or it must receive a "product". Then it will update this product in the
      neworder.

 -->

<template>
  <div class="amount-selector" :key="this.newAmount">
    <div :class="['btn-amount', {'btn-amount-cta' : cta}]" @click.stop="this.decrease()" v-show="amountToDisplay > 0 || !collapsable">
      <Icon name="removeOutline" class="icon-add-remove" />
    </div>
    <div class="amount-value" v-show="amountToDisplay > 0 || !collapsable">
      {{ amountToDisplay }}
    </div>
    <div :class="['btn-amount', {'btn-amount-cta' : cta}]" @click.stop="this.increase()">
      <Icon name="addOutline" class="icon-add-remove" />
    </div>
  </div>
</template>

<script>
import { Plugins, HapticsImpactStyle } from '@capacitor/core'
const { Haptics } = Plugins

export default {
  name: 'amountselector',
  props: ['amount', 'product', 'update', 'increment', 'collapsable', 'cta', 'minimum'],
  data () {
    return {
      newAmount: this.amount,
      timer: null,
      awaitingTimer: false,
      loading: false
    }
  },
  computed: {
    amountToDisplay () {
      if (typeof this.newAmount === 'number') {
        return this.newAmount
      } else if (typeof this.amount === 'number') {
        return this.amount
      }
    },
    incrementNumber () {
      if (this.increment) {
        return this.increment
      } else {
        return 1
      }
    }
  },
  watch: {
    // When the user clicks + or -, the newAmount will be changed
    // Then this watcher will run and after a waiting period of 
    // a couple of seconds, it will trigger the update to happen
    newAmount () {
      clearTimeout(this.timer)
      this.awaitingTimer = true
      const product = this.product
      this.timer = setTimeout(() => {
        if (product) {
          this.loading = true
          this.$store.dispatch('updateProduct', {
            product: product, 
            quantity: this.newAmount
          }).then(() => {
            this.loading = false
          })
        } else {
          this.update(this.newAmount)
        }
      }, 1500)
      this.awaitingTimer = false
    }
  },
  methods: {
    validateMinimum () {
      if (typeof this.minimum === "number") {
        const potentialNewAmount = this.amountToDisplay - this.incrementNumber
        return potentialNewAmount >= this.minimum
      } else {
        return true
      }
    },
    decrease () {
      if (this.newAmount >= this.incrementNumber && this.validateMinimum()) {
        if (!this.loading) {
          this.newAmount = this.amountToDisplay - this.incrementNumber
          Haptics.impact({style: HapticsImpactStyle.Medium})
        } else {
          this.$store.dispatch('showNotification', {
            message: 'Hups. Du warst schneller als unser Server! Bitte probiere es in ein paar Sekunden noch einmal.',
            type: 'Error'
          })
        }
      }
    },
    increase () {
      if (!this.loading) {
        this.newAmount = this.amountToDisplay + this.incrementNumber
        Haptics.impact({style: HapticsImpactStyle.Medium})
      } else {
        this.$store.dispatch('showNotification', {
          message: 'Hups. Du warst schneller als unser Server! Bitte probiere es in ein paar Sekunden noch einmal.',
          type: 'Error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.amount-selector {
  display: flex;
  align-items: center;
  background: $col-white;
  color: $col-font;
  border-radius: 50px;
  height: 24px;
  width: min-content;
}

.amount-value {
  @extend .secondary-text;
  width: 34px;
  text-align: center;
}
</style>
