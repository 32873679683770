<template>
  <div>
    <Icon name="foodableBell" class="icon-modal" />
    <div class="text-section">
      <div class="headline">App Update herunterladen</div>
      <div class="main-text">
        Deine App-Version ({{ $store.state.appversion }}) ist veraltet und 
        funktioniert möglicherweise nicht mehr richtig.
        Lade bitte jetzt die neuste Version im App Store herunter, 
        damit du die App weiterhin ohne Probleme nutzen kannst.
      </div>
      <div class="main-text" v-if="this.$store.getters.debugmode && this.$store.state.expectedAppVersion">
        Diese App hat die Version {{ $store.state.appversion }}.
        Unser Server erwartet die Version {{ $store.state.expectedAppVersion }}
      </div>
    </div>
    <div>
      <div @click="$store.dispatch('closeModal')" class="btn-tertiary" fill="clear">Nein</div>
    </div>
    <div @click="$store.dispatch('goToAppStore')" class="btn-primary">Zum App Update</div>
  </div>
</template>
