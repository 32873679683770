<template>
  <div @click.stop="ilike()" :key="isLiked" class="like-area" v-if="recipe">
    <div class="recipe-feature" v-if="showLikes">{{ recipe.likes }}</div>
    <Icon name="heartOutline" v-if="!isLiked" class="heart-icon" />
    <Icon name="heart" v-if="isLiked" class="heart-icon liked" />
  </div>
</template>

<script>

export default {
  name: 'like-button',
  props: [
    'recipe',
    'showLikes'
  ],
  data () {
    return {
      likingInProgress: null
    }
  },
  computed: {
    isLiked () {
      if (this.$store.state.recipes && this.recipe) {
        const foundRecipeOffline = this.$store.state.recipes.find(r => r.id === this.recipe.id)
        if (foundRecipeOffline) {
          return foundRecipeOffline.is_liked
        }
      }
    }
  },
  methods: {
    ilike() {
      if (this.likingInProgress) {
        this.$store.dispatch('showNotification', { message: 'Du warst zu schnell. Bitte versuche es gleich noch einmal.', type: 'Error' })
      } else {
        this.likingInProgress = true
        this.$store.dispatch('ilike', this.recipe).then(updatedRecipe => {
          this.likingInProgress = false
          this.recipe.is_liked = updatedRecipe.is_liked
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.like-area {
  display: flex;
}

.heart-icon {
  --stroke: #{$col-gray-font};
  --stroke-width: 1.6rem;
  height: 32px;
  width: 32px;
}

.liked {
  fill: #{$col-red} !important;
  color: #{$col-red} !important;
  --stroke: #{$col-red} !important;
}
</style>
