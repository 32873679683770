<!--

      This component requires users to verify their email address
      after registering.

 -->

<template>
  <div>
    <Icon name="foodableBell" class="icon-modal" />
    <div class="text-section">
      <div class="headline">E-Mail Verifikation erforderlich</div>
      <div class="main-text">Bitte geh in dein E-Mail Postfach und klicke auf den Link in der
        E-Mail, die wir dir geschickt haben.</div>
    </div>
    <div class="btn-tertiary standalone" @click="sendLinkAgain()">Link erneut senden</div>
    <div @click="isEmailVerified()" class="btn-primary standalone">Erledigt</div>
    <br><br>
    <div 
      class="btn-tertiary standalone" 
      v-if="!showNoEmailHelp" 
      @click="openHelp('noEmail')"
    >
      Ich habe keine Mail bekommen?
    </div>
    <div class="text-section secondary-text" v-if="showNoEmailHelp" @click="closeHelp('noEmail')">
      Bitte schau auch mal in deinen Spam Ordner. Ist sie vielleicht da gelandet?
      Du kannst oben auf "Link erneut senden" klicken, damit wir dir noch eine Mail schicken.
      Wenn trotzdem weiterhin keine 
      angekommen ist, könnte es sein, dass du dich bei der Registrierung vertippt hast? 
      Du hast dich registriert mit <strong>{{ $store.state.profile.email }}</strong>.
      Falls das nicht deine korrekte E-Mail Adresse ist, klicke oben rechts auf das Kreuz und 
      registriere dich nochmal bitte.
    </div>
    <div 
      class="btn-tertiary standalone" 
      v-if="!showVerifyHelp" 
      @click="openHelp('verify')"
    >
      Warum ist das nötig?
    </div>
    <div class="text-section secondary-text" v-if="showVerifyHelp" @click="closeHelp('verify')">
      Dein Datenschutz ist uns wichtig!<br>Daher senden wir dir eine Email
      zur Bestätigung, dass auch wirklich du dich registriert hast. Dieses
      Vorgehen ist auch von der Datenschutz-Grundverordnung (DSGVO)
      gefordert.
    </div>
  </div>
</template>

<script scoped>
export default {
  name: 'verifyemail',
  data () {
    return {
      showVerifyHelp: false,
      showNoEmailHelp: false,
      allowPolling: null
    }
  },
  methods: {
    sendLinkAgain () {
      const details = {
        url: this.$store.state.api.auth.requestverificationemail
      }
      this.$store.dispatch('apiSend', details).then(() => {
        this.$store.dispatch('showNotification', {
          type: 'Success',
          message: 'Wir haben dir einen neuen Link zugeschickt an ' + this.$store.state.profile.email + '. Schau bitte auch in deinen Spam Ordner!'
        })
      })
    },

    // Download the profile to check if the email verification happened.
    isEmailVerified () {
      this.$store.dispatch('getProfileFromAPI').then( () => {
        if (this.$store.state.profile && this.$store.state.profile.email_confirmed) {
          this.$store.dispatch('closeModal')
        } else {
          this.$store.dispatch('showNotification', {
            type: 'Error',
            message: 'Wirklich? Bitte versuche es in ein paar Sekunden erneut.'
          })
        }
      })
    },

    pollIsEmailVerified () {
      if (this.allowPolling) {
        this.$store.dispatch('getProfileFromAPI').then( () => {
          if (this.$store.state.profile && this.$store.state.profile.email_confirmed) {
            this.$store.dispatch('closeModal')
          } else {
            setTimeout(this.pollIsEmailVerified, 3000)
          }
        })
      }
    },

    openHelp ( name ) {
      if ( name === 'noEmail') {
        this.showVerifyHelp = false
        this.showNoEmailHelp = true
      } else if ( name === 'verify' ) {
        this.showNoEmailHelp = false
        this.showVerifyHelp = true
      }
    },

    closeHelp ( name ) {
      this.showVerifyHelp = false
      this.showNoEmailHelp = false
    }
  },
  mounted () {
    this.allowPolling = true
    this.pollIsEmailVerified()
  }
}
</script>
