import { Plugins } from '@capacitor/core'
const { Storage } = Plugins

// Actions
const actions = {
  // Direct interaction with the storage. Do not use this function.
  async setOffline({}, data) {
    await Storage.set({
      key: data.key,
      value: JSON.stringify(data.value)
    });
  },

  // Direct interaction with the storage. Do not use this function.
  async getOffline({}, key) {
    const ret = await Storage.get({ key: key });
    try {
      return JSON.parse(ret.value);
    } catch (e) {
      console.error('Could not parse JSON from device storage')
      console.error(key, ret)
    }
  },

  // The save function first saves its input in the rootState and then offline.
  // Use it to save data in the rootState. To delete data, save a null value.
  // However, do not use this function to save profile settings.
  // Use applySettings instead.
  async save({ rootState, dispatch }, data) {
    if(data.value !== undefined) {
      rootState[data.key] = data.value
      await dispatch('setOffline', { key: data.key, value: data.value })
    }
  },

  // The startup function populates the rootState with the data in the local
  // storage.
  async populateState({ rootState, dispatch }) {
    let offlineValue
    for (const [key, value] of Object.entries(rootState)) {
      offlineValue = await dispatch('getOffline', key)
      if ( offlineValue ) {
        rootState[key] = offlineValue
      }
    }
  },

  // Delete all app-related data stored locally on the smartphone.
  async deleteAllData({ rootState, dispatch }) {
    for (const [k, v] of Object.entries(rootState)) {
      if (k != 'api' && k != 'recipeImpressionFlags' && k != 'settingsPages' && k != 'paymentMethodTypes') {
        dispatch('setOffline', { key: k, value: null })
        rootState[k] = null
      }
    }
  },

}

export default {
  actions
}
