<template>
  <div>
    <img src="../../../assets/img/mealkit.svg" alt="">
    <div class="text-section">
      <div class="headline">Whoa! It's Magic!</div>
      <div class="white-space"></div>
      <div class="headline">Wir haben dir mithilfe der foodable KI eine Kochbox zusammengestellt.</div>
      <div class="white-space"></div>
      <div class="headline">Das bietet dir viele Vorteile!</div>
      <div class="text-section text-left" v-for="value in values" :key="value.title">
        <div class="headline">{{ value.title }}</div>
        <div class="secondary-text">{{ value.subtitle }}</div>
        <div class="tertiary-text-gray">{{ value.text }}</div>
      </div>
    </div>
  </div>
  <div @click="$store.dispatch('closeModal')" class="btn-primary">Schließen</div>
</template>

<script>
export default {
  name: 'mealplannerexplanation',
  data () {
    return {
      values: [
        {
          title: 'Verbrauch',
          subtitle: 'Gut für die Umwelt. Und für deinen Geldbeutel.',
          text: 'Ein großer Teil der Lebensmittelverschwendung entsteht in Privathaushalten. Unsere KI stellt dir Rezepte so zusammen, dass du möglichst viele deiner verderblichen Produkte auch verbrauchst.'
        },
        {
          title: 'Preis',
          subtitle: 'Durch unsere KI erstellte Menüs sind im Schnitt deutlich günstiger als manuell geplante Wocheneinkäufe.',
          text: 'Rezepte für Haushaltsgröße, Produktverfügbarkeit und passend zum Warenkorb auszuwählen ist aufwendig. Unsere KI stellt dir Rezepte so zusammen, dass der Preis für dich im Rahmen bleibt.'
        },
        {
          title: 'Geschmack',
          subtitle: 'Spare Zeit beim Wocheneinkauf und finde spannende neue Rezepte!',
          text: 'Passende Rezepte und Inspiration zu finden ist nicht immer leicht. Unsere KI versucht deinen Geschmack zu verstehen und empfiehlt Rezepte, die zu dir passen.'
        },
        {
          title: 'Saisonalität',
          subtitle: 'Deinen CO2 Fußabdruck zu reduzieren war noch nie so einfach - und so lecker!',
          text: 'Saisonal zu essen hat einen großen Einfluss auf die CO2 Bilanz deiner Ernährung. Unsere KI empfiehlt dir vornehmlich Rezepte, die zur aktuellen Saison passen.'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.white-space {
  height: 10px;
}

</style>