<template>
  <div class="step">
    <div class="step-line"></div>
    <div class="step-number">{{ number }}</div>
    <div class="step-text text-left">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step',
  props: ['number']
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.step {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: start;
  margin: 10px 0;
  position: relative;
}

.step-number {
  @extend .main-text;
  z-index: 1;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  background: $col-font-green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.step-line {
  background: $col-font-green;
  height: 100%;
  width: 1px;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 12px;
}

.step-text {
  @extend .main-text;
  padding-top: 2px;
}
</style>
