<template lang="html">
  <div v-html="theSVG" />
</template>

<script>
import * as allIcons from 'ionicons/icons';
import * as allfoodableIcons from '@/foodableIcons';

export default {
  name: "icon",
  props: ['name'],
  computed: {
    theSVG () {
      if (this.name && this.name.includes('foodable')) {
        try {
          return allfoodableIcons[this.name]
        } catch (e) {
          console.error("There is no foodable icon called " + this.name)
          console.error(allfoodableIcons)
        }
      } else {
        try {
          return allIcons[this.name].replace('data:image/svg+xml;utf8,', '')
        } catch (e) {
          console.error("There is no icon called " + this.name)
          console.error(allIcons)
        }
      }
    }
  }
}
</script>

<style lang="scss">

.ionicon-fill-none {
  fill: var(--fill, none);
}

.ionicon-stroke-width {
  stroke-width: 1.6rem;
  stroke-width: var(--stroke-width, 1.6rem);
  stroke: black;
  stroke: var(--stroke, black);
}

.icon-inner,
.ionicon {
  display: block;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  font-size: var(--font-size, 1rem);
}

</style>