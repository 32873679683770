import router from '../../router'
import * as Sentry from '@sentry/browser'

// Actions
const actions = {

  async prepareRequest({ rootState, dispatch }, details) {
    // Some calls need an id, make sure calls work with or without id
    if (!details.id) { details.id = '' }
    if (details.id != '' && !details.id.toString().includes('/')) { details.id += '/' }

    // Assume it to be a get request, if not explicitly specified
    if (!details.method) { details.method = 'GET' }

    // Create headers for the request, if needed
    if (!details.header) { details.header = { "Content-Type": "application/json" } }
    if (rootState.accesstoken
        && !details.header.hasOwnProperty('Authorization')
        && !details.noauthheader) {
      details.header["Authorization"] = "Bearer " + rootState.accesstoken
    }

    // Conditionally add query parameters to the url
    details['queryparams'] = ''
    if (!details.url.includes("auth")
        && !details.url.includes("search")
        && !details.url.includes('whisk')
        && !details.url.includes('?')) {
      details.queryparams = "?format=json"
    }

    // Take the url of the api and add the relative path
    if (!details.url.includes("http")) {
      details.url = process.env.VUE_APP_API + details.url
    }

    // Return the url and the data object for the request
    return [
      details.url + details.id + details.queryparams,
      {
        method: details.method,
        headers: details.header,
        signal: details.signal,
        body: JSON.stringify(details.data)
      }
    ]
  },


  async performRequest({ rootState, dispatch }, request) {
    // Try to do the actual api call
    try {
      dispatch('save', { key: 'callsInProgress', value: rootState.callsInProgress += 1 })
      const output = await fetch(request[0], request[1])
      dispatch('save', { key: 'callsInProgress', value: Math.max(rootState.callsInProgress -= 1, 0) })
      return output
    } catch (e) {
      console.error(`The api call failed: ${e}`)
      dispatch('save', { key: 'callsInProgress', value: rootState.callsInProgress -= 1 })
      
      // Check the internet connection
      if (rootState.callsInProgress === 0) {
        dispatch('checkInternetConnection')
      }
    }
  },


  async parseAnswer({ rootState, dispatch }, answer) {
    // Try to parse the json of the result
    let response = {}
    const text = await answer.text()
    const status = answer.status

    try {
      response = JSON.parse(text)
    } catch (e) {
      if (!(e instanceof SyntaxError)) {
        throw e
      }
    }

    if (status === 503 && response.detail) {
      dispatch('showNotification', { message: response.detail, type: 'Error' })
    }

    if (status.toString()[0] === '4' && status !== 401) {
      // log 4xx errors to sentry; ignore 401, it's handled by token refreshing
      Sentry.configureScope(function (scope) {
        scope.setExtra(
          'response',
          {
            ok: false,
            status: status,
            url: answer.url,
            statusText: answer.statusText,
            text: text
          }
        )
      })
      Sentry.captureEvent({ message: `Got ${status} response from server`, level: 'warning' })
    }

    return response
  },

  // In case the above function does not work, we should go back to this:
  //
  // if (answer.ok || answer.status === 401) {
  //   const text = await answer.text()
  //   if (text !== '') {
  //     return JSON.parse(text)
  //   }
  // }

  async evaluateResult({ rootState, dispatch }, input) {
    const result = input.r
    const details = input.d

    // Step 1: Refresh the access token if invalid
    if (!details.lastchance
        && result.detail
        && result.detail.includes("Invalid token")) {
      details['lastchance'] = true
      return await dispatch('refreshTheToken', details)
    } else {
      return result
    }
  },


  async apiSend({ rootState, dispatch }, details) {
    // Step 1: Prepare the request
    const request = await dispatch('prepareRequest', details)
    if (!(request && request.length === 2)) { return }

    // Step 2: Perform the actual api call
    const answer = await dispatch('performRequest', request)
    if (!answer) { return }

    // Step 3: Import the answer to JavaScript
    const result = await dispatch('parseAnswer', answer)
    if (!result) { return }

    // Step 4: Evaluate the answer
    return await dispatch('evaluateResult', {r: result, d: details})
  },


  async refreshTheToken({ rootState, dispatch }, apicall) {
    if (!rootState.refreshingtoken) {
      console.log("Starting to refresh the token")
      // Do not allow this function to run multiple times in parallel.
      // This is not allowed, since the refresh token is being replaced
      // every time. Thus, it would happen that the parallely running
      // function call would still use the old refresh token, while the
      // new refresh token has already been issued.
      rootState.refreshingtoken = true

      // This function requires a refresh token.
      // If the refresh token is null, this is due to the fact that the user
      // had an invalid refresh token before, and it was thus deleted.
      // Usually this happens when a client switches between APIs or api versions.
      // In those cases, the user needs to login again.
      if (!rootState.refreshtoken) { router.push({ path: '/signin', replace: true }); return }

      const token = await dispatch('getNewAccessToken')

      if ( token && token.access_token && !token.detail && !token.error ) {
        if (token.refresh_token != rootState.refreshtoken) {
          console.log('We received a new refresh token... saving it...')
          await dispatch('save', { key: 'refreshtoken', value: token.refresh_token })
        }
        console.log('Saving the new access token and performing the request...')
        await dispatch('save', { key: 'accesstoken', value: token.access_token })
        if (apicall) {
          // Since the authorization header might contain the old accesstoken,
          // it is safer, to delete it. Then the function prepareRequest will
          // create a new updated header, since none exists.
          delete apicall.header
          rootState.refreshingtoken = false
          return dispatch('apiSend', apicall)
        }
      } else {
        // The accesstoken is invalid, and we didn't get a new one, which is why
        // we delete the old access token. Then, upon startup, the app knows that
        // the user has to be logged in again.
        dispatch('save', { key: 'accesstoken', value: null })
        if ( token && token.error ) {
          // The refresh token is invalid. This could happen, if an old refresh
          // token is being used. We prevent this by checking if this function
          // is already running. However, this error message can also have
          // other causes. Therefore, the safest solution is to login again.
          console.log(`Error refreshing the token: ${token.error}`)
          dispatch('save', { key: 'refreshtoken', value: null })
          router.push({ path: '/login', replace: true })
        }
      }
      rootState.refreshingtoken = false
    } else {
      console.log("Not refreshing the token, since a token refresh is in progress.")
    }
  },



  async checkInternetConnection ({ dispatch }) {
    function isOffline (who, truth) {
      if (who === 'foodable') {
        dispatch('save', { key: 'foodableOffline', value: truth })
      } else if (who === 'google') {
        dispatch('save', { key: 'googleOffline', value: truth })
      }
    }

    // Check if the foodable server is reachable
    let img = document.body.appendChild(document.createElement("img"))
    img.onload = () => {
      isOffline && isOffline.constructor == Function && isOffline('foodable', false)
    }
    img.onerror = () => {
      isOffline && isOffline.constructor == Function && isOffline('foodable', true)
    }
    img.src = process.env.VUE_APP_API + 'static/rest_framework/img/grid.png'
    img.style = 'width: 1px; height: 1px;'

    // Check if Google is reachable and use it as a proxy for Internet
    // connectivity in general
    let img2 = document.body.appendChild(document.createElement("img"))
    img2.onload = () => {
      isOffline && isOffline.constructur == Function && isOffline('google', false)
    }
    img2.onerror = () => {
      isOffline && isOffline.constructur == Function && isOffline('google', true)
    }
    img2.src = 'https://www.gstatic.com/navigationdrawer/feedback_icon.svg'
    img2.style = 'width: 1px; height: 1px;'
  }
}

export default {
    actions
}
