<template>
  <div :class="['info-box', {'padding-top': !noPaddingTop}]">
    <Icon v-if="this.icon" :class="['info-icon', {'info-icon-right': right}]" :name="icon" />
    <div :class="['green-box', {'green-box-left': right}]">
      <div class="info-text">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'info-box',
  props: [
    'icon',
    'text',
    'noPaddingTop',
    'right'
  ]
}

</script>

<style lang="scss" scoped>
@import './../../theme/main.scss';

.info-box {
  display: flex;
}

.padding-top {
  padding-top: 20px;
}

.info-icon {
  height: auto;
  min-width: 60px;
  margin: 10px 20px;
  z-index: 1;
  flex-grow: 2;
}

.info-icon-right {
  order: 1;
  margin-left: -60px;
  margin-right: 60px;
  margin-top: -10px;
}

.green-box {
  @extend .secondary-text;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  text-align: left;
  background: $col-pastell-green;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  flex-grow: 1;
}

.green-box-left {
  margin-top: 40px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  padding-right: 35px;
}


</style>
