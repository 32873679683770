// Order
//
// This module has the purpose to manage manipulations to the order.
// The order contains an array of orderproducts some of which contain
// the same product.
//
// Since the shopping list is not supposed to display the same product
// multiple times, we merge and aggregate them.
//
// However, this creates the complexity that if the user clicks on
// such an aggregated product in the shopping list, e.g. to increase
// its quantity, we have to disentangle it and determine which of the
// oderproducts to change.
//
// This complexity is further increased by the fact that there are
// several places from which a user can change an order:
// Shopping list, product search, alternatives page, small amount
// products page (aka durable goods page).

// Actions
const actions = {
  async mergeSimilarOrderProducts ({ dispatch }, products) {
    let mergedProducts = []
    const sortedProducts = products.sort((a, b) => a.product_name.localeCompare(b.product_name))
    sortedProducts.forEach(product => {
      let foundProduct = null

      mergedProducts.forEach(orderProductArray => {
        orderProductArray.forEach(mergedProduct => {
          if (mergedProduct.product_id === product.product_id) {
            foundProduct = mergedProduct.product_id
          }
        })
        // We found an orderProduct in the orderProductArray, which contains
        // the same product. Therefore, we add the current orderProduct to
        // the same orderProductArray as this other orderProduct.
        if (foundProduct) {
          orderProductArray.push(product)
        }
      })

      // Since this orderProduct is not yet part of any orderProductArray
      // and we did not find any other orderProductArray with identical products
      // we create a new orderProductArray and add this orderProduct into it.
      if (!foundProduct) {
        mergedProducts.push([product])
      }
    })

    let aggregatedMergedProducts = []
    await Promise.all(mergedProducts.map(mp => {
      dispatch('aggregate', mp).then(res => {
        aggregatedMergedProducts.push(res)
      })
    }))

    // Sort by durable and name
    const aggregatedMergedProductsDurable = aggregatedMergedProducts.filter(
      aggregatedMergedProduct => aggregatedMergedProduct.is_durable
    )
    const aggregatedMergedProductsNotDurable = aggregatedMergedProducts.filter(
      aggregatedMergedProduct => !aggregatedMergedProduct.is_durable
    )

    aggregatedMergedProductsDurable.sort((a, b) => a.product_name.localeCompare(b.product_name))
    aggregatedMergedProductsNotDurable.sort((a, b) => a.product_name.localeCompare(b.product_name))

    aggregatedMergedProducts = aggregatedMergedProductsDurable.concat(aggregatedMergedProductsNotDurable)
    
    return aggregatedMergedProducts
  },


  // Takes a list of order products that contain the same products
  // and aggregates them.
  aggregate ({}, identicalProducts) {
    if (identicalProducts && identicalProducts.length > 0) {
    
      // Calculate the total quantity
      let totalQuantity = 0
      identicalProducts.forEach(p => {
        totalQuantity += p.quantity
      })

      // Collect all order_product_ids into an array
      let aggregatedOrderProducts = []
      identicalProducts.forEach(ip => {
        let aggregationObject = {}
        if (ip.id) {
        aggregationObject['id'] = ip.id
        }
        if (ip.shopping_ingredient_id) {
        aggregationObject['shopping_ingredient_id'] = ip.shopping_ingredient_id
        }
        aggregatedOrderProducts.push(aggregationObject)
      })

      let isDurable = false
      let shoppingIngredientId = null
      identicalProducts.forEach(p => {
        if (p.is_durable) isDurable = true
        if (p.shopping_ingredient_id) shoppingIngredientId = p.shopping_ingredient_id
      })

      // Construct the final orderProduct object
      return {
        img_url: identicalProducts[0].img_url,
        price: identicalProducts[0].price,
        product_category: identicalProducts[0].product_category,
        product_id: identicalProducts[0].product_id,
        product_name: identicalProducts[0].product_name,
        is_durable: isDurable,
        quantity: totalQuantity,
        shopping_ingredient_id: shoppingIngredientId,
        originalOrderProducts: aggregatedOrderProducts
      }
    }
  },


  async getMergedProductById ({ rootState, dispatch }, productId) {
    const mergedOrderProducts = await dispatch('mergeSimilarOrderProducts', rootState.neworder.orderproducts)
    const foundTheProduct = mergedOrderProducts.find(m => m.product_id === productId)
    if (foundTheProduct) {
      return foundTheProduct
    }
  },


  async getOrderProductById ({ rootState }, orderProductId) {
    return rootState.neworder.orderproducts.find(o => o.id === orderProductId)
  },


  // When the target quantity is known, this function is the
  // way to go.
  async updateProduct ({ dispatch }, data) {
    const cleanProduct = await dispatch('cleanProduct', data.product)
    await dispatch('determineNecessaryOperation', {
      product: cleanProduct,
      action: 'edit',
      quantity: data.quantity
    })
  },

  
  // This function adds a product to the existing new order.
  // It is useful to add a small amount product.
  async addProductToNewOrder ({ dispatch }, product) {
    const cleanProduct = await dispatch('cleanProduct', product)
    const foundProductInNewOrder = await dispatch('getMergedProductById', cleanProduct.id)
    let oldQuantity = 0
    if (foundProductInNewOrder && foundProductInNewOrder.hasOwnProperty('quantity')) {
      oldQuantity = foundProductInNewOrder.quantity
    }
    dispatch('determineNecessaryOperation', {
      product: cleanProduct,
      action: 'edit',
      quantity: oldQuantity + 1
    })
  },


  // This function removes a given product from the existing new order.
  // It is useful to remove a small amount product.
  async removeProductFromNewOrder ({ dispatch }, product) {
    const cleanProduct = await dispatch('cleanProduct', product)
    const foundProductInNewOrder = await dispatch('getMergedProductById', cleanProduct.id)
    if (foundProductInNewOrder && foundProductInNewOrder.hasOwnProperty('quantity')) {
      dispatch('determineNecessaryOperation', {
        product: cleanProduct,
        action: 'edit',
        quantity: foundProductInNewOrder.quantity - 1
      })   
    }
  },


  // This function replaces a product with a new product in the existing 
  // new order
  async replaceProductInNewOrder ({ dispatch }, data) {
    const cleanProduct = await dispatch('cleanProduct', data.product)
    const cleanNewProduct = await dispatch('cleanProduct', data.newProduct)
    dispatch('determineNecessaryOperation', {
      product: cleanProduct,
      newProduct: cleanNewProduct,
      action: 'replace'
    })
  },


  // Product data comes from 4 different API endpoints and in all cases has
  // slight differences in structure and naming of properties. Thus we clean
  // the product data to unify it and reduce all unnecessary information.
  async cleanProduct ({}, product) {
    if (product) {
      let id
      let quantity
    
      // Sometimes we get product_id and sometimes it's just called
      // id (e.g. when we get a product from the product search).
      if (product.hasOwnProperty('product_id')) {
        id = product.product_id
      } else if (product.id) {
        id = product.id
      }
    
      // Sometimes we get quantity and sometimes it's called amount.
      if (product.hasOwnProperty('quantity')) {
        quantity = product.quantity
      } else {
        quantity = product.amount
      }

      return {
        id: id,
        quantity: quantity,
        shoppingIngredientId: product.shopping_ingredient_id,
        originalOrderProducts: product.originalOrderProducts,
        is_durable: product.is_durable
      }
    }
  },


  // This function is based on these specifications:
  // https://foodable.atlassian.net/wiki/spaces/DEV/pages/21102593/OrderProduct+Update+-+ShoppingIngredient
  async determineNecessaryOperation ({ dispatch }, data) {
    const recipeOrderProductId = await dispatch('getRecipeOrderProduct', data.product)
    const nonRecipeOrderProductId = await dispatch('getNonRecipeOrderProduct', data.product)

    if (data.action === 'edit') {

      if (recipeOrderProductId || nonRecipeOrderProductId) {

        // There is an order product already. -> Delete or change it.
        const orderProductId = recipeOrderProductId ? recipeOrderProductId : nonRecipeOrderProductId

        if (!data.product.is_durable && data.quantity <= 0) {

          // The quantity is about to be reduced to 0. -> Delete it.
          await dispatch('deleteOrderProduct', orderProductId)

        } else {

          // The quantity will stay above 0. -> Change it.
          const orderProductQuantityDiff = await dispatch('calcQuantityDiff', {
            newQuantity: data.quantity,
            orderProductId: orderProductId,
            product: data.product
          })
          await dispatch('changeOrderProductQuantity', { 
            orderProductId: orderProductId,
            newQuantity: orderProductQuantityDiff
          })

        }
      } else {

        // There is no orderproduct yet. Create one.
        const newOrderProductQuantityDiff = await dispatch('calcQuantityDiff', {
          newQuantity: data.quantity,
          orderProductId: null,
          product: data.product
        })
        await dispatch('createNewOrderProduct', {
          product: data.product,
          newQuantity: newOrderProductQuantityDiff
        })

      }

    } else if (data.action === 'replace') {

      if (nonRecipeOrderProductId) {
        await dispatch('replaceProductInOrderProduct', {
          orderProductId: nonRecipeOrderProductId,
          newProductId: data.newProduct.id
        })
      } else if (recipeOrderProductId) {
        await dispatch('replaceProductInOrderProduct', {
          orderProductId: recipeOrderProductId,
          newProductId: data.newProduct.id
        })
      }

    }
  },


  async calcQuantityDiff ({ dispatch }, data ) {
    // Possible input arguments:
    //  - data.newQuantity (required)
    //  - data.orderProductId (optional)
    //  - data.product (required)

    // 1. Get a merged product containing all orderproducts 
    //  with this orderproduct's product id to extract its
    //  quantity.
    const mergedProduct = await dispatch('getMergedProductById', data.product.id)
    let oldTotalQuantity = 0
    if (mergedProduct && mergedProduct.hasOwnProperty('quantity')) {
      oldTotalQuantity = mergedProduct.quantity
    }

    // 2. Get the quantity of this particular orderproduct
    let thisQuantity = 0
    if (data.orderProductId) {
      const thisOrderProduct = await dispatch('getOrderProductById', data.orderProductId)
      thisQuantity = thisOrderProduct.quantity
    }

    // 3. Subtract thisQuantity from the oldTotalQuantity
    //  This difference will remaing unchanged when we only change
    //  this order product, which is why we have to take it into
    //  account when changing the quantity.
    const oldQuantityWithoutThisOrderProduct = oldTotalQuantity - thisQuantity

    // 4. Take newQuantity and substract this given total quantity
    //  Since 'newQuantity' is the target quantity, and the
    //  'oldQuantityWithoutThisOrderProduct' will not be changed, we
    //  must calculate their diff.
    const quantityDiff = data.newQuantity - oldQuantityWithoutThisOrderProduct

    // 5. Return the result. This is the amount by which we have to change
    //  the quantity of the to-be-changed order product.
    return quantityDiff
  },


  // Searches the new order for the provided product and checks
  // if there is an underlyting order product, which has a
  // shopping ingredient id and is thus required for the recipes.
  getRecipeOrderProduct ({ rootState }, product) {
    const orderProducts = rootState.neworder.orderproducts
    const recipeOrderProducts = orderProducts.filter(o => o.shopping_ingredient_id)
    const relevantRecipeOrderProducts = recipeOrderProducts.filter(o => o.product_id === product.id)
    if (relevantRecipeOrderProducts && relevantRecipeOrderProducts.length > 0) {
      return relevantRecipeOrderProducts[0].id
    }
  },


  // Searches the new order for the provided product and checks
  // if there is an underlying order product, which does not
  // have a shopping ingredient id and is thus not required for
  // any of the recipes.
  getNonRecipeOrderProduct ({ rootState }, product) {
    const orderProducts = rootState.neworder.orderproducts
    const nonRecipeOrderProducts = orderProducts.filter(o => !o.shopping_ingredient_id)
    const relevantNonRecipeOrderProducts = nonRecipeOrderProducts.filter(o => o.product_id === product.id)
    if (relevantNonRecipeOrderProducts && relevantNonRecipeOrderProducts.length > 0) {
      return relevantNonRecipeOrderProducts[0].id
    }
  },


  // PATCH new order to change the order quantity
  async changeOrderProductQuantity ({ rootState, dispatch }, data) {
    const details = {
      url: rootState.api.shop.order + rootState.neworder.id + '/products/',
      id: data.orderProductId,
      method: 'PATCH',
      data: {
        quantity: data.newQuantity
      }
    }
    await dispatch('apiSend', details).then(async () => {
      await dispatch('refreshExistingOrder', true)
    })
  },


  // Creates a new order product. If provided, it uses the property
  // "shopping_ingredient_id" and thus creates a recipe product.
  // This is only necessary for small amount products (aka durable goods).
  // Else if creates a non-recipe product, by not sending a 
  // shopping ingredient id.
  async createNewOrderProduct ({ rootState, dispatch }, data) {
    let dataObject = {
      product: data.product.id,
      quantity: data.newQuantity,
    }

    if (data.product.shoppingIngredientId) {
      dataObject['shopping_ingredient'] = data.product.shoppingIngredientId
    }

    const details = {
      url: rootState.api.shop.order + rootState.neworder.id + '/products/',
      method: 'POST',
      data: dataObject
    }
    await dispatch('apiSend', details).then(async () => {
      await dispatch('refreshExistingOrder', true)
    })
  },


  async deleteOrderProduct ({ rootState, dispatch }, orderProductId) {
    const details = {
      url: rootState.api.shop.order + rootState.neworder.id + '/products/',
      id: orderProductId,
      method: 'DELETE'
    }
    await dispatch('apiSend', details).then(async () => {
      await dispatch('refreshExistingOrder', true)
    })
  },


  // This function patches an order product in order to replace
  // its product with a different product, which the user
  // selected in the alternatives page (Product.vue).
  async replaceProductInOrderProduct ({ rootState, dispatch }, data) {
    const details = {
      url: rootState.api.shop.order + rootState.neworder.id + '/products/',
      id: data.orderProductId,
      method: 'PATCH',
      data: {
        product: data.newProductId
      }
    }
    await dispatch('apiSend', details).then(async () => {
      await dispatch('refreshExistingOrder', true)
    })
  }

}

export default {
  actions
}