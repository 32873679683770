<template>
  <div>
    <Icon name="foodableHeart" class="icon-modal" />
    <div class="text-section">
      <div class="headline" v-if="$store.getters.oldCustomerJourney">Hast du diese Kochbox bei REWE bestellt?</div>
      <div class="headline" v-if="!$store.getters.oldCustomerJourney">Hast du diesen Warenkorb bei REWE bestellt?</div>
    </div>
    <div class="text-section">
      <div class="main-text" v-if="$store.getters.oldCustomerJourney">
        Bei deinem letzten Besuch in der foodable App hast du diese Kochbox 
        geöffnet und bist damit zu REWE weitergeleitet worden. Du hast aber 
        noch nicht bestätigt, dass du sie gekauft hast. Hast du?
      </div>
      <div class="main-text" v-if="!$store.getters.oldCustomerJourney">
        Bei deinem letzten Besuch in der foodable App bist du mit diesem 
        Warenkorb zu REWE weitergeleitet worden. Du hast aber noch nicht 
        bestätigt, dass du die Rezepte und Produkte gekauft hast. Hast du?
      </div>
    </div>
    <div @click="openMenu()">
      <MenuCard v-if="$store.state.newplan" :openPlan="$store.state.newplan" />
    </div>
    <div @click="didNotOrder()" class="btn-tertiary" fill="clear">Nein, ich habe nicht bei REWE bestellt</div>
    <div @click="didOrder()" class="btn-primary">Ja, ich habe bei REWE bestellt</div>
    <div class="text-section secondary-text-gray">
      Wenn du die Bestellung hier bestätigst, wird sie im Tab "Kochen & Essen"
      ganz oben angezeigt. Aktiviere sie dort, um die Rezepte zu sehen.
    </div>
  </div>
</template>


<script>
import MenuCard from '@/components/cards/MenuCard.vue'

export default {
  name: 'didyouorder',
  components: {
    MenuCard
  },
  methods: {
    openMenu () {
      this.$store.dispatch('closeModal')
    },
    didNotOrder () {
      this.$store.dispatch('save', { key: 'neworderWithLastStepRewe', value: null })
      this.$store.dispatch('closeModal')
    },
    didOrder () {
      this.$store.dispatch('foodableOrdering', true) // goToCooking = true -> use other navigation after ordering
      this.$store.dispatch('closeModal')
    }
  }
}
</script>
