<!--

      This modal shows information about the leftovers for the
      newplan (currently active menu that was not yet ordered).
      It displays detailed statistics about how much of each
      ingredient is consumed or not.

 -->

<template>
  <div class="text-section">
    <CircleChart :size="200" :value="consumption" />
    <div class="main-text">der schnell verderblichen Rezept-Zutaten werden verbraucht.</div>
    <div class="secondary-text-gray">Wenn du mehr Rezepte oder Portionen planst, kann dir unser System bessere Vorschläge machen um weniger zu verschwenden.</div>
  </div>
  <InfoBox icon="foodableLightBulb">
    Unsere intelligente Rezept-Zusammenstellung optimiert insbesondere den Verbrauch von schnell-verderblichen Lebensmitteln. Gut für die Umwelt - und deinen Geldbeutel.
  </InfoBox>
  <br>
  <div class="text-section">
    <div class="text-left">
      <div class="headline">Schnell verderbliche Zutaten</div>
      <div class="secondary-text-gray">Unsere Rezeptvorschläge sind darauf ausgelegt, dass wenig von diesen Produkten übrig bleibt.</div>
      <div v-for="item in leftovers.filter(l => l.food_category === 'Schnell verderblich')" :key="item.name">
        <div class="leftover">
          <CircleChart :size="100" :value="used(item)" />
          <div class="leftover-right">
            <div class="main-text">{{ item.name }}</div>
            <div class="secondary-text-gray">{{ leftAmount(item) }}</div>
          </div>
        </div>
      </div>
    </div>
    <br><br><br>

    <div class="text-left">
      <div class="headline">Alltagsprodukte</div>
      <div class="secondary-text-gray">Reste von Alltagsprodukten kannst du gut anderweitig verbrauchen.</div>
      <div v-for="item in leftovers.filter(l => l.food_category === 'Täglicher Bedarf')" :key="item.name">
        <div class="leftover">
          <CircleChart :size="100" :value="used(item)" />
          <div class="right">
            <div class="main-text">{{ item.name }}</div>
            <div class="secondary-text-gray">{{ leftAmount(item) }}</div>
          </div>
        </div>
      </div>
    </div>
    <br><br><br>


    <div class="text-left">
      <div class="headline">Haltbare Zutaten</div>
      <div class="secondary-text-gray">Diese Zutaten sind noch lange haltbar.</div>
      <div v-for="item in leftovers.filter(l => l.food_category === 'Haltbar')" :key="item.name">
        <div class="leftover">
          <CircleChart :size="100" :value="used(item)" />
          <div class="right">
            <div class="main-text">{{ item.name }}</div>
            <div class="secondary-text-gray">{{ leftAmount(item) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleChart from '@/components/charts/CircleChart.vue'
import InfoBox from '@/components/info/InfoBox.vue'

export default {
  name: 'leftoversexplanation',
  props: ['data'],
  components: {
    CircleChart,
    InfoBox
  },
  computed: {
    leftovers () {
      let leftovers = []
      if (this.data) {
        leftovers = this.data.leftovers.sort((a,b) => this.used(b) - this.used(a))
      } else if (this.$store.state.neworderLeftovers) {
        leftovers = this.$store.state.neworderLeftovers.leftovers.sort((a,b) => this.used(b) - this.used(a))
      }
      return leftovers.filter(l => l.amount)
    },
    consumption () {
      let ratio
      if (this.data) {
        ratio = this.data.consumption_ratio
      } else if (this.$store.state.neworderLeftovers) {
        ratio = this.$store.state.neworderLeftovers.consumption_ratio
      }
      const consumption = Math.round(ratio * 100)
      if (consumption > 100) {
        return 100
      } else {
        return consumption
      }
    }
  },
  methods: {
    used(item) {
      const used = item.used / item.amount
      if (used > 0.9) {
        return 100
      } else {
        return Math.round(used * 100)
      }
    },
    leftAmount(item) {
      const amount = Math.round(item.amount - item.used)
      if (amount > 1000) {
        return Math.round(amount/10)/100 + 'kg bleiben übrig'
      } else if (amount < 1000 && amount > 0) {
        return amount + 'g bleiben übrig'
      } else {
        return 'Alles wird verbraucht'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../../theme/main.scss';

.explanation {
  font-size: 1.1rem;
  margin-top: -20px;
  margin-bottom: 20px;
  color: $col-font;
}

.leftover {
  display: flex;
  align-items: center;
  margin: 0 -10px -30px -10px;
}

.leftover-right {
  margin-left: 10px;
}

.name {
  font-size: 1.2rem;
  text-align: left;
}
</style>