<!--

      This component explains the price of a menu.

 -->

<template>
  <div>
    <Icon name="foodableLightBulb" class="icon-modal"/>
    <div class="text-section">
      <div class="headline">Was bedeutet dieser Preis?</div>
      <div class="main-text">
        Für <Price class="inline-price" :price="thePrice" v-if="thePrice"/>
        {{ thePrice ? '' : 'diesen Preis' }} erhältst du alle Supermarktprodukte,
        die du für {{ plural ? 'diese Rezepte' : 'dieses Rezept' }} brauchst.
      </div>
      <br><br>
      <div class="headline">Supermarktpreise</div>
      <div class="main-text">
        Ein Rezept bei foodable kostet daher exakt soviel wie im Supermarkt.
      </div>
      <br><br>
      <div class="headline">Rezept & Vorrat</div>
      <div class="main-text">
        Nicht jedes Produkt wird vollständig verbraucht. Mit
        Tiefkühlware, Eiern, Milch usw. kannst du noch allerlei
        anstellen.
        <span v-if="theAvgPrice && theRestPrice">
          Effektiv {{ plural ? 'kosten' : 'kostet' }} dich
          {{ plural ? 'diese' : 'dieses' }} <span v-if="days">{{ days.length }}</span>
          {{ plural ? 'Rezepte je' : 'Rezept' }}
          <Price class="inline-price" :price="theAvgPrice" v-if="theAvgPrice" /> pro
          Portion. Angefangene Zutaten im Wert von
          <Price class="inline-price" :price="theRestPrice" v-if="theRestPrice" />
          kannst du später noch für andere Zwecke verwenden.
        </span>
      </div>
      <br><br>
      <div class="headline">Smarter Einkaufen</div>
      <div class="main-text">
        Ein Rezept ist günstiger, wenn ein Teil der Produkte bereits
        im Warenkorb ist. Wir helfen, dir Rezepte passend auszuwählen.
        <br><br>
        Wir stellen dein Menü so zusammen, dass die Produkte möglichst
        vollständig verbraucht werden.
        Wenn du mehr Rezepte und Portionen planst, können wir die
        Zusammenstellung besser optimieren - und du bekommst noch
        bessere Preise pro Rezept!
      </div>
    </div>
  </div>
</template>

<script scoped>
import Price from '@/components/Price.vue'

export default {
  name: 'priceexplanation',
  props: ['days', 'thePrice', 'theAvgPrice', 'theRestPrice'],
  components: {
    Price
  },
  computed: {
    plural () {
      if (this.days && this.days.length && this.days.length > 1) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
