<template>
  <div>
    <Icon name="foodableHeart" class="icon-modal" />
    <div class="text-section">
      <div class="headline">Lieblingsrezepte hinzufügen</div>
      <div class="main-text">
        Du kannst deine Lieblingsrezepte via E-Mail hinzufügen. Wir verknüpfen diese
        dann mit deinem foodable Konto.
        <br><br>
        Trage bitte Rezeptname, Zutaten und 
        Zubereitungsschritte ein und füge ein Bild hinzu.
        Alternativ kannst du uns auch einen Link zum Rezept schicken.
      </div>
    </div>
    <div>
      <div @click="$store.dispatch('closeModal')" class="btn-tertiary" fill="clear">Nein</div>
    </div>
    <div @click="$store.dispatch('addRecipeViaMail')" class="btn-primary">Jetzt Rezept hinzufügen</div>
    <div class="text-section tertiary-text-gray">Falls diese Funktion häufig genutzt wird, werden wir das direkte Hinzufügen von Rezepten in der App ermöglichen.</div>
  </div>
</template>
