<template>
  <div>
    <Icon name="foodableLightBulb" class="icon-modal" />
    <div class="text-section">
      <div class="headline">Für mehr Tage im Voraus zu planen hat viele Vorteile</div>
      <div class="main-text unordered-list">
        <ul>
          <li>Der Preis pro Portion wird grundsätzlich günstiger!</li>
          <li>Planen ist die am häufigsten empfohlene Methode, um Lebensmittelverschwendung zu reduzieren!</li>
          <li>Du zahlst seltener Liefergebühren.</li>
          <li>Wenn du mehrere Rezepte wählst, können unsere Algorithmen sie besser miteinander kombinieren.</li>
          <li>Du erreichst einfacher die Mindestgrößen für den Warenkorb (z.B. 50€ beim REWE Lieferservice).</li>
        </ul>
      </div>
    </div>
    <div @click="$store.dispatch('closeModal')" class="btn-primary">Schließen</div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../../theme/main.scss';

.unordered-list {
  text-align: left;
  li {
    margin-bottom: 8px;
  }
}
</style>