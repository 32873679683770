<!--

      This component displays a popup above all of the other content.

 -->

<template>
  <div class="popup-container">
    <div class="popup-modal">
      <div :class="['popup-content', {'vertical-padding' : !noPadding}]">
        <slot></slot>
      </div>
    </div>
    <div class="close-button-position">
      <div class="round-button" @click="$store.dispatch('closeModal')">
        <Icon name="closeOutline" class="round-button-icon centered" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal',
  props: ['noPadding']
}
</script>

<style lang="scss" scoped>
@import '../../theme/main.scss';

.popup-container {
  z-index: 999;
  position: relative;
}

.popup-modal {
  position: absolute;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  .section-title {
    margin-left: 20px;
  }
}

.popup-content {
  background: white;
  min-height: 100%;
  text-align: center;
}

.vertical-padding {
  padding-top: 50px;
  padding-bottom: 150px;
}
</style>
