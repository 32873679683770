<template>
  <div>
    <Icon name="foodableLightBulb" class="icon-modal" />
    <div class="text-section">
      <div class="headline">Milchprodukte</div><br>
      <div class="main-text">
        Die Kategorie "Sonstige Milchprodukte" beinhaltet all diejenigen Milchprodukte, die
        nicht in den anderen Kategorien abgedeckt sind (z.B. Butter). Wenn du also 
        gänzlich auf Milchprodukte verzichten möchtest, weil du beispielsweise vegan isst, 
        oder eine Allergie hast, solltest du auch diese Kategorie unbedingt mit ausschließen.
        <br><br>
        Bitte kontrolliere zur Sicherheit trotzdem selbst ob die von dir gewählten Produkte
        auch wirklich frei von ausgeschlossenen Zutaten sind.
      </div>
    </div>
  </div>
</template>
