import { createStore } from 'vuex'

// Import the store modules
import Analytics from './modules/Analytics.store'
import ApiCommunication from './modules/ApiCommunication.store'
import ApiCalls from './modules/ApiCalls.store'
import Authentication from './modules/Authentication.store'
import Order from './modules/Order.store'
import Data from './modules/Data.store'
import Navigation from './modules/Navigation.store'
import Notifications from './modules/Notifications.store'
import Supermarket from './modules/Supermarket.store'
import UXCam from './modules/UXCam.store'

// When modularizing the vuex store, I only succeeded in modularizing the
// actions and mutations, but not the state objects, for two reasons:
// 1. Upon App startup, the modularized state objects were not available.
//    Instead the root state was: state: { ModuleName: null }.
//    Hence, for each module, its state variables were missing and I was
//    unable to find the reason for this problem.
// 2. In the Data.store.js file we have a logic, which saves and retrieves
//    the whole state in the smartphone storage or browser local storage.
//    This logic would have to be adapted to save and retrieve the state
//    variables of each module. This unnecessarily overcomplicates the logic.
// For these reasons, I decided to store the whole state in this file, while
// vuex actions and mutations reside in separate module files.

// This is the list of our API endpoints.
const api = {
  plan: {
    generate: 'planner_api/create-mealplan/',
    rpCreate: 'planner_api/planrecipe/create/',
    rp: 'planner_api/planrecipe/',
    dailyCreate: 'planner_api/daily/create/',
    daily: 'planner_api/daily/',
    weeklyCreate: 'planner_api/weekly/create/',
    weekly: 'planner_api/weekly/',
    featured: 'planner_api/weekly/featured/'
  },
  recipes: 'recipe_api/recipes/',
  recipeCategories: 'recipe_api/categories/',
  recipeImpressions: 'recipe_api/recipes/impressions/',
  recipeAvailability: 'recipe_api/recipes/availability/',
  search: 'recipe_api/recipes/search/',
  favorites: 'recipe_api/recipes/favorites/',
  shop: {
    order: 'shopping_api/orders/',
    orderedOrdersOverview: 'shopping_api/orders/overview/',
    reorder: 'shopping_api/orders/copy/',
    new: 'shopping_api/orders/new/',
    storebranches: 'shopping_api/store_branches/',
    products: 'shopping_api/products/',
    productLike: 'shopping_api/product-likes/create/',
    productSearch: 'shopping_api/search/',
    orderProducts: 'shopping_api/ordersproducts/',
    categories: 'shopping_api/categories/',
    productRecommendations: 'shopping_api/recommendations/',
    list: 'shopping_api/shopping-list/',
    ingredientSearch: 'shopping_api/ingredients/search/'
  },
  auth: {
    login: 'auth/token/',
    register: 'auth/register/',
    socialauth: 'auth/convert-token/',
    requestverificationemail: 'user_api/request-verification-link/'
  },
  pw: {
    requestreset: 'user_api/request-reset-password/',
    reset: 'user_api/reset-password/'
  },
  like: {
    create: 'recipe_api/likes/create/',
    delete: 'recipe_api/likes/',
  },
  ingredientcategories: 'ingredient_api/categories/',
  biocategories: 'ingredient_api/categories/bio/',
  profile: 'user_api/profiles/current/',
  selectedRecipeCategories: 'user_api/profiles/current/selected-recipe-categories/',
  tests: 'user_api/profiles/current/tests/',
  version: 'version_control/version/',
  foodableAnalytics: 'tracking_api/tracking-events/create/',
  awinTransactions: 'tracking_api/tracking-events/list-awin/',
  tracking: 'tracking_api/tracking-events/',
  achievements: 'achievements_api/achievements/',
  userproductcategories: 'shopping_api/user-product-categories/',
  userproductcategoryproduct: 'shopping_api/user-product-category-product/'
}

const settingsPages = [
  { title: 'Produktqualität', component: 'Bio'},
  { title: 'Das essen wir nicht', component: 'Exclude'},
  { title: 'Haushalt', component: 'Household'},
  { title: 'Lieferung', component: 'Supermarket'},
  { title: 'Lebensmittelretter', component: 'Foodwaste'},
  { title: 'Bestelltag', component: 'OrderDay'},
  { title: 'Rezeptkategorien', component: 'RecipeCategories'},
  { title: 'SEPA Lastschrift', component: 'SEPA'},
  { title: 'Kreditkarte', component: 'CreditCard'},
  { title: 'Bezahlmethode', component: 'Payment'},
  { title: 'Adresse', component: 'Address'}
]

const recipeImpressionFlags = {
  SEEN: 0,
  SELECTED: 1,
  REPLACED: 2,
  OPENED: 3,
  DELETED: 4,
  LIKED: 5,
  COOKED: 6,
  EATEN: 7,
  SEEN_IN_MENU: 8
}

const paymentMethodTypes = {
  sepa_debit: {icon: 'logoEuro', name: 'SEPA Lastschrift'},
  card: {icon: 'card', name: 'Kreditkarte'}
}

// WARNING! If you add another array, dict or object here, please
// make sure to exclude it from the function "deleteAllData"!
// Otherwise it will be deleted when a user logs out and the next
// user that logs in will not have this data.


const store = createStore({
  // This is the App's root vuex state, which contains all of the required
  // data for the whole application. This state is kept in sync with the
  // smartphone storage or browser's local storage.

  state () {
    return {
      // Api
      api: api,
      accesstoken: null,
      refreshtoken: null,
      apiversion: null,
      appversion: null,
      expectedAppVersion: null,

      // Auth
      slug: null,
      refreshingtoken: null,

      // Data
      planneddays: null,
      previousplan: null,
      openOrders: null, // These are all of my (unordered) orders (as visible in newplan page)
      openPlans: null, // These are all of my (unordered) menus (as visible in newplan page)
      openOrdersLastPage: null,
      savedPlans: null, // These are all of my saved plans
      featuredPlans: null, // These are all featured menus, which are globally defined in the Api
      newplan: null,
      neworder: null,
      neworderdurables: null,
      newordershoppingingredients: null,
      neworderPriceConsumed: null,
      neworderLeftovers: null,
      neworderUnavailableProducts: null,
      neworderWithLastStepRewe: null,
      neworderPaymentIntent: null,
      neworderPaymentMethod: null,
      neworderPaymentMethods: null,
      neworderDeliverySlot: null,
      loadingNeworderPriceConsumed: null, // Is true while "neworderPriceConsumed" is being retrieved
      order: null, // Currently viewed order in "Meine Bestellungen" (Order.vue)
      orders: null,
      deliveredOrders: null,
      notDeliveredOrders: null,
      modalrecipe: null,
      modalrplan: null,
      recipes: null,
      recipeDownloadQueue: null,
      replaceme: null, // to-be-replaced recipe in menu
      productreplace: null, // product to be replaced on product alternative page
      favoriteschanged: null, // only relates to replace page favorites tab
      profile: null,
      selectedRecipeCategories: null,
      ingredientcategories: [],
      biocategories: null,
      analyticsEnabled: true,
      deviceInfo: null,
      latestOrderDownloadedAt: null,
      recipeAvailability: null,
      settingsPages: settingsPages,
      recipeImpressionFlags: recipeImpressionFlags,
      paymentMethodTypes: paymentMethodTypes,
      modalIngredientDetails: null,
      modalShowAvailability: null, // show product availability in recipe modal
      modalOrder: null, // show product images in recipe modal
      removingNeworderAndNewplan: null,
      userProductCategories: [],
      allProductsSections: null,

      // Navigation
      tab:            null,
      onboarded:      false,
      callsInProgress: 0,
      foodableOffline:false,
      googleOffline:  false,
      modal:          null,  // this refers to the new own modal
      generatingPlan: null,
      sendingToRewe:  null,
      navigationDestination: null,
      routerHistory: null,

      // Notifications
      notification:         null,
      notificationsEnabled: null, // Does the device allow local and push notif.
      askedOnceForPermission: null,

      // Supermarket
      supermarketuser: null,
      supermarketpassword: null,
      whisktrolleyurl: null,
    }
  },

  getters: {
    oldCustomerJourney (state) {
      return state.profile && state.profile.old_customer_journey
    },
    useInternalPayment (state) {
      return state.profile && state.profile.preferred_store_branch && state.profile.preferred_store_branch.use_internal_payment
    },
    theAvgPrice (state, getters) {
      if (state.newplan && state.neworderPriceConsumed) {
        let servings = 0
        state.newplan.single_days.forEach(day => {
          if (day && day.planrecipes[0] && day.planrecipes[0].hasOwnProperty('serving_size')) {
            servings += day.planrecipes[0].serving_size
          }
        })
        return state.neworderPriceConsumed / servings
      }
    },
    theRestPrice (state) {
      if (state.neworder && state.neworderPriceConsumed) {
        return state.neworder.price - state.neworderPriceConsumed
      }
    },
    leftoversConsumption (state) {
      if (state.neworderLeftovers && state.neworderLeftovers.hasOwnProperty('consumption_ratio')) {
        const consumption = Math.round(state.neworderLeftovers.consumption_ratio * 100)
        if (consumption > 100) {
          return 100
        } else {
          return consumption
        }
      }
    },
    days (state) {
      if (state.newplan && state.newplan.single_days) {
        return state.newplan.single_days.sort((a,b) => {return a.position - b.position})
      }
    },
    openPlansSortedAsc (state) {
      if (state.openPlans) {
        return state.openPlans.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at)
        })
      }
    },
    countUnavailableProductsInNeworder (state) {
      if (state.neworderUnavailableProducts && state.neworderUnavailableProducts.length > 0 && state.neworder && state.neworder.orderproducts) {
        let count = 0
        state.neworder.orderproducts.forEach(product => {
          const foundProduct = state.neworderUnavailableProducts.find(p => p.product === product.product_id)
          if (foundProduct) {
            count += 1
          }
        })
        return count
      }
    },
    orderDay (state) {
      const dayNames = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
      if (state.profile && typeof state.profile.order_day === 'number') {
        return dayNames[state.profile.order_day]
      }
    },
    orderDayShort (state) {
      const dayNames = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
      if (state.profile && typeof state.profile.order_day === 'number') {
        return dayNames[state.profile.order_day]
      }
    },
    orderDayNotificationOn (state) {
      if (state.profile) {
        return state.profile && state.profile.order_notification_enabled
      }
    },
    foodwasteDayShort (state) {
      const dayNames = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
      if (state.profile && typeof state.profile.foodwaste_notification_day === 'number') {
        return dayNames[state.profile.foodwaste_notification_day]
      }
    },
    foodwasteNotificationOn (state) {
      if (state.profile) {
        return state.profile && state.profile.foodwaste_notification_enabled 
      }
    },
    orderTimeHour (state) {
      if (state.profile && state.profile.order_time ) {
        return parseInt(state.profile.order_time.split(":")[0])
      }
    },
    orderTimeMinute (state) {
      if (state.profile && state.profile.order_time ) {
        return parseInt(state.profile.order_time.split(":")[1])
      }
    },
    addressExists (state) {
      if (state.profile && state.profile.delivery_address) {
        return state.profile.delivery_address && state.profile.delivery_address.address && state.profile.postal_code && state.profile.delivery_address.city
      }
    },
    nameExists (state) {
      if (state.profile && state.profile.full_name) {
        return state.profile.full_name
      }
    },
    telExists (state) {
      if (state.profile && state.profile.phone_number) {
        return state.profile.phone_number
      }
    },
    fullAddressExists (state, getters) {
      return getters.addressExists && getters.nameExists && getters.telExists
    },
    ingredientcategorygroups (state) {
      // Collect a list of all exclusion groups
      if (state.ingredientcategories && state.ingredientcategories.forEach !== undefined) { // initial state of this is weird
        let cats = []

        state.ingredientcategories.forEach(i => {
          if (i.exclusion_group && !cats.some(c => c === i.exclusion_group)) {
            cats.push(i.exclusion_group)
          }
        })

        // Sort the exclusion groups into the order in which to display them
        let theOrder = ['mf', 'a', 'al', 'p']
        let finalCategoryList = []
        theOrder.forEach(i => {
          if (cats.includes(i)) {
            finalCategoryList.push(i)
          }
        })
        return finalCategoryList
      }
    },
    profileExclusionsCounter (state) {
      if (!state.profile) return null;
      const relevantExclusionAttributes = ['ingredient_category_exclusions', 'shopping_ingredient_exclusions']
      let exclusionCounter = 0
      for (const relevantExclusionAttribute of relevantExclusionAttributes) {
        let relevantExclusions = state.profile[relevantExclusionAttribute]
        if (!relevantExclusions) {
          exclusionCounter += 0;
        } else {
          exclusionCounter += relevantExclusions.length
        }
      }
      return exclusionCounter
    },
    internet (state) {
      let status = null
      if (state.foodableOffline && state.googleOffline) {
        // If Google and foodable server are down, we conclude that the internet
        // connection is down.
        status = 'offline'
      } else if (state.foodableOffline && !state.googleOffline ) {
        // If Google is up while foodable server is unreachable, we conclude that
        // the foodable server is down.
        status = 'server_down'
      } else if (!state.foodableOffline) {
        status = 'online'
      }
      console.log('Internet status: ' + status)
      return status
    },
    debugmode (state) {
      if (state.profile && state.profile.email && state.profile.email.includes('@foodable.de') ) {
        return true
      } else {
        return false
      }
    }
  },

  // Actions are modularized into the following modules.
  modules: {
     Analytics,
     ApiCommunication,
     ApiCalls,
     Authentication,
     Order,
     Data,
     Navigation,
     Notifications,
     Supermarket,
     UXCam
  }
})

export default store
