<!--

      This modal shows product information about the expiry
      and optimal storage of each shopping ingredient.
 
 -->

<template>
  <div class="expiry-modal">
    <Icon name="foodableLightBulb" class="icon-modal" />
    <div class="headline">{{ $store.state.modalIngredientDetails.name }}</div>
    <div class="product-bought" v-if="product">
      <span class="product-image-bought-text">Du hast dieses Produkt für dieses Rezept gekauft:</span>
      <div class="product-bought-details">
        <img class="product-image-bought" :src="product.img_url + '?output-format=jpeg&output-quality=70&resize=176px%3A176px'" alt="">
        <div class="product-bought-details-right">
          <div class="secondary-text">{{ product.product_name }}</div>
          <div class="secondary-text-gray">{{ product.quantity }} mal gekauft</div>
        </div>
      </div>
    </div>
    <div class="product-bought" v-if="product === false">
      <span class="product-image-bought-text">Du hast für diese Zutat kein Produkt im Supermarkt gekauft.</span>
    </div>

    <div class="main-text text-section" v-if="$store.state.modalIngredientDetails && $store.state.modalIngredientDetails.expiry_info">
      {{ $store.state.modalIngredientDetails.expiry_info.additional_information }}
    </div>

    <div class="expiry-info-area" v-if="$store.state.modalIngredientDetails && $store.state.modalIngredientDetails.expiry_info">
      <div class="expiry-info">
        <div class="main-text">Lagerort:</div>
        <div class="main-text">
          <div>{{ $store.state.modalIngredientDetails.expiry_info.place }}</div>
          <div class="hinweis" v-if="$store.state.modalIngredientDetails.expiry_info.dry && !$store.state.modalIngredientDetails.expiry_info.protect_from_light">Hinweis: Trocken lagern</div>
          <div class="hinweis" v-if="$store.state.modalIngredientDetails.expiry_info.protect_from_light && !$store.state.modalIngredientDetails.expiry_info.dry">Hinweis: Vor Licht schützen</div>
          <div class="hinweis" v-if="$store.state.modalIngredientDetails.expiry_info.protect_from_light && $store.state.modalIngredientDetails.expiry_info.dry">Hinweis: Trocken und dunkel lagern</div>
        </div>
      </div>
      <div class="expiry-info" v-if="$store.state.modalIngredientDetails.expiry_info.temperature">
        <div class="main-text">Temperatur:</div>
        <div class="main-text">{{ $store.state.modalIngredientDetails.expiry_info.temperature }}</div>
      </div>
      <div class="expiry-info" v-if="$store.state.modalIngredientDetails.expiry_info.days_to_expiry">
        <div class="main-text">Haltbarkeit:</div>
        <div class="main-text">{{ $store.state.modalIngredientDetails.expiry_info.days_to_expiry_original }} Tage</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'expiryinformation',
  data () {
    return {
      product: null
    }
  },
  mounted () {
    if (this.$store.state.modalOrder) {
      this.$store.dispatch('apiSend', {
        url: this.$store.state.api.shop.order,
        id: this.$store.state.modalOrder.id
      }).then(order => {
        if (order) {
          const orderProduct = order.orderproducts.find(op => {
            return op.shopping_ingredient_id && op.shopping_ingredient_id === this.$store.state.modalIngredientDetails.shopping_ingredient_id
          })
          if (orderProduct) {
            this.product = orderProduct
          } else {
            this.product = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../../theme/main.scss';

.expiry-modal {
  padding-bottom: 100px;
}

.expiry-info-area {
  margin: 20px;
  padding-top: 20px;
}

.expiry-info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: left;
  margin: 20px;
}

.hinweis {
  color: $col-gray-font;
}

.product-bought {
  margin: 20px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 5px;
}

.product-image-bought-text {
  @extend .tertiary-text-gray;
  margin: 10px 20px;
  display: block;
}

.product-image-bought {
  width:100px;
  height: 100px;
}

.product-bought-details {
  display: flex;
  padding: 0 10px;
}

.product-bought-details-right {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 10px;
}
</style>