// Documentation for "Sign in with Apple" as it works here:
// https://ionicframework.com/blog/adding-sign-in-with-apple-to-an-ionic-app/
// This is the corresponding plugin: https://github.com/rlfrahm/capacitor-apple-login
//
// For Android Google Login to work, we need this specific commit of the 
// Capacitor Google Auth plugin:
// https://github.com/CodetrixStudio/CapacitorGoogleAuth/\#88c82d3b01
// From this commit copy the contents of the file:
// /android/src/main/java/com/codetrixstudio/capacitor/GoogleAuth/GoogleAuth.java
// and replace them in the local node_modules folder

import "@codetrix-studio/capacitor-google-auth"
// require("@codetrix-studio/capacitor-google-auth")
import { Plugins } from "@capacitor/core"
import { FacebookLoginResponse } from '@capacitor-community/facebook-login'
const { SignInWithApple, FacebookLogin } = Plugins

// Actions
const actions = {

  async signInWithApple({ dispatch }) {
    await SignInWithApple.Authorize().then(async response => {
      await dispatch('convertTokenAndProceed', { socialToken: response.response, backend: 'apple-id'})
    }).catch(response => {
      dispatch('showSocialLoginError', 'Apple')
      console.error(response)
    })
  },

  async signInWithGoogle({ rootState, dispatch }) {
    await Plugins.GoogleAuth.signIn().then(async googleUser => {
      await dispatch('convertTokenAndProceed', { socialToken: googleUser.authentication.accessToken, backend: 'google-oauth2'})
    }).catch(response => {
      dispatch('showSocialLoginError', 'Google')
      console.error(response)
    })
  },

  async signInWithFacebook({ rootState, dispatch }) {
    await FacebookLogin.login({ permissions: ['email'] }).then(async result => {
      if (result.accessToken) {
        await dispatch('convertTokenAndProceed', { socialToken: result.accessToken.token, backend: 'facebook'})
      } else {
        dispatch('showSocialLoginError', 'Facebook')
      }
    }).catch(response => {
      dispatch('showSocialLoginError', 'Facebook')
      console.error(response)
    })
  },


  async convertTokenAndProceed ({ rootState, dispatch }, data) {
    // Input parameters:
    let socialToken = data.socialToken
    let backend = data.backend
    let email = null

    if (backend === 'apple-id') {
      socialToken = socialToken.identityToken
      email = socialToken.email
    }

    const details = {
      url: rootState.api.auth.socialauth,
      method: 'POST',
      data: {
        grant_type: "convert_token",
        client_id: process.env.VUE_APP_CLIENT_ID,
        client_secret: process.env.VUE_APP_CLIENT_SECRET,
        backend: backend, 
        token: socialToken
      }
    }
    await dispatch('apiSend', details).then(async convertedTokens => {
      // This is how a typical convertedTokens object looks like:
      // {"access_token":"mVtKf0VkCQy9WV4rlHp7LfIWBOOedu","expires_in":36000,"token_type":"Bearer","scope":"read write","refresh_token":"Z8CzsMet6MgryJ9H1yaEiDMLZMT8cz"}
      dispatch('save', { key: 'refreshtoken', value: convertedTokens.refresh_token })
      await dispatch('save', { key: 'accesstoken', value: convertedTokens.access_token}).then(async () => {
        // Figure out whether this is a login or a registration.
        if (backend === 'apple-id') {
          // Apple only sends the email upon registration
          if (email) {
            await dispatch('runThisUponSuccessfulRegistration', true)
          } else {
            await dispatch('runThisUponSuccessfulLogin', true)
          }
        } else {
          // Google and Facebook don't help us to figure this out.
          // Therefore, check if the profile has a postal code.
          await dispatch('getProfileFromAPI').then(async () => {
            if (rootState.profile && rootState.profile.postal_code) {
              await dispatch('runThisUponSuccessfulLogin', true)
            } else {
              await dispatch('runThisUponSuccessfulRegistration', true)
            }
          })
        }
      })
    })
  },


  async showSocialLoginError ({ dispatch }, backend) {
    dispatch('showNotification', {
      message: 'Hups. Die Anmeldung mit ' + backend + ' hat nicht geklappt. Du könntest dich sonst auch mit deiner E-Mail-Adresse registrieren.',
      type: 'Error'
    })
  }

}

export default {
  actions
}
